import { USER_ROLE } from '../../constants/user';
import { transitions } from '../../transactions/transactionProcessBooking';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

export const ENQUIRIES_PER_PAGE = 6;

const mapTransactionsToEnquiriesData = transactions =>
  transactions.map(transaction => {
    const { listing, customer } = transaction || {};
    const { lastMessage } = transaction?.attributes?.metadata;

    return {
      otherParty: listing?.attributes?.title || customer?.attributes?.profile?.displayName,
      lastMessage,
      transactionId: transaction?.id?.uuid,
    };
  });

// ================ Action types ================ //

export const FETCH_ENQUIRIES_REQUEST = 'app/EnquiriesPage/FETCH_ENQUIRIES_REQUEST';
export const FETCH_ENQUIRIES_SUCCESS = 'app/EnquiriesPage/FETCH_ENQUIRIES_SUCCESS';
export const FETCH_ENQUIRIES_ERROR = 'app/EnquiriesPage/FETCH_ENQUIRIES_ERROR';

// ================ Reducer ================ //

const initialState = {
  enquiries: [],
  queryParams: null,
  pagination: null,
  queryInProgress: false,
  queryEnquiriesError: null,
};

const enquiriesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ENQUIRIES_REQUEST:
      return {
        ...state,
        queryParams: payload?.queryParams,
        queryInProgress: true,
        queryEnquiriesError: null,
        enquiries: [],
      };
    case FETCH_ENQUIRIES_SUCCESS:
      const { transactions, pagination } = payload || {};

      return {
        ...state,
        enquiries: mapTransactionsToEnquiriesData(transactions),
        pagination,
        queryInProgress: false,
      };
    case FETCH_ENQUIRIES_ERROR:
      console.error(payload);
      return { ...state, queryInProgress: false, queryEnquiriesError: payload };

    default:
      return state;
  }
};

// ================ Action creators ================ //

export const queryEnquiriesRequest = queryParams => ({
  type: FETCH_ENQUIRIES_REQUEST,
  payload: { queryParams },
});

export const queryEnquiriesSuccess = response => {
  return {
    type: FETCH_ENQUIRIES_SUCCESS,
    payload: response,
  };
};

export const queryEnquiriesError = e => ({
  type: FETCH_ENQUIRIES_ERROR,
  error: true,
  payload: e,
});

export const loadData = params => (dispatch, getState, sdk) => {
  const { state, listingId, userRole, userId, ...queryParams } = params || {};
  const page = queryParams.page || 1;
  const otherParty = userRole === USER_ROLE.ARTIST ? 'customer' : 'listing';
  const otherPartyNameParam =
    userRole === USER_ROLE.ARTIST
      ? { 'fields.user': ['profile.displayName'] }
      : { 'fields.listing': ['title'] };

  const apiQueryParams = {
    lastTransitions: [transitions.INQUIRE],
    page,
    userId,
    perPage: ENQUIRIES_PER_PAGE,
    include: [otherParty],
    ...otherPartyNameParam,
  };

  dispatch(queryEnquiriesRequest(params));

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = denormalisedResponseEntities(response);
      const pagination = response?.data?.meta;

      dispatch(queryEnquiriesSuccess({ transactions, pagination }));

      return response;
    })
    .catch(e => {
      dispatch(queryEnquiriesError(storableError(e)));
      throw e;
    });
};

export default enquiriesPageReducer;
