import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-london',
    postCodes: 'E,EC,N,NW,SE,SW,W,WC',
    predictionPlace: {
      address: 'London, UK',
      bounds: new LatLngBounds(
        new LatLng(51.67234324898703, 0.1482710335611201),
        new LatLng(51.38494012429096, -0.3514683384218145)
      ),
    },
  },
  {
    id: 'default-edinburgh',
    postCodes: 'EH',
    predictionPlace: {
      address: 'Edinburgh, UK',
      bounds: new LatLngBounds(
        new LatLng(55.99199385663457, -3.077658768988969),
        new LatLng(55.89042284355509, -3.333018697257174)
      ),
    },
  },
  {
    id: 'default-manchester',
    postCodes: 'M',
    predictionPlace: {
      address: 'Manchester, UK',
      bounds: new LatLngBounds(
        new LatLng(53.54458793949853, -2.146828844526851),
        new LatLng(53.39990299768613, -2.300096904893769)
      ),
    },
  },
  {
    id: 'default-glasgow',
    postCodes: 'G',
    predictionPlace: {
      address: 'Glasgow, UK',
      bounds: new LatLngBounds(
        new LatLng(55.92964111489253, -4.071716725920944),
        new LatLng(55.78127908593233, -4.393200305054868)
      ),
    },
  },
  {
    id: 'default-birmingham',
    postCodes: 'B',
    predictionPlace: {
      address: 'Birmingham, UK',
      bounds: new LatLngBounds(
        new LatLng(52.60869933491674, -1.709829372653529),
        new LatLng(52.38599896742283, -2.017433632448159)
      ),
    },
  },
  {
    id: 'default-leeds',
    postCodes: 'LS',
    predictionPlace: {
      address: 'Leeds, UK',
      bounds: new LatLngBounds(
        new LatLng(53.88120207888145, -1.397373104235037),
        new LatLng(53.7308029402404, -1.674091522121716)
      ),
    },
  },
  {
    id: 'default-cardiff',
    postCodes: 'CF',
    predictionPlace: {
      address: 'Cardiff, UK',
      bounds: new LatLngBounds(
        new LatLng(51.56090628589921, -3.11643735486853),
        new LatLng(51.44357516114741, -3.293662576756845)
      ),
    },
  },
  {
    id: 'default-liverpool',
    postCodes: 'L',
    predictionPlace: {
      address: 'Liverpool, UK',
      bounds: new LatLngBounds(
        new LatLng(53.48621000000001, -2.818000144896959),
        new LatLng(53.31154257793854, -3.019179335864527)
      ),
    },
  },
  {
    id: 'default-newcastle',
    postCodes: 'NE',
    predictionPlace: {
      address: 'Newcastle, UK',
      bounds: new LatLngBounds(
        new LatLng(54.23072073256779, -5.8753252043194),
        new LatLng(54.19528595008207, -5.916615274033512)
      ),
    },
  },
  {
    id: 'default-bristol',
    postCodes: 'BS',
    predictionPlace: {
      address: 'Bristol, UK',
      bounds: new LatLngBounds(
        new LatLng(51.54443262264671, -2.450902404174833),
        new LatLng(51.39254527703832, -2.730532637989797)
      ),
    },
  },
];
export default defaultLocations;
