import React from 'react';

const IconGallery = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3 0H2.7C1.20883 0 0 1.20883 0 2.7V15.3C0 16.7912 1.20883 18 2.7 18H15.3C15.448 17.9979 15.5956 17.9829 15.741 17.955L16.011 17.892H16.074H16.119L16.452 17.766L16.569 17.703C16.659 17.649 16.758 17.604 16.848 17.541C16.9682 17.4526 17.0824 17.3564 17.19 17.253L17.253 17.172C17.3414 17.0825 17.4226 16.9862 17.496 16.884L17.577 16.767C17.6399 16.6668 17.6941 16.5614 17.739 16.452C17.7637 16.4088 17.7848 16.3637 17.802 16.317C17.847 16.209 17.874 16.092 17.91 15.975V15.84C17.961 15.6641 17.9912 15.4829 18 15.3V2.7C18 1.20883 16.7912 0 15.3 0ZM2.70101 16.1999C2.20395 16.1999 1.80101 15.797 1.80101 15.2999V11.4209L4.76201 8.45091C4.931 8.28053 5.16103 8.1847 5.40101 8.1847C5.64098 8.1847 5.87102 8.28053 6.04001 8.45091L13.78 16.1999H2.70101ZM16.2002 15.3C16.1993 15.411 16.178 15.5208 16.1372 15.624C16.1166 15.6679 16.0925 15.71 16.0652 15.75C16.0411 15.7881 16.014 15.8242 15.9842 15.858L11.1692 11.043L11.9612 10.251C12.1302 10.0806 12.3602 9.98478 12.6002 9.98478C12.8401 9.98478 13.0702 10.0806 13.2392 10.251L16.2002 13.221V15.3ZM16.201 10.6739L14.509 8.9999C13.4394 7.98503 11.7626 7.98503 10.693 8.9999L9.90101 9.7919L7.30901 7.1999C6.23944 6.18503 4.56258 6.18503 3.49301 7.1999L1.80101 8.8739V2.6999C1.80101 2.20284 2.20395 1.7999 2.70101 1.7999H15.301C15.7981 1.7999 16.201 2.20284 16.201 2.6999V10.6739Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconGallery;
