import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import Form from '../Form/Form';
import { GTAG_COOKIE_GROUPS } from '../../constants/analytics';
import { FieldToggleCheckbox } from '..';
import { useIntl } from 'react-intl';

import css from './CookieConsent.module.css';

const GOOGLE_COOKIES = Object.values(GTAG_COOKIE_GROUPS);

const CookieConsentForm = props => {
  const { formApiRef, ...rest } = props;

  const intl = useIntl();

  return (
    <FinalForm
      {...rest}
      render={formRenderProps => {
        const { form, handleSubmit } = formRenderProps;

        formApiRef.current = form;

        return (
          <Form onSubmit={handleSubmit} className={css.cookieForm}>
            <FieldToggleCheckbox
              label={intl.formatMessage({ id: 'General.necessary' })}
              asideFromLabel={
                <span className={css.toggleLabelDesc}>
                  {intl.formatMessage({ id: `CookieConsent.necessaryDesc` })}
                </span>
              }
              id="necessary"
              name="necessary"
              className={css.toggle}
              showDynamicColor
              value="granted"
              disabled
            />

            {GOOGLE_COOKIES.map(name => (
              <FieldToggleCheckbox
                key={name}
                label={intl.formatMessage({ id: `CookieConsent.${name}` })}
                asideFromLabel={
                  <span className={css.toggleLabelDesc}>
                    {intl.formatMessage({ id: `CookieConsent.${name}Desc` })}
                  </span>
                }
                id={name}
                name={name}
                className={css.toggle}
                showDynamicColor
                value="granted"
              />
            ))}
          </Form>
        );
      }}
    />
  );
};

export default CookieConsentForm;
