import { checkIfVimeoVideoExists, checkIfYtVideoExists } from './api';

export const getVideoLinkInfo = url => {
  // Regex to match YouTube and Vimeo URLs
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n?#]+)/;
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;

  // Check if URL matches YouTube or Vimeo
  if (youtubeRegex.test(url)) {
    return { platform: 'youtube', videoId: url.match(youtubeRegex)[1] };
  } else if (vimeoRegex.test(url)) {
    return { platform: 'vimeo', videoId: url.match(vimeoRegex)[1] };
  } else {
    return { platform: null, videoId: null };
  }
};

export const checkVideoExists = async url => {
  try {
    const { platform, videoId } = getVideoLinkInfo(url);

    switch (platform) {
      case 'youtube':
        const { exists: existsYt } = await checkIfYtVideoExists(videoId);
        return existsYt;
      case 'vimeo':
        const { exists: existsVimeo } = await checkIfVimeoVideoExists(videoId);
        return existsVimeo;
      default:
        return false;
    }
  } catch (error) {
    return false;
  }
};

// Helper function to generate src and title based on provider
export const generateVideoEmbedProps = (provider, videoId) => {
  switch (provider) {
    case 'youtube':
      return {
        src: `https://www.youtube-nocookie.com/embed/${videoId}?rel=0`,
        title: 'Embedded youtube',
      };
    case 'vimeo':
      return {
        src: `https://player.vimeo.com/video/${videoId}`,
        title: 'Embedded vimeo',
      };
    default:
      return { src: '', title: '' };
  }
};
