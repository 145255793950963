import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from './currency';
import { daysBetween, getMomentFromDate } from './dates';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../util/sdkLoader';
import moment from 'moment';
import { BOOKING_POSSIBLE_TIME_RANGE } from '../constants/booking';

const { Money } = sdkTypes;

export const checkIfDatesOverlap = (startDate1, endDate1, startDate2, endDate2) => {
  const startsAtA = new Date(startDate1);
  const endsAtA = new Date(endDate1);
  const startsAtB = new Date(startDate2);
  const endsAtB = new Date(endDate2);

  return startsAtA < endsAtB && startsAtB < endsAtA;
  // Not overlapping if startsAtA >= endsAtB || endsAtA <= startsAtB

  // startsAtA as {
  // endsAtA as }
  // startsAtB as [
  // endsAtB as ]

  // Not overlapping cases
  // -----[-----]--{-}-
  // -{-}-[-----]------

  // Overlapping
  // --{--[---}-]------
  // -----[-{---]--}---
  // --{--[-----]--}---
  // -----[{---}]------
};

export const checkIfEventDateIsPossible = dayMoment => {
  const start = moment()
    .add(
      BOOKING_POSSIBLE_TIME_RANGE.startPeriod.amount,
      BOOKING_POSSIBLE_TIME_RANGE.startPeriod.unit
    )
    .startOf('day');
  const end = moment().add(
    BOOKING_POSSIBLE_TIME_RANGE.endPeriod.amount,
    BOOKING_POSSIBLE_TIME_RANGE.endPeriod.unit
  );

  return dayMoment.isBetween(start, end);
};

export const createEventFromBooking = (intl, booking) => {
  const { _id, attributes } = booking;

  const { metadata, protectedData } = attributes || {};

  const { dateOfEvent } = metadata || {};
  const { eventType } = protectedData || {};

  return {
    title: intl.formatMessage({ id: `General.${eventType}` }),
    start: new Date(dateOfEvent),
    end: new Date(dateOfEvent),
    resource: {
      id: _id,
      booking,
    },
  };
};

export const createBookingDate = (bookingDate, bookingTime) =>
  bookingDate && bookingTime
    ? getMomentFromDate(bookingDate)
        .hours(bookingTime.slice(0, 2))
        .minutes(bookingTime.slice(3))
        .toDate()
    : null;

export const estimatedTotalPrice = (lineItems, marketplaceCurrency) => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, new Decimal(0));

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0].unitPrice ? lineItems[0].unitPrice.currency : marketplaceCurrency;

  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

export const parseProtectedDataLineItems = (lineItems, currency) => {
  const lineItemsWithTotals = lineItems.map(lineItem => {
    const { lineTotal, unitPrice, quantity, percentage } = lineItem;

    return {
      ...lineItem,
      unitPrice: unitPrice ? new Money(unitPrice, currency) : null,
      lineTotal: lineTotal ? new Money(lineTotal, currency) : null,
      quantity: quantity ? new Decimal(quantity) : null,
      percentage: percentage ? new Decimal(percentage) : null,
      reversal: false,
    };
  });
  return lineItemsWithTotals;
};

export const removeTypeFromProtectedDataLocation = location => {
  const { selectedPlace, search } = location;

  const { bounds, origin, address, line1, name, postalCode, city } = selectedPlace || {};

  return {
    search,
    selectedPlace: {
      address,
      line1,
      name,
      postalCode,
      city,
      origin: {
        lat: origin.lat,
        lng: origin.lng,
      },
      bounds: bounds
        ? {
            ne: { lat: bounds.ne.lat, lng: bounds.ne.lng },
            sw: { lat: bounds.sw.lat, lng: bounds.sw.lng },
          }
        : null,
    },
  };
};

/**
 - 100% of artist fee is due if cancellation occurs within 30 days of event date.
 - 75% of artist fee is due if cancellation occurs within 31-91 days of event date.
 - 50% of artist fee is due if cancellation occurs within 92-181 days of event date.
 */
export const getCancellationFeePercent = eventDate => {
  const now = new Date();

  let daysDiff;

  try {
    daysDiff = daysBetween(now, eventDate);
  } catch (error) {}

  if (typeof daysDiff === 'undefined') return 100;

  if (daysDiff <= 30) return 100;

  if (daysDiff > 30 && daysDiff <= 91) return 75;

  return 50;
};

export const calculateVATAmount = grossValue => {
  return grossValue / 6;
};
