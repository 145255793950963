import React from 'react';
import { Field } from 'react-final-form';
import { object, string } from 'prop-types';
import VideoLinkImport from './VideoLinkImport';

import css from './FieldVideoLinkImport.module.css';

const FieldVideoLinkImportComponent = props => {
  const { input, label, asideFromLabel, meta, id, className } = props;
  const { invalid, touched, error } = meta;
  const { value, ...restInputProps } = input;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && error);

  return (
    <div className={className}>
      {label ? (
        <div className={css.labelWrapper}>
          <label htmlFor={id}>{label}</label> {asideFromLabel}
        </div>
      ) : null}
      <VideoLinkImport
        id={id}
        value={!value ? [] : value}
        {...restInputProps}
        errorMessage={hasError ? error : null}
      />
    </div>
  );
};

FieldVideoLinkImportComponent.propTypes = {
  label: string,
  input: object.isRequired,
  meta: object.isRequired,
  className: string,
  id: string,
};

const FieldVideoLinkImport = props => {
  const { ...rest } = props;

  return <Field component={FieldVideoLinkImportComponent} {...rest} />;
};

export default FieldVideoLinkImport;
