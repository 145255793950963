import React, { Component } from 'react';
import { arrayOf, func, node, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import loadable from '@loadable/component';
import { ValidationError } from '../../components';

import css from './LocationAutocompleteInput.module.css';

// LocationAutocompleteInputImpl is a big component that includes code for both Mapbox and Google Maps
// It is loaded dynamically - i.e. it is splitted to its own code chunk.
const LocationAutocompleteInputImpl = loadable(() =>
  import(/* webpackChunkName: "LocationAutocompleteInputImpl" */ './LocationAutocompleteInputImpl')
);

class LocationAutocompleteInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      labelClassName,
      hideErrorMessage,
      asideFromLabel,
      onChange,
      ...restProps
    } = this.props;
    const { input, label, meta, valueFromForm, ...otherProps } = restProps;
    /* eslint-enable no-unused-vars */
    const { onChange: onChangeDefault } = input;
    const onLocationChange = value => {
      onChangeDefault(value);
      onChange?.(value);
    };
    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
    const locationAutocompleteProps = {
      label,
      meta,
      ...otherProps,
      input: { ...input, value, onChange: onLocationChange },
    };
    const labelInfo = label ? (
      <div className={css.labelWrapper}>
        <label className={labelClassName} htmlFor={input.name}>
          {label}
        </label>
        {asideFromLabel}
      </div>
    ) : null;

    return (
      <div className={rootClassName}>
        {labelInfo}
        <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        {hideErrorMessage ? null : <ValidationError fieldMeta={meta} />}
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
  asideFromLabel: node,
  types: arrayOf(string),
  onChange: func,
};

export default LocationAutocompleteInputImpl;

export const FieldLocationAutocompleteInput = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
