import React from 'react';
import { node, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { id, className, triggerClassName, trigger, children, ...restOfProps } = props;

  const classes = classNames(css.root, className);

  const tooltipProps = {
    id,
    className: classes,
    ...restOfProps,
  };

  return (
    <>
      {trigger || (
        <span
          data-tooltip-id={id}
          className={classNames(css.trigger, triggerClassName)}
          // Needed for tooltips with input labels
          onClick={e => e.stopPropagation()}
        >
          ?
        </span>
      )}
      <ReactTooltip {...tooltipProps}>{children}</ReactTooltip>
    </>
  );
};

Tooltip.propTypes = {
  className: string,
  triggerClassName: string,
  id: string.isRequired,
  trigger: node,
  place: oneOf(['top', 'bottom', 'left', 'right']),
  type: oneOf(['dark', 'success', 'warning', 'error', 'info', 'light']),
  effect: oneOf(['float', 'solid']),
};

export default Tooltip;
