import React from 'react';

const IconMusicalNote = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="25px"
      height="25px"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2500.000000 2500.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,2500.000000) scale(0.100000,-0.100000)"
        stroke="none"
        fill="#084C61"
      >
        <path
          fill="#EE2677"
          d="M7305 21037 c-160 -53 -275 -161 -337 -315 l-23 -57 -5 -3937 c-5
  -3765 -6 -3938 -23 -3955 -17 -17 -21 -17 -95 10 -221 82 -296 107 -392 131
  -623 152 -1194 30 -1726 -369 -338 -254 -557 -580 -707 -1050 l-41 -130 -4
  -380 -3 -380 45 -157 c52 -180 95 -291 171 -436 348 -659 1033 -1071 1783
  -1072 554 0 1067 217 1448 612 282 294 456 631 537 1043 l21 110 6 4080 5
  4080 206 -235 c279 -319 470 -521 695 -736 431 -413 845 -763 1384 -1169 326
  -246 518 -496 624 -815 50 -150 69 -270 69 -435 0 -225 -48 -424 -157 -651
  -153 -318 -373 -543 -670 -685 -189 -90 -372 -137 -636 -164 -212 -21 -305
  -59 -405 -166 -145 -155 -172 -428 -60 -614 47 -78 77 -109 149 -157 103 -67
  167 -82 346 -81 649 5 1228 236 1669 667 420 411 688 930 778 1506 25 162 25
  539 0 700 -42 267 -107 480 -221 715 -195 403 -432 682 -836 985 -324 243
  -634 496 -930 759 -220 195 -712 687 -892 891 -370 420 -694 856 -963 1295
  -218 356 -304 459 -442 527 -84 42 -86 42 -206 45 -82 2 -135 -2 -162 -10z
  m-1269 -9097 c238 -30 441 -129 607 -297 127 -128 199 -246 251 -411 103 -326
  12 -684 -241 -953 -148 -156 -343 -262 -549 -299 -99 -18 -286 -8 -389 20
  -326 88 -598 339 -705 652 -40 120 -54 220 -47 351 21 403 301 765 693 896
  137 46 246 58 380 41z"
        />
        <path
          d="M20410 16965 c-36 -7 -423 -114 -860 -238 -437 -124 -1360 -385
  -2050 -581 -690 -195 -1574 -445 -1965 -556 -390 -111 -1051 -298 -1469 -416
  -840 -237 -864 -246 -970 -352 -63 -63 -117 -162 -135 -250 -8 -38 -11 -1014
  -11 -3412 0 -2158 -3 -3368 -10 -3384 -13 -36 -33 -33 -158 18 -292 120 -512
  165 -797 166 -714 1 -1300 -297 -1674 -849 -380 -560 -476 -1171 -279 -1766
  123 -371 348 -712 621 -942 186 -156 430 -290 682 -376 257 -88 258 -88 636
  -85 l334 3 135 37 c385 103 682 276 950 555 169 174 276 328 381 548 91 189
  148 368 184 580 16 93 18 308 19 3150 2 2872 3 3053 19 3110 32 110 93 159
  262 214 50 16 952 273 2005 571 1053 298 2302 652 2775 786 473 135 879 248
  902 251 35 5 45 2 67 -20 l26 -26 0 -1953 c0 -1754 -2 -1956 -16 -1976 -19
  -27 -30 -25 -171 34 -279 117 -504 164 -788 164 -294 0 -549 -54 -835 -177
  -651 -280 -1083 -864 -1175 -1592 -24 -187 -17 -500 14 -656 167 -824 865
  -1477 1711 -1601 176 -25 462 -16 635 20 651 138 1157 517 1436 1075 98 196
  167 428 201 681 10 76 13 950 13 4465 l0 4370 -22 70 c-53 168 -186 296 -354
  340 -66 18 -181 18 -269 0z m-415 -1217 c14 -6 28 -21 31 -34 3 -12 4 -176 2
  -365 -3 -254 -8 -353 -17 -379 -33 -85 -91 -131 -221 -175 -144 -49 -5696
  -1618 -5742 -1623 -38 -4 -48 -1 -56 14 -6 12 -11 174 -13 409 -3 434 -4 427
  64 479 38 29 29 26 1252 370 1334 374 3961 1117 4325 1221 179 52 330 94 337
  95 7 0 24 -5 38 -12z m-740 -6838 c348 -88 623 -344 728 -676 150 -475 -107
  -1002 -583 -1197 -255 -104 -515 -97 -765 22 -451 215 -681 712 -538 1166 116
  367 433 647 800 705 87 13 266 4 358 -20z m-7107 -1996 c298 -61 559 -267 698
  -549 72 -147 97 -253 97 -410 1 -135 -18 -235 -65 -354 -118 -299 -376 -528
  -688 -613 -119 -32 -346 -32 -465 1 -285 77 -544 298 -667 569 -58 128 -81
  240 -81 397 0 110 5 156 21 216 101 374 403 664 772 743 90 20 283 20 378 0z"
        />
      </g>
    </svg>
  );
};

export default IconMusicalNote;
