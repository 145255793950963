import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DISTANCE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDistance = props => {
  const { lineItems, intl } = props;

  const distanceLineItem = lineItems.find(
    item => item.code === LINE_ITEM_DISTANCE && !item.reversal
  );

  if (!distanceLineItem) return null;

  const { lineTotal } = distanceLineItem;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {intl.formatMessage({ id: 'EditListingPricingForm.travelExpenses' })}
      </span>
      <span className={css.itemValue}>{formatMoney(intl, lineTotal)}</span>
    </div>
  );
};

LineItemDistance.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDistance;
