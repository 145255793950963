import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { lazyLoadWithDimensions } from '../../../../util/uiHelpers.js';
import { generateVideoEmbedProps, getVideoLinkInfo } from '../../../../util/videoHelpers.js';
import { AspectRatioWrapper } from '../../../../components/index.js';

import css from './YoutubeEmbed.module.css';

const RADIX = 10;
const BLACK_BG = '#000000';

const IFrame = props => {
  const { dimensions, ...rest } = props;
  return <iframe {...dimensions} {...rest} title={rest.title} />;
};
const LazyIFrame = lazyLoadWithDimensions(IFrame);

export const YoutubeEmbed = props => {
  const { className, rootClassName, aspectRatio, disableFullscreen, url } = props;
  const hasSlash = aspectRatio.indexOf('/') > 0;
  const [aspectWidth, aspectHeight] = hasSlash ? aspectRatio.split('/') : [16, 9];
  const width = Number.parseInt(aspectWidth, RADIX);
  const height = Number.parseInt(aspectHeight, RADIX);
  const classes = classNames(rootClassName || css.video, className);

  const { platform, videoId } = getVideoLinkInfo(url);
  const { src, title } = generateVideoEmbedProps(platform, videoId);

  return (
    <AspectRatioWrapper className={classes} width={width} height={height}>
      <LazyIFrame
        src={src}
        className={css.iframe}
        style={{ background: BLACK_BG }}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        referrerPolicy="no-referrer-when-downgrade"
        allowFullScreen={!disableFullscreen}
        title={title}
      />
    </AspectRatioWrapper>
  );
};

YoutubeEmbed.displayName = 'YoutubeEmbed';

YoutubeEmbed.defaultProps = {
  rootClassName: null,
  className: null,
  aspectRatio: '16/9',
};

YoutubeEmbed.propTypes = {
  rootClassName: string,
  className: string,
  youtubeVideoId: string.isRequired,
  aspectRatio: string,
};
