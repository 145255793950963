import React, { Component } from 'react';
import { func, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../../util/reactIntl';
import {
  isInRange,
  isDayMomentInsideRange,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  isDateSameOrAfter,
  monthIdString,
  getStartOf,
  initialVisibleMonth,
  resetToStartOfDay,
  getMomentFromDate,
  parseDateFromISO8601,
} from '../../../util/dates';
import { propTypes } from '../../../util/types';
import { bookingDateRequired, required } from '../../../util/validators';
import { FieldDateInput, FieldSelect, IconArrowHead } from '../../../components';

import css from './FieldDateAndTimeInput.module.css';
import defaultConfig from '../../../config/configDefault';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';

// dayCountAvailableForBooking is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const TODAY = new Date();

const nextMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, 1, 'months');
const prevMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, -1, 'months');

const endOfRange = (date, dayCountAvailableForBooking, timeZone) => {
  return getStartOf(date, 'day', timeZone, dayCountAvailableForBooking - 1, 'days');
};

const incrementTimeString = timeString => {
  const [hours, minutes] = timeString.split(':');
  const hoursnum = Number.parseInt(hours, 10);

  const newMinutes = minutes === '00' ? '30' : '00';
  const newHours = newMinutes === '00' ? hoursnum + 1 : hoursnum;

  return `${newHours > 9 ? newHours : `0${newHours}`}:${newMinutes}`;
};

const getAvailableStartTimes = (availabilityStartTime, availabilityEndTime) => {
  if (!availabilityStartTime || !availabilityEndTime) {
    return [];
  }

  const times = [];

  const endTimeFinal =
    availabilityEndTime === '00:00' || availabilityEndTime < availabilityStartTime
      ? '23:30'
      : availabilityEndTime;

  for (let time = availabilityStartTime; time <= endTimeFinal; time = incrementTimeString(time)) {
    times.push(time);
  }

  //handle cases where endTime is smaller than startTime
  if (
    availabilityEndTime &&
    availabilityEndTime < availabilityStartTime &&
    availabilityEndTime !== '00:00'
  )
    for (let time = '00:00'; time <= availabilityEndTime; time = incrementTimeString(time)) {
      times.push(time);
    }

  return times;
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdString(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const Next = props => {
  const { currentMonth, dayCountAvailableForBooking, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return isDateSameOrAfter(
    nextMonthDate,
    endOfRange(TODAY, dayCountAvailableForBooking, timeZone)
  ) ? null : (
    <NextIcon />
  );
};
const Prev = props => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getStartOf(TODAY, 'month', timeZone);

  return isDateSameOrAfter(prevMonthDate, currentMonthDate) ? <PrevIcon /> : null;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getStartOf(
        props.values?.bookingStartDate?.date || TODAY,
        'month',
        props.timeZone
      ),
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots, dayCountAvailableForBooking } = this.props;
    const endOfRangeDate = endOfRange(TODAY, dayCountAvailableForBooking, timeZone);

    // Use "today", if the first day of given month is in the past
    const start = isDateSameOrAfter(TODAY, date) ? TODAY : date;
    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(start, TODAY, endOfRangeDate)) {
      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = isDateSameOrAfter(nextMonthDate, endOfRangeDate)
        ? getStartOf(endOfRangeDate, 'day', timeZone)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        this.fetchMonthData(this.state.currentMonth);

        // If previous fetch for month data failed, try again.
        const monthId = monthIdString(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = value => {
    const { timeZone, form, availabilityEndTime } = this.props;
    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getStartOf(TODAY, 'month', timeZone) });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const endDate = resetToStartOfDay(startDate, timeZone, 1);

    form.batch(() => {
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', availabilityEndTime);
    });
  };

  isOutsideRange(day) {
    const endOfRange = defaultConfig.stripe?.dayCountAvailableForBooking - 1;

    const firstAvailableDate = getMomentFromDate().add(4, 'days');

    return (
      !isInclusivelyAfterDay(day, firstAvailableDate) ||
      !isInclusivelyBeforeDay(day, getMomentFromDate().add(endOfRange, 'days'))
    );
  }

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      // endDateInputProps,
      values,
      monthlyTimeSlots,
      timeZone,
      intl,
      dayCountAvailableForBooking,
      availabilityStartTime,
      availabilityEndTime,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );

    const availableStartTimes = getAvailableStartTimes(availabilityStartTime, availabilityEndTime);

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? day =>
          !timeSlotsOnSelectedMonth.find(timeSlot => {
            const date = parseDateFromISO8601(timeSlot.date, timeZone);

            return isDayMomentInsideRange(day, date, timeZone);
          })
      : () => false;

    const startOfToday = getStartOf(TODAY, 'day', timeZone);

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.startDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={v =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={v =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              initialVisibleMonth={initialVisibleMonth(bookingStartDate || startOfToday, timeZone)}
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingStartDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={
                <Next
                  currentMonth={this.state.currentMonth}
                  timeZone={timeZone}
                  dayCountAvailableForBooking={dayCountAvailableForBooking}
                />
              }
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              useMobileMargins
              validate={bookingDateRequired(
                intl.formatMessage({ id: 'BookingTimeForm.requiredDate' })
              )}
              onClose={event =>
                this.setState({
                  currentMonth: getStartOf(event?.date ?? TODAY, 'month', this.props.timeZone),
                })
              }
              isOutsideRange={this.isOutsideRange}
            />
          </div>
        </div>
        <div className={css.formRow}>
          <FieldSelect
            name="bookingStartTime"
            id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
            className={css.fieldSelect}
            selectClassName={classNames(css.select, {
              [css.placeholder]: !values.bookingStartTime,
            })}
            label={intl.formatMessage({ id: 'FieldDateAndTimeInput.startTime' })}
            validate={required(intl.formatMessage({ id: 'General.fieldRequired' }))}
          >
            <option value="" disabled selected hidden>
              {intl.formatMessage({ id: 'BookingTimeForm.startTimePlaceholder' })}
            </option>
            {availableStartTimes.map((timeString, i) => (
              <option key={timeString + i} value={timeString}>
                {timeString}
              </option>
            ))}
          </FieldSelect>
        </div>
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  dayCountAvailableForBooking: number,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
