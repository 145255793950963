// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export const convertHours12to24 = time12h => {
  let hours = time12h.replace(/[^\d]/g, '');
  const modifier = time12h.replace(/[^A-Z]/g, '');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = Number.parseInt(hours, 10) + 12;
  }

  const hoursFinal = hours > 9 ? hours : `0${+hours}`;

  return `${hoursFinal}:00`;
};

export const convertHours24To12 = timeString => {
  const [hourString] = timeString.split(':');
  const hour = +hourString % 24;

  return (hour % 12 || 12) + (hour < 12 ? 'AM' : 'PM');
};

export const capitalizeWord = s => `${s.charAt(0).toUpperCase()}${s.substr(1)}`;
