import React from 'react';
import { bool, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import Decimal from 'decimal.js';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  propTypes,
} from '../../util/types';

import css from './OrderBreakdown.module.css';
import classNames from 'classnames';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

const nonCommissionReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && item.reversal);
};

const LineItemDeposit = props => {
  const {
    transaction,
    isProvider,
    intl,
    asterisks = '*',
    isRefunded,
    lineItems,
    marketplaceCurrency,
  } = props;

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(lineItems);

  const refund = lineItemsTotal(refundLineItems, marketplaceCurrency);

  const formattedRefund =
    refundLineItems.length > 0
      ? formatMoney(intl, new Money(-refund.amount, marketplaceCurrency))
      : null;

  return (
    <div className={css.lineItem}>
      <div className={classNames(css.itemLabel, css.bold)}>
        {`${intl.formatMessage({ id: 'General.deposit' })}${asterisks}`}
      </div>
      <div className={classNames(css.itemValue, css.bold)}>
        {!isRefunded ? formattedTotalPrice : formattedRefund}
      </div>
    </div>
  );
};

LineItemDeposit.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  asterisks: string,
  isRefunded: bool,
};

export default LineItemDeposit;
