import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './OrderBreakdown.module.css';

const EventTypeMaybe = props => {
  const { eventType } = props;

  if (!eventType) return null;

  return (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="General.eventType" />
        </span>

        <span className={css.itemValue}>
          <FormattedMessage id={`General.${eventType}`} />
        </span>
      </div>
    </>
  );
};

export default EventTypeMaybe;
