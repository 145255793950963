import React from 'react';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  propTypes,
} from '../../util/types';
import { calculateVATAmount, estimatedTotalPrice } from '../../util/booking';
import { getProcess, resolveLatestProcessName } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';
import { bool } from 'prop-types';
import { useConfiguration } from '../../context/configurationContext';
import classNames from 'classnames';

const { Money } = sdkTypes;

const VAT_PERCENTAGE = 20;

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

const LineItemFullPrice = props => {
  const {
    transaction,
    txLineItems,
    depositLineItems,
    isProvider,
    intl,
    currency,
    refunded,
    asterisks,
    vatRegistered,
  } = props;

  const config = useConfiguration();

  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName || !txLineItems?.length) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const providerCommision = depositLineItems?.find(isCommission);

  const customerLineItems = txLineItems.filter(item => item.includeFor.includes('customer'));
  const providerLineItems = txLineItems.filter(item => item.includeFor.includes('provider'));

  const lineItemsFinal = isProvider ? [...providerLineItems, providerCommision] : customerLineItems;

  if (!lineItemsFinal.length) return null;

  const totalPrice = refunded
    ? new Money(0, config.currency)
    : estimatedTotalPrice(lineItemsFinal, currency);

  const totalPriceCustomer = refunded
    ? new Money(0, config.currency)
    : estimatedTotalPrice(customerLineItems, currency);

  const vatAmount = calculateVATAmount(totalPriceCustomer.amount);
  const vatPrice = new Money(vatAmount, currency);
  const vatLabel = (
    <FormattedMessage id="OrderBreakdown.vat" values={{ percentage: VAT_PERCENTAGE }} />
  );

  return (
    <>
      <hr className={css.totalDivider} />
      {vatRegistered && (
        <div className={css.lineItemTotal}>
          <span className={css.itemLabel}>{vatLabel}</span>

          <span className={css.itemValue}>{formatMoney(intl, vatPrice)}</span>
        </div>
      )}
      <div className={css.lineItemTotal}>
        <span className={classNames(css.totalLabel, css.bold)}>
          {totalLabel}
          {asterisks}
        </span>
        <span className={css.totalPrice}>{formatMoney(intl, totalPrice)}</span>
      </div>
    </>
  );
};

LineItemFullPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  refunded: bool,
};

export default LineItemFullPrice;
