import React from 'react';

const IconNote = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49909 7.19992H5.39909C5.89614 7.19992 6.29909 6.79697 6.29909 6.29992C6.29909 5.80286 5.89614 5.39992 5.39909 5.39992H4.49909C4.00203 5.39992 3.59909 5.80286 3.59909 6.29992C3.59909 6.79697 4.00203 7.19992 4.49909 7.19992ZM4.49909 8.99992C4.00203 8.99992 3.59909 9.40287 3.59909 9.89992C3.59909 10.397 4.00203 10.7999 4.49909 10.7999H9.89909C10.3961 10.7999 10.7991 10.397 10.7991 9.89992C10.7991 9.40287 10.3961 8.99992 9.89909 8.99992H4.49909ZM14.4 6.246C14.3906 6.16332 14.3725 6.08187 14.346 6.003V5.922C14.3027 5.82946 14.245 5.7444 14.175 5.67L8.775 0.27C8.7006 0.199995 8.61554 0.142273 8.523 0.099C8.49613 0.0951842 8.46886 0.0951842 8.442 0.099C8.35057 0.0465672 8.2496 0.0129098 8.145 0H2.7C1.20883 0 0 1.20883 0 2.7V15.3C0 16.7912 1.20883 18 2.7 18H11.7C13.1912 18 14.4 16.7912 14.4 15.3V6.3C14.4 6.3 14.4 6.3 14.4 6.246ZM8.99918 3.06903L11.3302 5.40003H9.89918C9.40212 5.40003 8.99918 4.99709 8.99918 4.50003V3.06903ZM12.601 15.2999C12.601 15.797 12.1981 16.1999 11.701 16.1999H2.70101C2.20395 16.1999 1.80101 15.797 1.80101 15.2999V2.69991C1.80101 2.20286 2.20395 1.79991 2.70101 1.79991H7.20101V4.49991C7.20101 5.99108 8.40984 7.19991 9.90101 7.19991H12.601V15.2999ZM9.89909 12.5999H4.49909C4.00203 12.5999 3.59909 13.0029 3.59909 13.4999C3.59909 13.997 4.00203 14.3999 4.49909 14.3999H9.89909C10.3961 14.3999 10.7991 13.997 10.7991 13.4999C10.7991 13.0029 10.3961 12.5999 9.89909 12.5999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconNote;
