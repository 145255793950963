import React from 'react';

const IconLocation = props => {
  const { className } = props;

  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4412 2.47771C11.1376 -0.825905 5.78133 -0.825905 2.47771 2.47771C-0.825905 5.78133 -0.825905 11.1376 2.47771 14.4412L7.73167 19.7051C7.91886 19.8938 8.17368 20 8.43951 20C8.70533 20 8.96015 19.8938 9.14735 19.7051L14.4412 14.3913C17.731 11.1015 17.731 5.76759 14.4412 2.47771ZM13.0163 12.9657L8.46019 17.5417L3.9041 12.9657C1.39069 10.45 1.39193 6.37326 3.90688 3.85908C6.42183 1.3449 10.4985 1.3449 13.0135 3.85908C15.5284 6.37326 15.5297 10.45 13.0163 12.9657ZM5.46901 5.39883C3.79332 7.07969 3.79332 9.79941 5.46901 11.4803C6.69156 12.7049 8.52942 13.0767 10.1318 12.4235C11.7343 11.7704 12.7885 10.2197 12.8066 8.4894C12.8157 7.34778 12.3626 6.25103 11.5504 5.44868C10.7524 4.63233 9.66168 4.16801 8.52009 4.15865C7.3785 4.14929 6.28033 4.59567 5.46901 5.39883ZM10.1435 10.0646C9.36431 10.8557 8.13429 10.9847 7.20793 10.3726C6.28157 9.76052 5.91791 8.57842 6.34003 7.55147C6.76215 6.52451 7.85201 5.93988 8.94106 6.15619C10.0301 6.3725 10.8138 7.32928 10.8114 8.4396C10.7969 9.05499 10.5387 9.63946 10.0936 10.0646H10.1435Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconLocation;
