import React from 'react';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import { number, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import css from './YearAndMonthPicker.module.css';
import { parse, stringify } from '../../util/urlHelpers';
import NamedLink from '../NamedLink/NamedLink';

const TODAY = new Date();
const TODAY_YEAR = TODAY.getFullYear();
const TODAY_MONTH = TODAY.getMonth();

const generateAvailableYears = (maxYearsInPast, maxYearsInFuture) => {
  const years = [];
  for (let i = -maxYearsInPast; i <= maxYearsInFuture; i++) years.push(TODAY_YEAR + i);
  return years;
};

const checkIfDateIsInRange = (availableYears, date) =>
  date.getFullYear() >= availableYears[0] &&
  date.getFullYear() <= availableYears[availableYears.length - 1];

const getYearAndMonthFromDate = date => {
  return { year: date.getFullYear(), month: date.getMonth() };
};

const decreaseMonth = date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - 1);
  return getYearAndMonthFromDate(newDate);
};

const increaseMonth = date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + 1);
  return getYearAndMonthFromDate(newDate);
};

const changeYear = (date, year) => {
  const newDate = new Date(date);
  newDate.setFullYear(year);
  return getYearAndMonthFromDate(newDate);
};

const YearAndMonthPicker = props => {
  const { maxYearsInPast, maxYearsInFuture, pageName } = props;

  const availableYears = generateAvailableYears(maxYearsInPast, maxYearsInFuture);

  const history = useHistory();
  const location = useLocation();
  const { pathname, search, state } = location;
  const { page, ...searchParams } = parse(search);

  const date = new Date(
    searchParams?.year || TODAY_YEAR,
    searchParams.month !== undefined ? searchParams.month : TODAY_MONTH
  );

  const decreaseMonthLinkDisabled = !checkIfDateIsInRange(
    availableYears,
    new Date(date.getFullYear(), date.getMonth() - 1)
  );

  const increaseMonthLinkDisabled = !checkIfDateIsInRange(
    availableYears,
    new Date(date.getFullYear(), date.getMonth() + 1)
  );

  const prevMonthLink = decreaseMonthLinkDisabled ? (
    <div className={css.disabledLink}>
      <IconArrowHead direction="left" />
    </div>
  ) : (
    <NamedLink
      name={pageName}
      to={{ search: `?${stringify({ ...searchParams, ...decreaseMonth(date) })}` }}
      className={css.link}
      disabled={decreaseMonthLinkDisabled}
    >
      <IconArrowHead direction="left" />
    </NamedLink>
  );

  const nextMonthLink = increaseMonthLinkDisabled ? (
    <div className={css.disabledLink}>
      <IconArrowHead direction="right" />
    </div>
  ) : (
    <NamedLink
      name={pageName}
      to={{ search: `?${stringify({ ...searchParams, ...increaseMonth(date) })}` }}
      className={css.link}
      disabled={increaseMonthLinkDisabled}
    >
      <IconArrowHead direction="right" />
    </NamedLink>
  );

  return (
    <div className={css.root}>
      {prevMonthLink}
      <div className={css.date}>
        {date.toLocaleString('default', { month: 'long' })}
        <select
          value={date.getFullYear()}
          onChange={e => {
            const searchString = `?${stringify({
              ...searchParams,
              ...changeYear(date, e.target.value),
            })}`;
            history.push(`${pathname}${searchString}`, state);
          }}
        >
          {availableYears.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {nextMonthLink}
    </div>
  );
};

YearAndMonthPicker.propTypes = {
  pageName: string,
  maxYearsInPast: number,
  maxYearsInFuture: number,
};

export default YearAndMonthPicker;
