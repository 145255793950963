import classNames from 'classnames';
import React from 'react';

import css from './PrivacyPolicyContent.module.css';
import ExternalLink from '../ExternalLink/ExternalLink';
import { H3 } from '../Heading/Heading';
import defaultConfig from '../../config/configDefault';
import appSettings from '../../config/settings';
import { googleAnalyticsId } from '../../config/configAnalytics';

const PrivacyPolicyContent = props => {
  const { className } = props;

  return (
    <div className={classNames(css.root, className)}>
      <p className={css.center}>
        <b>Music Marmalade External Privacy and Cookie Notice</b>
      </p>
      <p className={css.center}>Last updated: [14/07/2024]</p>
      <ol className={css.orderList}>
        <li>
          <H3 as="h2">Overview</H3>
          <hr />

          <p>
            <b>What does this document do?</b>
          </p>
          <p>
            This document sets out what information Entium Platforms Limited (trading as Music
            Marmalade) collects about people, what we use it for and who we share it with. It
            explains what legal rights individuals have in relation to their information and what to
            do if they have any concerns about how their information is being used.&nbsp; We will
            refer to ourselves as Music Marmalade throughout this document.
          </p>
          <p>
            Music Marmalade sometimes needs to update this document to reflect any changes to our
            business activities or to comply with new legal requirements. If we have your email
            address, we will email you to notify you of any important changes before they take
            effect.
          </p>
          <p>
            If you have any questions about this document or the way Music Marmalade uses people's
            information, please get in touch by emailing {defaultConfig.supportEmail}. &nbsp;
          </p>
          <p>
            <b>Who should read this document?</b>
          </p>
          <p>The information in this document will be relevant to you if you:</p>
          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>visit our website at www.musicmarmalade.com or contact us via emai</div>l
            </li>
            <li>
              <div>are an Artist or Client of Music Marmalad</div>e
            </li>
            <li>
              <div>
                have been provided login credentials and access to our platform (<b>User</b>)
              </div>
            </li>
            <li>
              <div>
                are a person identified by any content uploaded by, or an enquiry submitted by, or
                information provided by a User, or key contact or website visitor
              </div>
            </li>
            <li>
              <div>
                sign up to our mailing list or otherwise indicate you wish to receive communications
                from us
              </div>
            </li>
          </ul>

          <p>
            <b>Who we are</b>
          </p>
          <p>
            We are Entium Platforms Limited, a company registered in Scotland under company number
            SC797778 whose registered office is at Unit A7 Somervell Trading Estate, Somervell
            Street, Cambuslang, Glasgow, G72 7EB (Music Marmalade<b>, we, use</b> or <b>our</b>).
          </p>
          <p>
            <b>Our legal status under data protection law</b>
          </p>
          <p>
            As a company located in the UK, our use of people's information is regulated by the
            Information Commissioner's Office (<b>ICO</b>), the regulator responsible for ensuring
            organisations comply with UK data protection law.&nbsp;
          </p>
          <p>
            Whenever we collect, use or share information about people located outside the UK we
            comply with additional local laws that apply.
          </p>
          <p>
            Music Marmalade is the <b>controller</b> for your information (which means we decide
            what information we collect and how it is used). We are registered with the ICO under
            registration number {defaultConfig.icoRegistration}.
          </p>
        </li>

        <li>
          <H3 as="h2">What information we collect and receive</H3>

          <hr />

          <p>
            <b>Personal data</b> means any information that can (or could be used to) identify a
            living person. We have grouped together the different types of personal data that we
            collect and where we receive it from in the table below:
          </p>
          <table>
            <thead>
              <tr className={css.center}>
                <th>
                  <div>Personal Data</div>
                </th>

                <th>
                  <div>Received from</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>Identity data</b> - first name, and last name.
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you</div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Contact data</b> -email address, telephone number, social media handle,
                    address,
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you</div>
                    </li>
                    <li>
                      <div>
                        Stripe - our third party payment platform (see Stripe Privacy Information
                        here:{' '}
                        <ExternalLink href="https://stripe.com/gb/legal/privacy-center">
                          https://stripe.com/gb/legal/privacy-center
                        </ExternalLink>
                        )
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Financial</b> -order reference number, purchase or transaction history and
                    dates of payments made.
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>You</div>
                    </li>
                    <li>
                      <div>
                        Stripe - our third party payment platform (see Stripe Privacy Information
                        here:{' '}
                        <ExternalLink href="https://stripe.com/gb/legal/privacy-center">
                          https://stripe.com/gb/legal/privacy-center
                        </ExternalLink>
                        )
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Feedback and enquiries</b> - any responses you provide when you rate our
                    services or the artists listed on the Platform, or reply to a survey, any
                    information you send when you contact us, submit an enquiry on our website or
                    comment on our social media corporate accounts or content.
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you</div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Marketing</b> - your status as a marketing recipient (e.g. if you have signed
                    up to or opted out of receiving communications from us), your preferred method
                    of communication and how you have interacted with our communications and
                    content, your telephone preference service (TPS) status.
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>You (including via cookies or similar technologies)</div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Usage data</b> - login credentials, access permissions, audit logs, chat and
                    message logs, content or information input or uploaded, clickstream to and on
                    our website, download or upload errors, length of visit, page interaction
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you (including via cookies and similar technologies)</div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Technical data</b> - internet protocol (IP) address, browser type and
                    version, time zone setting and location, browser plug-in types and versions,
                    operating system and type of device used to access our website.
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you (including via cookies and similar technologies)</div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Image and audio</b> - profile picture, photograph, video footage, audio
                    recording (both via YouTube).
                  </p>
                </td>

                <td>
                  <ul className={css.discList}>
                    <li>
                      <div>you </div>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <b>Aggregated Data</b>
          </p>
          <p>
            We sometimes <b>anonymise</b> the personal data we collect (so it can no longer be used
            to identify you as an individual) and then combine it with other anonymous information
            so that it becomes <b>aggregated data</b>. Aggregated data helps us identify trends
            (such as the percentage of website visitors visiting a particular webpage on our
            website). Data protection law does not govern the use of aggregated data and the various
            rights described below do not apply to it.
          </p>
          <p>
            <b>What happens if you do not provide your personal data?</b>
          </p>
          <p>
            Some of the personal data we request is optional and we can provide our services without
            that information. In other cases, if you do not provide the information you may not be
            able to benefit from the full range of services on our Website.&nbsp;
          </p>
        </li>

        <li>
          <H3 as="h2">How we use your information</H3>
          <hr />

          <p>
            UK data protection law requires controllers to identify a legal justification (also
            known as a <b>lawful basis</b>) to collect and use your personal data. There are six
            lawful basis which organisations can rely on to justify their collection and use of
            personal data.
          </p>
          <p>
            Whenever Music Marmalade acts as the controller for personal data, we rely on the
            following lawful basis:
          </p>

          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>
                to enter into and perform our <b>contract</b> with you;
              </div>
            </li>

            <li>
              <div>
                to pursue our <b>legitimate interests</b> (our justifiable business aims) but only
                if those interests are not outweighed by your other rights and freedoms (e.g. your
                right to privacy);
              </div>
            </li>

            <li>
              <div>
                to comply with a <b>legal obligation</b> that we have;
              </div>
            </li>

            <li>
              <div>
                to do something that you have given your <b>consent</b> (permission) for.
              </div>
            </li>
          </ul>

          <p>
            The table below provides more detail about the reasons Music Marmalade may use your
            personal data. If we intend to use your personal data for a new reason that is not
            listed in the table, we will update this document and notify you.
          </p>

          <table>
            <thead>
              <tr className={css.center}>
                <th>Reason</th>
                <th>Legal Justification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>taking steps to enter into the contract with Clients and Artists&nbsp;</p>
                </td>
                <td>
                  <p>performance of contract (for Clients and Artists)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to provide our service&nbsp;</p>
                </td>
                <td>
                  <p>performance of contract (for Clients and Artists)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to investigate and respond to complaints</p>
                </td>
                <td>
                  <p>
                    legitimate interest - necessary to remedy errors, improve service and protect
                    our reputation
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to obtain feedback</p>
                </td>
                <td>
                  <p>legitimate interest - necessary to improve our services</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    to respond to requests for support from Clients and Artists and other queries
                  </p>
                </td>
                <td>
                  <p>
                    legitimate interest - necessary to perform our obligation under the contract
                    with the Client and/or Artist and ensure our website is functioning correctly
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to process payments and recover any monies owed to us</p>
                </td>
                <td>
                  <p>legitimate interest - necessary to generate revenue and recover debts due</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to better understand how our website and services are used</p>
                </td>
                <td>
                  <p>legitimate interest - necessary to improve our services</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to provide and protect our services, websites and internal systems</p>
                </td>
                <td>
                  <p>
                    legitimate interest - necessary to provide our services and website, monitor and
                    improve network security and prevent fraud
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to send you service notifications and updates</p>
                </td>
                <td>
                  <p>performance of contract (for Clients and Artists)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to lodge or respond to a legal claim</p>
                </td>
                <td>
                  <p>
                    legitimate interest - necessary to enforce our contractual or legal right or to
                    effectively respond to a claim made against us
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to notify you about changes to this document</p>
                </td>
                <td>
                  <p>legal obligation</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to enable a person to exercise their legal rights</p>
                </td>
                <td>
                  <p>legal obligation</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to share your information between Clients and Artists</p>
                </td>
                <td>
                  <p>performance of contract (for Clients and Artists)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>to send marketing communications</p>
                </td>
                <td>
                  <p>legitimate interest - necessary to promote and grow our business consent</p>
                </td>
              </tr>
            </tbody>
          </table>
        </li>

        <li>
          <H3 as="h2">
            Marketing
            <hr />
          </H3>

          <p>
            We always ask for consent before we send you marketing communications. If you sign up to
            our mailing list and later change your mind we include a link in all our emails so that
            you can unsubscribe at any time.&nbsp;
          </p>

          <p>
            Where you have bought services from us before, we may send you marketing messages about
            similar services we think would be of interest to you. We'll stop doing this if you
            indicate you do not want to receive these types of messages, e.g. you unsubscribe via
            the link in the email.
          </p>
        </li>

        <li>
          <H3 as="h2">
            Who we share your information with
            <hr />
          </H3>

          <p>We share (or may share) your personal data with:</p>
          <p>
            <b>Our staff:</b> Music Marmalade employees (or other types of workers including
            contractors such as web developers) who have contracts containing confidentiality and
            data protection obligations.
          </p>
          <p>
            <b>If you are an Artist:</b> we will share certain information with a Client that books
            you for an Event.
          </p>
          <p>
            <b>If you are a Client:</b> we will share certain information with an Artist that you
            book for an Event.
          </p>
          <p>
            <b>Our supply chain: </b>
            <i>
              We use Stripe for payment, analytics, and other business services. Stripe collects and
              processes personal data, including identifying information about the devices that
              connect to its services. Stripe uses this information to operate and improve the
              services it provides to us, including for fraud detection and prevention. You can
              learn more about Stripe and its processing activities via privacy policy at
              https://stripe.com/privacy.
            </i>
          </p>

          <p>
            <b>Regulatory authorities</b>: such as HM Revenue &amp; Customs.
          </p>
          <p>
            <b>Our professional advisers:</b> such as our accountants or legal advisors where we
            require specialist advice to help us conduct our business.
          </p>
          <p>
            <b>Any actual or potential buyer of our business</b>.
          </p>
          <p>
            If we were asked to provide personal data in response to a court order or legal request
            (e.g. from the police), we would seek legal advice before disclosing any information and
            carefully consider the impact on your rights when providing a response.
          </p>
        </li>

        <li>
          <H3 as="h2">Where your information is located or transferred to</H3>

          <hr />

          <p>
            Music Marmalade will only transfer personal data outside the UK where we have a valid
            legal mechanism in place (to make sure that your personal data is guaranteed a level of
            protection, regardless of where in the world it is located, e.g. by only sending it to
            territories approved by or under contracts approved by UK Secretary of State).&nbsp;
          </p>

          <p>
            If you access our service whilst abroad then your personal data may be stored on
            services located in the same country that you are.
          </p>
        </li>

        <li>
          <H3 as="h2">How we keep your information safe</H3>
          <hr />

          <p>
            We have security measures to prevent your personal data from being accidentally or
            illegally lost, used or accessed by those who do not have permission. These measures
            include:
          </p>
          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>access controls and user authentication</div>
            </li>

            <li>
              <div>internal IT and network security</div>
            </li>

            <li>
              <div>regular testing and review of our security measures</div>
            </li>

            <li>
              <div>staff policies and training</div>
            </li>

            <li>
              <div>incident and breach reporting processes</div>
            </li>

            <li>
              <div>business continuity and disaster recovery processes</div>
            </li>
          </ul>

          <p>
            If there is an incident which has affected your personal data, we will notify the
            regulator and keep you informed (where required under data protection law).
          </p>
        </li>

        <li>
          <H3 as="h2">
            How long we keep your information
            <hr />
          </H3>

          <p>
            We will only keep your personal data for as long as necessary to fulfil the purposes we
            collected it for or as long as required to fulfil our legal obligations.&nbsp;
          </p>
          <p>
            When we consider how long to keep your personal data, we will consider whether it is
            still necessary to keep it for the purpose which we collected it or whether the same
            purpose could be achieved by holding less personal data. We will also consider the
            volume, nature, and sensitivity of the personal data and the potential harm to you if
            there was an incident affecting your personal data.
          </p>
          <p>
            If you browse our website, we keep personal data collected through our analytics tools
            for only as long as necessary to fulfil the purposes we collected it for (see our cookie
            information below).
          </p>
          <p>
            We keep the contact details of people who have subscribed to our mailing lists until we
            receive a request to remove their details or unsubscribe them.&nbsp;
          </p>
        </li>

        <li>
          <H3 as="h2">Your legal rights</H3>
          <hr />

          <p>
            Under UK law, you have specific rights in relation to your personal data. If you are
            located outside the UK, you may have different or additional legal rights. If you want
            to exercise any of these rights, please email {defaultConfig.supportEmail}.
          </p>
          <p>UK data protection law grants the following legal rights:</p>

          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>
                the right of <b>access</b> (obtaining a copy of your personal data)
              </div>
            </li>

            <li>
              <div>
                he right to <b>rectification</b> (correcting your personal data)
              </div>
            </li>

            <li>
              <div>
                The right to <b>erasure</b> (deleting your personal data)
              </div>
            </li>

            <li>
              <div>
                The right to <b>restrict</b> processing (to stop use of your personal data for a
                time limited period)
              </div>
            </li>

            <li>
              <div>
                The right to data <b>portability</b> (to move your personal data to another
                organisation)
              </div>
            </li>

            <li>
              <div>
                The right to <b>object</b> (to object to our use of your personal data)
              </div>
            </li>

            <li>
              <div>
                The right to complain to the ICO (you can find further details of how to do this on
                their website) - note: if you are located outside the UK, you can also complain to
                the regulator responsible for data protection compliance in the country you are
                located.
              </div>
            </li>
          </ul>

          <p>
            There are some limited exemptions to these rights, so they may not apply in every
            scenario and Music Marmalade may decline your request (but we would explain our decision
            in writing if this was the case). Music Marmalade will also not action a request where
            we have been unable to confirm your identity (this is one of our security processes to
            make sure we keep information safe) or if we feel the request is unfounded or excessive.
          </p>
        </li>

        <li>
          <H3 as="h2">
            Cookies and similar technologies
            <hr />
          </H3>

          <p>Our website uses cookies and similar technologies.</p>
          <p>
            <b>Cookies</b> are small text files that are downloaded to your device. Cookies contain
            uniquely generated references which are used to distinguish you from other users. They
            allow information gathered on one webpage to be stored until it is needed for use on
            another, allowing our website to provide you with a personalised experience (like
            remembering your favourites) and provide us with statistics about how you interact with
            our website.
          </p>
          <p>
            Cookies are not harmful to your devices (like a virus or malicious code) but some
            individuals prefer not to share their information (for example, to avoid targeted
            advertising).
          </p>
          <p>
            We mention <b>similar technologies</b> because there are other technologies which
            perform similar functions (e.g. beacons and pixels) and their use is regulated by the
            same laws that apply to cookies.
          </p>
          <p>
            <b>Different types of cookies</b>
          </p>
          <p>
            <u>Session vs. persistent cookies</u>: cookies have a limited lifespan. Cookies which
            only last a short time or end when you close your browser are called{' '}
            <b>session cookies</b>. Cookies which remain on your device for longer are called{' '}
            <b>persistent cookies</b> (these are the type of cookies allow websites to remember your
            details when you log back onto them).
          </p>
          <p>
            <u>First party vs third party cookies:</u> cookies placed on your device by the website
            owner are called <b>first party cookies</b>. When the website owner uses other
            businesses' technology to help them manage and monitor their website, the cookies added
            by the other business are called <b>third party cookies</b>.
          </p>

          <p>
            Categories of cookies
            <u>
              : cookies can be grouped by what they help the website or website owner do (the
            </u>{' '}
            <b>
              <u>Purpose</u>
            </b>
            <u>).</u>
          </p>

          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>
                <b>Necessary cookies</b> are cookies which help the website to run properly (when
                they are strictly necessary cookies it means their only function is to help the
                website work).&nbsp;
              </div>
            </li>

            <li>
              <div>
                <b>Performance cookies</b> help a website owner understand and analyse how website
                visitors use their website.&nbsp;
              </div>
            </li>

            <li>
              <div>
                <b>Analytical cookies</b> are used to understand how visitors interact with the
                website. These cookies help provide information on metrics the number of visitors,
                bounce rate, etc.
              </div>
            </li>

            <li>
              <div>
                <b>Marketing cookies</b> tailor online adverts to reflect the content you have
                previously browse and help inform companies about your interests so they can show
                you relevant adverts.
              </div>
            </li>
          </ul>

          <p>
            <b>What does Music Marmalade use cookies for?</b>
          </p>
          <p>We may use cookies to:</p>

          <ul className={classNames(css.discList, css.indent)}>
            <li>
              <div>
                to track how visitors use our website, to improve our service and user experience
              </div>
            </li>

            <li>
              <div>to record whether you have seen specific messages we display on our website</div>
            </li>

            <li>
              <div>
                to keep you signed into our website, to improve our service and user experience
              </div>
            </li>

            <li>
              <div>
                where we post content and links to content, we use cookies to capture and analyse
                information such as number of views and shares
              </div>
            </li>
          </ul>

          <p>The cookies we use are:</p>

          <table>
            <thead>
              <tr>
                <th>Cookie</th>
                <th>Purpose</th>

                <th>What it does</th>

                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>st-{appSettings.sdk.clientId}-token</p>
                </td>
                <td>
                  <p>Functional</p>
                </td>
                <td>
                  <p>Authentication and authorisation</p>
                </td>
                <td>
                  <p>1 month</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>__stripe_sid</p>
                </td>
                <td>
                  <p>Functional</p>
                </td>
                <td>
                  <p>Fraud prevention</p>
                </td>
                <td>
                  <p>30 minutes</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>__stripe_mid</p>
                </td>
                <td>
                  <p>Functional</p>
                </td>
                <td>
                  <p>Fraud prevention</p>
                </td>
                <td>
                  <p>1 year</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_ga</p>
                </td>
                <td>
                  <p>User Identification</p>
                </td>
                <td>
                  <p>To distinguish users</p>
                </td>
                <td>
                  <p>2 years</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_ga_{googleAnalyticsId?.substring(2)}</p>
                </td>
                <td>
                  <p>Session Management</p>
                </td>
                <td>
                  <p>Persists session state</p>
                </td>
                <td>
                  <p>2 years</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            We can only use cookies with your permission (you will be prompted by a message when you
            first visit our Website, also known as a cookie banner, where you can choose to accept
            or decline our cookies).
          </p>
          <p>
            You can update your cookie settings on our website by clicking "Accept All" or "Accept
            Necessary" on the cookie banner.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicyContent;
