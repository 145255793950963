import {
  Ceremony,
  DJ,
  Extra,
  ExtraMembers,
  Guitar,
  Microphone,
  MusicalNote,
  Party,
  Piano,
  Reception,
  Saxophone,
  StrollingBand,
  TrebleClef,
  Trumpet,
} from '../assets/iconImages';

import {
  ADD_ONS,
  AVAILABILITY,
  DELIVERY,
  DETAILS,
  GALLERY,
  GENERAL,
  PRICING,
  PRICING_AND_STOCK,
  FAQ,
} from '../constants/listing';

export const ADD_ON_ICON_OPTIONS = [
  {
    value: 'sax',
    image: Saxophone,
  },
  {
    value: 'ceremony',
    image: Ceremony,
  },
  {
    value: 'dj',
    image: DJ,
  },
  {
    value: 'extra',
    image: Extra,
  },
  {
    value: 'extra-members',
    image: ExtraMembers,
  },
  {
    value: 'guitar',
    image: Guitar,
  },
  {
    value: 'microphone',
    image: Microphone,
  },
  {
    value: 'note',
    image: MusicalNote,
  },
  {
    value: 'party',
    image: Party,
  },
  {
    value: 'piano',
    image: Piano,
  },
  {
    value: 'reception',
    image: Reception,
  },
  {
    value: 'strolling-band',
    image: StrollingBand,
  },
  {
    value: 'treble-clef',
    image: TrebleClef,
  },
  {
    value: 'trumpet',
    image: Trumpet,
  },
];

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
export const tabCompleted = (tab, listing) => {
  const {
    availabilityPlan,
    geolocation,
    price,
    title,
    description,
    publicData,
  } = listing.attributes;
  const images = listing.images;
  const {
    shippingEnabled,
    pickupEnabled,
    location,
    leaderName,
    leaderLastName,
    bandType,
    bandCategory,
    numOfBandMembers,
    standardBreakDuration,
    standardPerformanceDuration,
    standardPerformanceStartTime,
    serviceableLocations,
    estimatedSetUpDuration,
    videoLinks,
  } = publicData || {};
  const deliveryOptionPicked = publicData && (shippingEnabled || pickupEnabled);

  switch (tab) {
    case GENERAL:
      return !!(title && leaderName && leaderLastName && geolocation && location?.address);
    case DETAILS:
      return !!(
        bandType &&
        bandCategory &&
        numOfBandMembers &&
        standardBreakDuration &&
        standardPerformanceDuration &&
        standardPerformanceStartTime &&
        serviceableLocations &&
        estimatedSetUpDuration
      );
    case PRICING:
      return !!price;
    case PRICING_AND_STOCK:
      return !!price;
    case DELIVERY:
      return !!deliveryOptionPicked;

    case AVAILABILITY:
      return !!availabilityPlan;
    case GALLERY:
      return description && images && images.length > 0 && videoLinks && videoLinks.length > 0;
    case FAQ:
      return publicData?.hasOwnProperty('hasAddons');
    case ADD_ONS:
      return publicData?.hasOwnProperty('hasAddons');
    default:
      return false;
  }
};

export const createFilterOptions = (options, optionAll) => {
  const filterOptions = options.map(o => ({
    key: `${o.option}`,
    label: o.label,
    value: o.option,
    icon: o.icon,
    searchString: o.label,
    group: o?.group,
  }));
  optionAll &&
    filterOptions.unshift({
      key: 'all',
      label: 'All',
      value: options.map(option => option.option),
      searchString: 'All',
    });
  return filterOptions;
};

export const calculateTotalRating = (
  averageRating,
  ratingCount,
  externalRatingCount,
  externalAverageRating
) => {
  let totalRatingSum = 0;
  let totalRatingCount = 0;

  // Check and add ratings from each source if they exist
  if (averageRating !== undefined && ratingCount !== undefined) {
    totalRatingSum += averageRating * ratingCount;
    totalRatingCount += ratingCount;
  }

  if (externalAverageRating !== undefined && externalRatingCount !== undefined) {
    totalRatingSum += externalAverageRating * externalRatingCount;
    totalRatingCount += externalRatingCount;
  }

  // Calculate the total average rating
  const totalRating = totalRatingCount > 0 ? totalRatingSum / totalRatingCount : 0;

  return { totalRating, totalRatingCount };
};
