import React from 'react';

import Decimal from 'decimal.js';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { bool } from 'prop-types';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && !item.reversal);
};

const LineItemSubTotalMaybe = props => {
  const {
    txLineItems,
    depositLineItems,
    intl,
    marketplaceCurrency,
    isRefund,
    showHr = false,
    label,
  } = props;

  if (!txLineItems?.length) return null;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(txLineItems);
  const depositItems = nonCommissionNonReversalLineItems(
    depositLineItems.map(item => ({
      ...item,
      lineTotal: new Money(item.lineTotal.amount * -1, item.lineTotal.currency),
    }))
  );

  // line totals of those line items combined
  const subTotal = lineItemsTotal([...subTotalLineItems, ...depositItems], marketplaceCurrency);

  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  return formattedSubTotal ? (
    <>
      {showHr && <hr className={css.totalDivider} />}
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id={label || `OrderBreakdown.subTotal${isRefund ? 'Refund' : ''}`} />
        </span>
        <span className={css.itemValue}>
          {isRefund ? '-' : ''}
          {formattedSubTotal}
        </span>
      </div>
    </>
  ) : null;
};

LineItemSubTotalMaybe.propTypes = {
  intl: intlShape.isRequired,
  isRefund: bool,
};

export default LineItemSubTotalMaybe;
