import Axios from 'axios';
import bus, { AuthEventTypes } from '../modules/bus';
import RefreshTokenService from '../services/RefreshTokenService';
import { getTokenStore } from '../util/tokenStore';

const httpClient = Axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/` });

export const stHttpClient = Axios.create({ baseURL: 'https://flex-api.sharetribe.com/v1/' });

const setToken = config => {
  const token = getTokenStore().getToken()?.access_token;

  if (token && config.method !== 'OPTIONS') {
    config.headers.Authorization = `Bearer ${token}`;
  }
};

const refresh = async config => {
  await new Promise((resolve, reject) => {
    function onRefreshTokenSuccess() {
      setToken(config);
      removeHandlers();
      resolve();
      clearTimeout(timeout);
    }

    function onRefreshTokenFail() {
      reject(Error('Could not refresh token'));
      removeHandlers();
    }

    const removeSuccessListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess
    );
    const removeFailListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_FAIL,
      onRefreshTokenFail
    );

    function removeHandlers() {
      removeSuccessListener();
      removeFailListener();
    }

    var timeout = setTimeout(() => {
      removeHandlers();
    }, RefreshTokenService.checkRefreshingTreshold);
  });
};

httpClient.interceptors.request.use(
  function(config) {
    const sharetribeJwt = getTokenStore().getToken()?.access_token;

    if (sharetribeJwt) {
      config.headers.Authorization = `Bearer ${sharetribeJwt}`;
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(null, async error => {
  const { config } = error;

  if (error.response && [401, 403].includes(error.response.status)) {
    if (config.data?.skipRefresh) return;

    if (RefreshTokenService.triedRefresh) {
      bus.broadcastEvent(AuthEventTypes.LOGOUT);
      return;
    }

    if (RefreshTokenService.refreshingAtm) {
      await refresh(config);
      return httpClient(config);
    }

    try {
      await RefreshTokenService.refreshToken();
      return httpClient(config);
    } catch (err) {
      bus.broadcastEvent(AuthEventTypes.LOGOUT);

      error.preventDefault = true;
    }
  }
  return Promise.reject(error);
});

export default httpClient;
