import React from 'react';

const IconMembers = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6353 8.20706C11.6514 7.32748 12.2353 6.04983 12.2353 4.70588C12.2353 2.1069 10.1284 0 7.52941 0C4.93042 0 2.82353 2.1069 2.82353 4.70588C2.82351 6.04983 3.40739 7.32748 4.42353 8.20706C1.73265 9.42554 0.00282927 12.1049 0 15.0588C0 15.5786 0.421379 16 0.941176 16C1.46097 16 1.88235 15.5786 1.88235 15.0588C1.88235 11.94 4.41063 9.41176 7.52941 9.41176C10.6482 9.41176 13.1765 11.94 13.1765 15.0588C13.1765 15.5786 13.5978 16 14.1176 16C14.6374 16 15.0588 15.5786 15.0588 15.0588C15.056 12.1049 13.3262 9.42554 10.6353 8.20706ZM7.52978 7.52928C5.97039 7.52928 4.70625 6.26515 4.70625 4.70575C4.70625 3.14636 5.97039 1.88223 7.52978 1.88223C9.08917 1.88223 10.3533 3.14636 10.3533 4.70575C10.3533 5.4546 10.0558 6.17278 9.52632 6.70229C8.9968 7.23181 8.27863 7.52928 7.52978 7.52928ZM16.6956 7.83059C17.9259 6.44508 18.2287 4.46652 17.469 2.77647C16.7093 1.08642 15.0285 -0.00049542 13.1756 1.69405e-07C12.6558 1.69405e-07 12.2344 0.421379 12.2344 0.941177C12.2344 1.46097 12.6558 1.88235 13.1756 1.88235C14.7349 1.88235 15.9991 3.14649 15.9991 4.70588C15.9964 5.71192 15.4586 6.64051 14.5873 7.14353C14.3036 7.30721 14.1253 7.60665 14.1167 7.93412C14.1087 8.25894 14.2688 8.56492 14.5403 8.74353L14.9073 8.98823L15.0297 9.05412C17.346 10.1527 18.8146 12.4953 18.7944 15.0588C18.7944 15.5786 19.2158 16 19.7356 16C20.2553 16 20.6767 15.5786 20.6767 15.0588C20.6924 12.1222 19.1859 9.38698 16.6956 7.83059Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconMembers;
