import httpClient from '../httpClient';

const getArtistTransactions = params => {
  return httpClient.get(`transactions/provider`, { params });
};

const cancelTransaction = (txId, data) => {
  return httpClient.patch(`transactions/${txId}/cancel`, data);
};

const getUserTransactions = params => {
  return httpClient.get(`transactions/customer`, { params });
};

const getBookings = params => {
  return httpClient.get(`transactions/range`, { params });
};

const getDepositChargedUrl = txId => {
  return httpClient.get(`transactions/${txId}/deposit-charged/url`);
};

const getDepositTakenUrl = txId => {
  return httpClient.get(`transactions/${txId}/deposit-taken/url`);
};

const updateStartTime = (txId, startTime) => {
  return httpClient.post(`transactions/${txId}/start-time`, { eventDate: startTime });
};

const updateNotes = (txId, notes) => {
  return httpClient.post(`transactions/${txId}/notes`, { notes });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getArtistTransactions,
  getUserTransactions,
  cancelTransaction,
  getBookings,
  getDepositChargedUrl,
  getDepositTakenUrl,
  updateStartTime,
  updateNotes,
};
