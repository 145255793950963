import React from 'react';
import { InlineTextButton } from '../Button/Button';
import { useIntl } from 'react-intl';

import css from './FAQ.module.css';

const FAQ = props => {
  const { isEdit, question, onEdit, onRemove } = props;

  const intl = useIntl();

  return (
    <div className={css.root}>
      <span className={css.title}>{question?.title}</span>
      {isEdit && (
        <div className={css.actionBtns}>
          <InlineTextButton type="button" onClick={onEdit}>
            {intl.formatMessage({ id: 'General.edit' })}
          </InlineTextButton>
          <span>•</span>
          <InlineTextButton type="button" onClick={onRemove}>
            {intl.formatMessage({ id: 'General.remove' })}
          </InlineTextButton>
        </div>
      )}
    </div>
  );
};

export default FAQ;
