import React from 'react';

const IconArtistPlus = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1191 7.41171H19.0602V6.35289C19.0602 5.76812 18.5862 5.29407 18.0014 5.29407C17.4166 5.29407 16.9426 5.76812 16.9426 6.35289V7.41171H15.8838C15.299 7.41171 14.8249 7.88577 14.8249 8.47054C14.8249 9.05531 15.299 9.52936 15.8838 9.52936H16.9426V10.5882C16.9426 11.173 17.4166 11.647 18.0014 11.647C18.5862 11.647 19.0602 11.173 19.0602 10.5882V9.52936H20.1191C20.7038 9.52936 21.1779 9.05531 21.1779 8.47054C21.1779 7.88577 20.7038 7.41171 20.1191 7.41171ZM11.9647 9.23294C13.1079 8.24342 13.7647 6.80606 13.7647 5.29412C13.7647 2.37026 11.3944 0 8.47059 0C5.54673 0 3.17647 2.37026 3.17647 5.29412C3.17645 6.80606 3.83331 8.24342 4.97647 9.23294C1.94923 10.6037 0.00318293 13.618 0 16.9412C0 17.5259 0.474051 18 1.05882 18C1.6436 18 2.11765 17.5259 2.11765 16.9412C2.11765 13.4325 4.96196 10.5882 8.47059 10.5882C11.9792 10.5882 14.8235 13.4325 14.8235 16.9412C14.8235 17.5259 15.2976 18 15.8824 18C16.4671 18 16.9412 17.5259 16.9412 16.9412C16.938 13.618 14.9919 10.6037 11.9647 9.23294ZM8.47183 8.47048C6.71751 8.47048 5.29536 7.04833 5.29536 5.29401C5.29536 3.53969 6.71751 2.11754 8.47183 2.11754C10.2261 2.11754 11.6483 3.53969 11.6483 5.29401C11.6483 6.13646 11.3136 6.94441 10.7179 7.54011C10.1222 8.13582 9.31428 8.47048 8.47183 8.47048Z"
        fill="#EE2677"
      />
    </svg>
  );
};

export default IconArtistPlus;
