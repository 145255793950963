import React, { Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { BOOKING_PROCESS_NAME } from '../../../transactions/transaction';

import {
  FieldLocationAutocompleteInput,
  FieldSelect,
  FieldTextInput,
  Form,
  H6,
  IconSpinner,
  PrimaryButton,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import {
  autocompleteOutOfService,
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from '../../../util/validators';
import { EVENT_TYPE_OPTIONS } from '../../../constants/listing';
import AddOnsField from '../AddOnsField/AddOnsField';
import debounce from 'lodash/debounce';
import { getDistanceFromGoogle } from '../../../util/googleMaps';
import { createBookingDate } from '../../../util/booking';
import { BOOKING_LOCATION_TYPES } from '../../../constants/booking';

import css from './BookingTimeForm.module.css';

const handleVenueLocationChanged = (location, formRef) => {
  const { selectedPlace } = location || {};
  if (!selectedPlace) return;

  formRef.change('streetName', selectedPlace?.line1);
  formRef.change('city', selectedPlace?.city);
  formRef.change('location', { selectedPlace, search: selectedPlace?.postalCode });

  debounce(() => {
    formRef?.getFieldState('location').focus();
    formRef?.getFieldState('location').blur();
  }, 300)();
};

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.locationQuery = React.createRef();
    this.googleDistance = React.createRef();

    this.state = {
      hasGoogleDistanceError: false,
    };
  }

  handleFormSubmit(values) {
    const { venueLocation, venueName, location: formLocation, streetName, city, ...rest } = values;

    const location = {
      ...formLocation,
      selectedPlace: {
        ...formLocation.selectedPlace,
        name: venueName?.selectedPlace?.name || venueName?.search,
        line1: streetName,
        city: city,
      },
    };
    this.props.onSubmit({ ...rest, location, googleDistance: this.googleDistance.current });
  }

  // When the values of the form are updated we need to fetch
  // lineItems from this template's backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the orderData object.
  handleOnChange = debounce(async formValues => {
    const { listingGeolocation } = this.props;
    // If we are active on any field, don't call.
    // This is because of location input calling this
    // fn. on input text change.
    this.props?.onFormChange?.(formValues?.values);
    if (formValues.active && formValues.active === 'location') return;

    const {
      bookingStartDate,
      bookingEndDate,
      bookingStartTime,
      bookingEndTime,
      location,
      formAddOns,
    } = formValues.values;

    const { selectedPlace } = location || {};

    if (!selectedPlace?.address) {
      this.googleDistance.current = null;
    }

    if (selectedPlace?.address && this.locationQuery.current !== selectedPlace?.address) {
      this.locationQuery.current = selectedPlace.address;

      try {
        this.googleDistance.current = await getDistanceFromGoogle(
          listingGeolocation,
          selectedPlace.origin
        );

        this.setState({ hasGoogleDistanceError: false });
      } catch (error) {
        console.error(error);
        this.googleDistance.current = null;

        this.setState({ hasGoogleDistanceError: true });

        return;
      }
    }

    const startDate = createBookingDate(bookingStartDate?.date, bookingStartTime);
    const endDate = createBookingDate(bookingEndDate?.date, bookingEndTime);

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (
      this.googleDistance.current &&
      startDate &&
      endDate &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        orderData: {
          bookingStart: startDate,
          bookingEnd: endDate,
          protectedData: {
            googleDistance: this.googleDistance.current,
            formAddOns,
          },
        },
        listingId,
        isOwnListing,
      });
    }
  }, 300);

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      dayCountAvailableForBooking,
      marketplaceName,
      availabilityStartTime,
      availabilityEndTime,
      addOns,
      serviceableLocations,
      currentUser,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const { isArtist } = currentUser?.attributes?.profile?.publicData || {};

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        mutators={{ ...arrayMutators }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            txLineItems,
            depositLineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            payoutDetailsWarning,
          } = fieldRenderProps;

          if (this.props.formRef) this.props.formRef.current = form;

          const startDate = createBookingDate(
            values.bookingStartDate?.date,
            values.bookingStartTime
          );
          const endDate = createBookingDate(values.bookingEndDate?.date, values.bookingEndTime);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedCustomerBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const breakdownData =
            startDate && endDate
              ? {
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown = breakdownData && depositLineItems && !fetchLineItemsError;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true, active: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  startDateInputProps={{
                    label: intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' }),
                    placeholderText: startDatePlaceholder,
                  }}
                  endDateInputProps={{
                    label: intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' }),
                    placeholderText: endDatePlaceholder,
                  }}
                  className={css.bookingDates}
                  listingId={listingId}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  dayCountAvailableForBooking={dayCountAvailableForBooking}
                  availabilityStartTime={availabilityStartTime}
                  availabilityEndTime={availabilityEndTime}
                />
              ) : null}

              <FieldLocationAutocompleteInput
                rootClassName={classNames(css.field, css.location)}
                inputClassName={css.input}
                iconClassName={css.locationIcon}
                format={value => {
                  if (!value) return value;
                  const { selectedPlace } = value || {};
                  if (!selectedPlace) return value;
                  return { ...value, search: selectedPlace?.name };
                }}
                id="venueName"
                name="venueName"
                label={intl.formatMessage({
                  id: 'General.venueName',
                })}
                placeholder={intl.formatMessage({
                  id: 'General.venueNamePlaceholder',
                })}
                types={BOOKING_LOCATION_TYPES}
                usePredictionAddress
                onChange={value => handleVenueLocationChanged(value, form)}
                validate={autocompleteSearchRequired(
                  intl.formatMessage({
                    id: 'General.fieldRequired',
                  })
                )}
              />

              <div className={css.locationInputs}>
                <FieldTextInput
                  className={css.field}
                  inputRootClass={classNames(css.input, css.venueName)}
                  name="streetName"
                  id="streetName"
                  label={intl.formatMessage({
                    id: 'General.streetName',
                  })}
                  placeholder={intl.formatMessage({ id: 'SignupForm.addressPlaceholder' })}
                />
                <FieldTextInput
                  className={css.field}
                  inputRootClass={classNames(css.input, css.venueName)}
                  name="city"
                  id="city"
                  label={intl.formatMessage({
                    id: 'General.city',
                  })}
                  placeholder={intl.formatMessage({ id: 'General.cityPlaceholder' })}
                />
                <FieldLocationAutocompleteInput
                  rootClassName={classNames(css.field, css.location)}
                  inputClassName={css.input}
                  iconClassName={css.locationIcon}
                  format={value => {
                    if (!value) return value;
                    const { selectedPlace } = value || {};
                    if (!selectedPlace) return value;
                    return { ...value, search: selectedPlace?.postalCode };
                  }}
                  id="location"
                  name="location"
                  label={intl.formatMessage({
                    id: 'General.postCode',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'General.postCodePlaceholder',
                  })}
                  validate={composeValidators(
                    autocompleteSearchRequired(
                      intl.formatMessage({
                        id: 'EditListingLocationForm.addressRequired',
                      })
                    ),
                    autocompletePlaceSelected(
                      intl.formatMessage({
                        id: 'EditListingLocationForm.addressNotRecognized',
                      })
                    ),
                    autocompleteOutOfService(
                      intl.formatMessage({ id: 'BookingTimeForm.locationNotServiced' }),
                      serviceableLocations
                    )
                  )}
                  types={['postal_code']}
                  usePredictionAddress
                />
              </div>
              {this.state.hasGoogleDistanceError && (
                <p className={css.googleError}>
                  {intl.formatMessage({ id: 'BookingTimeForm.googleDistanceError' })}
                </p>
              )}

              <FieldSelect
                name="eventType"
                id="eventType"
                className={classNames(css.field, css.eventType)}
                label={intl.formatMessage({ id: 'General.eventType' })}
                selectClassName={css.input}
              >
                {EVENT_TYPE_OPTIONS.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {intl.formatMessage({ id: label })}
                  </option>
                ))}
              </FieldSelect>

              <AddOnsField
                addOns={addOns}
                currency={unitPrice.currency}
                className={css.field}
                formAddOns={values.formAddOns}
              />

              {showEstimatedBreakdown ? (
                <div className={css.priceBreakdownContainer}>
                  <H6 as="h3" className={css.bookingBreakdownTitle}>
                    <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                  </H6>
                  <hr className={css.totalDivider} />
                  <EstimatedCustomerBreakdownMaybe
                    breakdownData={breakdownData}
                    txLineItems={txLineItems}
                    addOns={addOns}
                    depositLineItems={depositLineItems}
                    timeZone={timeZone}
                    currency={unitPrice.currency}
                    marketplaceName={marketplaceName}
                    processName={BOOKING_PROCESS_NAME}
                    vatRegistered={this.props.vatRegistered}
                  />

                  {fetchLineItemsInProgress && (
                    <div className={css.breakdownSpinner}>
                      <IconSpinner />
                    </div>
                  )}
                </div>
              ) : null}

              {fetchLineItemsError ? (
                <span className={css.sideBarError}>
                  <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
                </span>
              ) : null}

              <div className={css.submitButton}>
                <PrimaryButton
                  type="submit"
                  inProgress={fetchLineItemsInProgress}
                  disabled={isArtist}
                >
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
              <p className={css.finePrint}>
                {payoutDetailsWarning ? (
                  payoutDetailsWarning
                ) : (
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingTimeForm.ownListing'
                        : 'BookingTimeForm.youWontBeChargedInfo'
                    }
                  />
                )}
              </p>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  dayCountAvailableForBooking: number.isRequired,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
