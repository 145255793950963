import React from 'react';

const IconFilter = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9 0H2.1C0.940202 0 1.26615e-07 0.940202 1.26615e-07 2.1V2.919C-0.000100245 3.20806 0.0594765 3.49403 0.175 3.759V3.801C0.273896 4.02568 0.413975 4.22986 0.588 4.403L4.9 8.687V13.3C4.89952 13.5418 5.02391 13.7668 5.229 13.895C5.3404 13.964 5.46894 14.0004 5.6 14C5.70958 13.9993 5.81747 13.973 5.915 13.923L8.715 12.523C8.95039 12.4044 9.0992 12.1636 9.1 11.9V8.687L13.384 4.403C13.558 4.22986 13.6981 4.02568 13.797 3.801V3.759C13.9222 3.4961 13.9913 3.21004 14 2.919V2.1C14 0.940202 13.0598 0 11.9 0ZM7.90302 7.90293C7.77199 8.03503 7.69895 8.21387 7.70003 8.39993V11.4659L6.30002 12.1659V8.39993C6.3011 8.21387 6.22806 8.03503 6.09702 7.90293L2.38703 4.19993H11.613L7.90302 7.90293ZM12.6008 2.79993H1.40078V2.09993C1.40078 1.71333 1.71418 1.39993 2.10078 1.39993H11.9008C12.2874 1.39993 12.6008 1.71333 12.6008 2.09993V2.79993Z"
        fill="#EE2677"
      />
    </svg>
  );
};

export default IconFilter;
