import React from 'react';
import { useIntl } from 'react-intl';
import { InlineTextButton } from '../Button/Button';
import ReviewRating from '../ReviewRating/ReviewRating';
import { EVENT_TYPE_OPTIONS } from '../../constants/listing';
import IconArtist from '../IconArtist/IconArtist';
import IconCalendar from '../IconCalendar/IconCalendar';
import IconLocation from '../IconLocation/IconLocation';

import css from './ExternalArtistReview.module.css';
import classNames from 'classnames';

const ExternalArtistReview = props => {
  const { isEdit, review, onEdit, onRemove } = props;
  const { clientName, ratedExperience, eventType, eventDate, eventLocation } = review || {};
  const { selectedPlace, search } = eventLocation || {};

  const intl = useIntl();

  const eventDateFormatted = new Date(eventDate?.date)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className={css.root}>
      <div className={css.header}>
        <span className={css.client}>{clientName}</span>
        <ReviewRating className={css.rating} rating={ratedExperience} />
      </div>
      <div className={css.body}>
        <div className={css.info}>
          <IconArtist />
          {intl.formatMessage({
            id:
              EVENT_TYPE_OPTIONS.find(option => option.value === eventType)?.label ||
              'General.other',
          })}
        </div>
        <div className={css.info}>
          <IconCalendar />
          {eventDateFormatted}
        </div>
        <div className={classNames(css.info, css.location)}>
          <IconLocation />
          {selectedPlace?.name || search}
        </div>
      </div>
      {isEdit && (
        <div className={css.actionBtns}>
          <InlineTextButton type="button" onClick={onEdit}>
            {intl.formatMessage({ id: 'General.edit' })}
          </InlineTextButton>
          <span>•</span>
          <InlineTextButton type="button" onClick={onRemove}>
            {intl.formatMessage({ id: 'General.remove' })}
          </InlineTextButton>
        </div>
      )}
    </div>
  );
};

export default ExternalArtistReview;
