import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_LAYOVER, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { bool } from 'prop-types';

const LineItemLayoverMaybe = props => {
  const { lineItems, intl } = props;

  const layoutFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_LAYOVER && !item.reversal
  );

  return layoutFeeLineItem ? (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.layoverFee" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, layoutFeeLineItem.lineTotal)}</span>
      </div>
    </>
  ) : null;
};

LineItemLayoverMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
  isCustomer: bool,
};

export default LineItemLayoverMaybe;
