import React from 'react';
import PropTypes from 'prop-types';

const IconArtist = props => {
  const { className } = props;

  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77778 4.44444C7.77778 3.21714 8.77267 2.22222 10 2.22222C11.2273 2.22222 12.2222 3.21714 12.2222 4.44444C12.2222 5.67174 11.2273 6.66667 10 6.66667C8.77267 6.66667 7.77778 5.67174 7.77778 4.44444ZM10 0C7.5454 0 5.55556 1.98984 5.55556 4.44444C5.55556 6.89904 7.5454 8.88889 10 8.88889C12.4546 8.88889 14.4444 6.89904 14.4444 4.44444C14.4444 1.98984 12.4546 0 10 0ZM15.5556 16.6667C15.5556 16.053 16.053 15.5556 16.6667 15.5556C17.2803 15.5556 17.7778 16.053 17.7778 16.6667C17.7778 17.2803 17.2803 17.7778 16.6667 17.7778C16.053 17.7778 15.5556 17.2803 15.5556 16.6667ZM17.7778 13.523C17.4302 13.4002 17.0562 13.3333 16.6667 13.3333C14.8257 13.3333 13.3333 14.8257 13.3333 16.6667C13.3333 18.5077 14.8257 20 16.6667 20C18.5077 20 20 18.5077 20 16.6667V10.6867L20.6142 10.9938C21.1631 11.2682 21.8304 11.0458 22.1049 10.4969C22.3793 9.948 22.1569 9.28067 21.608 9.00622L19.3858 7.89511C19.0413 7.72287 18.6323 7.74127 18.3048 7.94367C17.9772 8.14622 17.7778 8.50378 17.7778 8.88889V13.523ZM0 16.6667C0 13.5984 2.48731 11.1111 5.55556 11.1111H11.6976C12.3112 11.1111 12.8087 11.6086 12.8087 12.2222C12.8087 12.8359 12.3112 13.3333 11.6976 13.3333H5.55556C3.71461 13.3333 2.22222 14.8257 2.22222 16.6667C2.22222 17.2803 2.71969 17.7778 3.33333 17.7778H10.3793C10.993 17.7778 11.4904 18.2752 11.4904 18.8889C11.4904 19.5026 10.993 20 10.3793 20H3.33333C1.49239 20 0 18.5077 0 16.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconArtist.defaultProps = {
  className: null,
};

IconArtist.propTypes = {
  className: string,
};

export default IconArtist;
