import React from 'react';

import css from './ArtistsTerms.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { useConfiguration } from '../../context/configurationContext';
import classNames from 'classnames';

const ArtistsTerms = props => {
  const { className } = props;

  const config = useConfiguration();
  const vatNumber = config.vatNumber;

  return (
    <div className={classNames(css.root, className)}>
      <p className={css.center}>
        <b>Music Marmalade Artist Terms</b>
      </p>

      <ol className={css.orderList}>
        <li>
          <h2 className={css.h2}>
            Who are we and what do we do?
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li data-list-text="1.1.">
              <p className={css.p}>
                We are Entium Platforms Ltd (trading as Music Marmalade), a company registered in
                Scotland under company registration number SC797777. Our registered office is at
                Unit A7, Somervell Trading Estate, Somervell Street, Cambuslang, Glasgow, G72 7EB.
                Our VAT registration number is {vatNumber}.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We will refer to ourselves as <b>Music Marmalade, us, or we </b>
                throughout these Terms. We may refer to you as <b>you </b>or the <b>Artist</b>.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We provide an online booking platform on our <b>Website</b>, allowing individuals (
                <b>Clients</b>) to make contact with and book Artists to perform at events (
                <b>Events</b>).
              </p>
            </li>
            <li>
              <p className={css.p}>
                When a Client uses the Website to book an Artist (<b>Booking</b>) they will enter
                into a Booking Contract (see below) with the Artist directly for their attendance at
                and performance at the Event.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade will facilitate the payment of Artist fees pursuant to the terms of
                this Agreement.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade is not involved in the Event at all and has no liability for the
                Client and their actions.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We encourage Clients to leave reviews of Artists they have booked, and recommend
                that Clients check the reviews before making a Booking.
              </p>
            </li>
            <li>
              <p className={css.p}>
                These terms and conditions (<b>Terms</b>) set out the terms on which you can
                register as a music artists (<b>Artist</b>) on our website (<b>Website</b>). By
                registering on our Website as an Artist, you confirm that you accept these Terms and
                you become legally bound by them.
              </p>
            </li>
            <li>
              <p className={css.p}>
                <span className="p">
                  For information regarding access to and use of our Website, please see our{' '}
                </span>
                <NamedLink name="TermsOfUsePage">Website Terms of Use</NamedLink>

                <span className="p"> which you agree to be bound by.</span>
              </p>
            </li>
            <li>
              <p className={css.p}>
                Please note that we reserve the right to update, change or replace any part of these
                Terms in our sole discretion. Current terms will be available here.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Registering with Music Marmalade as an Artist <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Artists must register to become listed on the Music Marmalade Website. Music
                Marmalade will review registrations and reserves the right to decline to list any
                Artist on its Website in its absolute discretion.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In order to make full use of the Website you must register as an Artist on the
                Website, and create and maintain a profile (<b>Artist Profile</b>). By doing so, you
                will also create and have access to an area of the Website where you can view
                Booking Requests, existing confirmed Bookings and Messages (<b>Artist Account</b>).
              </p>
            </li>
            <li>
              <p className={css.p}>
                <span className="p">
                  We will collect some personal data about you during the registration process (e.g.
                  your name, email address and payment details). For information regarding how we
                  process personal data, please see our{' '}
                </span>
                <NamedLink name="PrivacyPolicyPage">Privacy and Cookies Notice here.</NamedLink>
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>
                We will ask you to provide the following in order to build your Artist Profile
                (please note that this list may change over time as we update and improve our
                service offering):
              </div>
              <ul className={css.discList}>
                <li>
                  <p className={css.p}>Artist Name</p>
                </li>
                <li>
                  <p className={css.p}>
                    Artist Lead Name – this will be the individual with which we contract under
                    these Terms.
                  </p>
                </li>
                <li>
                  <p className={css.p}>Home location</p>
                </li>
                <li>
                  <p className={css.p}>Type of Artist</p>
                </li>
                <li>
                  <p className={css.p}>Number of members of Artist band / group</p>
                </li>
                <li>
                  <p className={css.p}>Standard performance start time</p>
                </li>
                <li>
                  <p className={css.p}>Standard performance duration</p>
                </li>
                <li>
                  <p className={css.p}>Standard interval duration</p>
                </li>
                <li>
                  <p className={css.p}>Estimated set-up duration</p>
                </li>
                <li>
                  <p className={css.p}>Locations where the Artist is prepared to perform</p>
                </li>
                <li>
                  <p className={css.p}>Artist biography</p>
                </li>
                <li>
                  <p className={css.p}>Artist setlist</p>
                </li>
                <li>
                  <p className={css.p}>Pricing and VAT Registration Status</p>
                </li>
                <li>
                  <p className={css.p}>Availability</p>
                </li>
                <li>
                  <p className={css.p}>Add-Ons (defined below)</p>
                </li>
                <li>
                  <p className={css.p}>Travel Expenses and Overnight Surcharge</p>
                </li>
                <li>
                  <p className={css.p}>Photographs and Videos</p>
                </li>
              </ul>
            </li>
            <li>
              <p className={css.p}>
                Your Artist Profile will be built based on the information you provide. Please check
                your Artist Profile carefully before submitting it. Whilst you are able to edit your
                Artist Profile at any time, you are responsible for ensuring that your Artist
                Profile is complete and accurate.
              </p>
            </li>
            <h3 className={css.h3}>Add-Ons</h3>
            <li>
              <p className={css.p}>
                Your Artist Profile allows you to describe the musical services that the Artist
                provides, it also enables you to inform Clients about additional optional services
                that you are able to provide (e.g. additional musicians, or instruments) and this is
                called an <b>Add-On</b>. A Client will be able to select one or more Add-On(s) when
                they view your profile and create their Booking Request.
              </p>
            </li>
            <li>
              <p className={css.p}>
                An Artist can customise the Add-On options available within the Artist Profile, such
                that descriptions and pricing can be added for each Add-On available.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You will be able to create up to four categories of Add-Ons for a Client to select
                and Music Marmalade reserves the right to limit how many Add-Ons are offered by
                Artists.
              </p>
            </li>
            <h3 className={css.h3}>Travel Expenses</h3>
            <li>
              <p className={css.p}>
                Travel expenses may be added by you to your Artist Profile and will be calculated
                based on your Home Location on your Artist Profile and the Event location. You must
                complete the relevant sections of the Artist Profile to enable us to present the
                correct travel expenses to a Client when they make enquiries via the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You must tell us about the number of vehicles that you intend to use for each Event
                so that the Website can accurately calculate the travel expenses that need to be
                added and presented to a Client when they perform a search on the Website for
                Available artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You must tell us how much you would like to charge, per vehicle, per mile.
              </p>
            </li>
            <h3 className={css.h3}>Overnight Surcharge</h3>
            <li>
              <p className={css.p}>
                You may add information to the Artist Profile relating to the fees which would be
                charged by you in the event you deem it necessary to stay overnight following an
                Event. These fees are a fee for your time to compensate you for being away from your
                Home Location overnight. You must arrange with the Client separately for them to pay
                for the accommodation costs, this will not be done via the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You must include information within the Artist Profile indicating the distance that
                you are willing to travel before an Overnight Surcharge are added to the fee.
                Additionally, you must include a price per head (per group member) for this.
              </p>
            </li>
            <h3 className={css.h3}>Availability</h3>
            <li>
              <p className={css.p}>
                It is the responsibility of all Artists to ensure that their Availability is
                appropriately marked within their Artist Profile on the Website to enable Clients to
                establish which artists are available for their Event.
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>
                Availability is marked within the Artist Profile using a traffic light system as
                follows:
              </div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>Green – Artist is available;</p>
                </li>
                <li>
                  <p className={css.p}>Red – Artist is unavailable.</p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>
                It is recommended that Artists keep their Availability updated for at least two
                years ahead, as many Clients seek to book artists for events such as weddings with a
                significant amount of notice.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Artist must ensure that any dates of non-availability (such as where the Artist
                has a performance that has been booked outside of the platform) are marked as such
                as Music Marmalade takes no responsibility for any loss or damage incurred as a
                result of inaccuracies in the Artist Profile and/or Availability.
              </p>
            </li>
            <h3 className={css.h3}>Stripe Account</h3>
            <li>
              <p className={css.p}>
                Music Marmalade uses Stripe to process payments received via the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                When building the Artist Profile, the Artist will be required to register with
                Stripe to enable payments to be collected and processed.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Stripe operates its own terms and conditions which will apply to the Stripe account
                that the Artist must maintain. This is independent of the Music Marmalade terms set
                out here.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Stripe may require Music Marmalade to provide certain information in respect of an
                Artist, the Artist agrees to co-operate with Music Marmalade in relation to such
                requests and understands that it may not be able to receive Fees in the event it
                does not do so.
              </p>
            </li>
            <h3 className={css.h3}>Reporting Requirements</h3>
            <li>
              <p className={css.p}>
                Music Marmalade may be required to report to Government and regulatory authorities
                in respect of activities conducted via the Website. The Artist agrees that Music
                Marmalade is permitted to provide such information as is requested by such
                organisations upon request.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Booking Requests <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                In order to make an enquiry with an Artist, a Client will undertake a search of the
                Website and the Client will be presented with a list of Artists that are available (
                <b>Available </b>– and “<b>Availability</b>” shall be interpreted accordingly)
                within the relevant search parameters for the event that the Client wishes to book (
                <b>Event</b>).
              </p>
            </li>
            <li>
              <p className={css.p}>
                Search results are shown to a Client based on the proximity of the Artist to the
                Event location by default, with Clients then being able to sort by highest or lowest
                price or highest rated Artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                After reviewing the Available Artists, the Client will generate a booking request (
                <b>Booking Request</b>). The Booking Request will contain all details of the Event
                and the total price of the booking (<b>Fees</b>). The pricing within the Booking
                Request will be generated directly from the information that the Artist has entered
                into its Artist Profile.
              </p>
            </li>
            <li>
              <p className={css.p}>
                This Booking Request will be sent to the Artist who will then have an opportunity to
                review the Booking Request and make a decision about whether they are happy to
                accept the Booking Request. This must be done within 48 hours of receiving the
                Booking Request. If the Artist does not respond to the Booking request within 48
                hours of receiving it, the Booking Request will be automatically declined.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the Artist chooses to accept the Booking, they will do so via the Artist Account
                we will send notification to confirm the Booking to the Client (<b>Confirmation</b>
                ).
              </p>
            </li>
            <li>
              <p className={css.p}>
                When we send the Confirmation, a contract between Client and the Artist will be
                created which will consist of the terms that apply to the relationship between the
                Client and the Artist, and the information contained within the Confirmation, which
                we have taken from the Booking Request (
                <NamedLink name="BookingContractPage">the Booking Contract</NamedLink>).
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Confirmation of a Booking <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                When a Client sends the Booking Request we will request a pre-authorisation of
                payment of the Reservation Fee from the Client to be made in order to secure the
                booking.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Once You accept a Booking Request and 48 hours have passed since the Booking Request
                was made by the Client, we will debit the pre-authorised Reservation Fee from the
                Client.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the unlikely event that Music Marmalade is unable to secure payment of the
                Reservation Fee from the Client, the Booking Contract will be immediately terminated
                and the Artist and the Artist’s Availability will be automatically updated.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            The Booking Contract <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You must comply with the terms of the{' '}
                <NamedLink name="BookingContractPage">Booking Contract</NamedLink>. Any breach of
                the Booking Contract by you will be deemed to be a breach of these Terms.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If an Artist or a Client want to vary a Booking Contract once it has been entered
                into, this must be done via the messaging function on the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Any additional payment that is required for any changes to the Booking Contract must
                be arranged directly between the Client and Artist and will not be handled via the
                Website.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Artist Responsibilities <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>
                The Artist agrees that it will adhere strictly to the following Artist Code of
                Conduct:
              </div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>
                    The Artist shall perform the Event to the best of their ability and in a manner
                    that reflects the Artist Profile that the Client viewed prior to the Event and
                    shall conduct themselves in a manner that is professional and appropriate at all
                    times.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist will comply at all times with the Booking Contract including any
                    subsequent changes that have been agreed with the Client in advance of the
                    Event.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist will arrive at the Event in a timely manner and will conduct
                    themselves in a professional manner throughout the Event, including during
                    set-up and take-down.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist will ensure it has with it all necessary equipment and instruments at
                    the Event and will dress in an appropriate manner for the Event.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist will not engage in any form of anti-social behaviour, or behaviour
                    which could be seen to bring Music Marmalade, the Client, or the Event venue
                    into disrepute.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist will not drink alcohol excessively or take drugs, before or during an
                    Event.
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    The Artist shall obtain written permission from the Client prior to taking
                    pictures or recording video at the Event and will refrain from taking pictures
                    or recording video if reasonably asked to do so by the Client or any other
                    attendee at the Event.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>
                Should the Artist breach the Artist Code of Conduct set out in this clause, Music
                Marmalade reserves the right in its absolute discretion to suspend or terminate the
                Artist Profile.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Use of Alternative Performers <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                The Artist must provide the line-up as represented to the Client, unless there is a
                necessity to substitute any members of the line-up due to unforeseen circumstances (
                <b>Substitute Members</b>).
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Substitute Members must be sufficiently rehearsed in order to perform to a high
                standard.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Fees and Payment <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Subject to clause 8.2, the Fees are set by the Artist and will be shown on the
                Website and the Booking Request. They will also be confirmed in the Confirmation.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In order to ensure that Artists are being sufficiently compensated for their work,
                Music Marmalade has expectations in respect of the recommended minimum price that
                may be offered to a Client for an Event. It is recommended that the Artist does not
                set their Fees at rates which are lower than the minimum recommended fees available
                within the Artist Account area of the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade reserves the right to suspend the Artist Profile if it is reasonably
                determined that the Artist is regularly setting its Fees at a level below the
                minimum recommended fees.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Notwithstanding the above, Music Marmalade does impose a minimum fee per Artist of
                £250 per Event and the Website will not permit listings below this price. Music
                Marmalade reserves the right to amend this minimum fee from time to time and will
                notify the Artist of this change within the Artist Profile.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Client will be responsible for paying the Fees via Music Marmalade’s Website
                using Stripe.
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>The Fees payable may be split into two payments:</div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>A Reservation Fee of 15% of the total Fees</p>
                </li>
                <li>
                  <p className={css.p}>
                    A Final Payment of any remaining Fees which have not been paid under the
                    Reservation Fee.
                  </p>
                </li>
              </ol>
            </li>
            <h3 className={css.h3}>Music Marmalade Service Fee</h3>
            <li>
              <p className={css.p}>
                Music Marmalade charges a service fee to Artists for their use of the Website (
                <b>Service Fee</b>) on the basis of the number of Booking Contracts entered into via
                the Website in each calendar year.
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>
                Subject to a minimum Service Fee of £37.50 which will apply to every Event
                regardless of the Fees charged to the Client, the Service Fee shall be calculated
                based on the Fees payable by the Client in respect of their Event as follows:
              </div>

              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>
                      Number of Events Booked via Website
                      <br />
                      (based on the number of Booking Contracts which have been entered into between
                      the Artist and a Client at the point at which the relevant Booking Request is
                      made)
                    </th>
                    <th>Service Fee as a Percentage of Fees Charged to Client</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1-25</td>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <td>26-40</td>
                    <td>12.5%</td>
                  </tr>
                  <tr>
                    <td>41-50</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>51 and over</td>
                    <td>7.5%</td>
                  </tr>
                </tbody>
              </table>
            </li>

            <li>
              <p className={css.p}>
                If an Artist is slow to respond to Booking Requests and has Booking Requests
                outstanding, any new Booking Requests that are received by that Artist prior to the
                existing Booking Requests being accepted will be subject to the Service Fee that
                applies to the number of Booking Contracts the Artist has actually entered into.
              </p>
            </li>
            <li>
              <p className={css.p}>
                On 1 January each year, the Artist’s total number of Bookings shall be reset to
                zero.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Events which the Artist has performed at will result in the Event being recorded as
                a completed Event. Any Event that the Artist cancels, or fails to perform at the
                Event, the Event will not be added to the total number of Events against which the
                Service Fee will be calculated.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In all instances, Music Marmalade will retain the Service Fee.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Fees, less the Service Fee, will be paid to the Artist, via Stripe, two days after
                the Event has taken place.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the Client does not make payments when requested, the Artist shall be entitled to
                cancel the Booking Contract.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event an Artist cancels an Event for any reason any Fees already paid by the
                Client must be refunded to the Client by the Artist including any Service Fee which
                may have been retained by Music Marmalade.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Communication Between Artists and Clients <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                All communication between Artists and Clients should be conducted via the Music
                Marmalade Website. It is important that Artists do not engage in communication
                outside of the Website as communications that take place via Messages can be taken
                to form part of the Booking Contract.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Communications between Artists and Clients must at all times adhere to the Artist
                Code of Conduct.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Artists must not in any way seek to communicate with Clients in a manner that could
                be considered by Music Marmalade to be intended to circumvent the booking system
                that is provided by Music Marmalade.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Additional Fees <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                The Client will be responsible for any reasonable additional costs or fees that the
                Artist may incur following confirmation of the Booking due to a variation to the
                Booking Contract, or other incidental expenses, and such costs or fees must be dealt
                with between the Artist and Client directly. Music Marmalade shall not be liable for
                any additional fees.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event that the Artist requires overnight accommodation in order to perform at
                the Event, the Client will be responsible for organising and paying for suitable
                accommodation. Failure to provide such overnight accommodation may result in the
                Artist being unable to attend the Event in which case the Client shall remain liable
                for the full Fee.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={11}>
          <h2 className={css.h2}>
            Insurance <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                It is the responsibility of the Artist to ensure that it has adequate public
                liability insurance in place and the Artist shall provide proof of the same to Music
                Marmalade upon request.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade disclaims any and all liability for any loss, damage or injury which
                occurs in relation to an Event.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Support from Music Marmalade <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We are available seven days a week to provide support to Clients and Artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Website has an approximate uptime of 99.9% although we cannot guarantee that it
                will be accessible at all times as we may need to conduct maintenance, or there
                could be an unexpected issue that affects the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Artists can contact us via the Website or via{' '}
                <a
                  href="mailto:artistsupport@musicmarmalade.com"
                  className={css.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  artistsupport@musicmarmalade.com{' '}
                </a>
                in order to receive support in the unlikely event that something does not go to
                plan.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            If a Client Terminates <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>
                A Client may cancel your Booking Contract at any time, subject to making the
                required payments as set out below:
              </div>

              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Time of Cancellation</th>
                    <th>Cancellation Payment Due</th>
                    <th>What An Artist Is Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>More than 182 days before the Event</td>
                    <td>15% of the Fee is due.</td>
                    <td>15% of Fee less the Service Fee</td>
                  </tr>
                  <tr>
                    <td>92 to 181 days before the Event</td>
                    <td>50% of Fee is due.</td>
                    <td>50% of Fee less the Service Fee</td>
                  </tr>
                  <tr>
                    <td>31 to 91 days before the Event</td>
                    <td>75% of Fee is due.</td>
                    <td>75% of Fee less the Service Fee</td>
                  </tr>
                  <tr>
                    <td>30 or less days before the Event</td>
                    <td>100% of Fee is due.</td>
                    <td>100% of Fee less the Service Fee</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade will seek to collect these payments from the Client in the event of
                cancellation by them, but is not obliged to do so.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event that a Client does not make payment as above, Music Marmalade may
                choose to pass on the contact details of the Client to the Artist in order for the
                Artist to seek payment directly and Music Marmalade shall have no further liability
                to the Artist in this respect.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Cancellation of an Event by Artist <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Once you have accepted a Booking Request, you must make every effort to perform the
                Event, cancelling one or more Events may lead to the Artist Profile being suspended.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If an Artist needs to cancel an Event following the acceptance of a Booking Request
                it must immediately inform Music Marmalade.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You have the right to terminate the Booking Contract that you have entered into with
                any Client in the event that the Client fails to make payment of Fees when due.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event that an Artist cancels an Event, except where such cancellation is the
                result of non- payment by the Client, it must make every effort to source a suitable
                substitute artist to cover the Event (<b>Substitute Artist</b>) and you will be
                liable for the fees charged by such substitute and any uplift in fees that the
                substitute may charge to the Client, up to a total of 110% of the original Fee.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Substitute Artist must be of equivalent standard to the Artist and you will be
                responsible for the Substitute Artist’s compliance with the terms of this Agreement.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If you are unable to source a Substitute Artist or the Client reasonably rejects the
                Substitute Artist, you must refund the Client the full Fee and Music Marmalade will
                retain the Service Fee.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event that an Artist does not provide a refund to the Client, Music Marmalade
                may take action to refund the Client and will be entitled to recover all costs and
                other losses incurred in doing so directly from the Artist.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Feedback on Website <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We welcome any feedback about the Music Marmalade Website and we are always keen to
                hear about ways to improve what we do. If you have any comments, please submit these
                to{' '}
                <a
                  href="mailto:artistsupport@musicmarmalade.com"
                  className={css.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  artistsupport@musicmarmalade.com
                </a>
                .
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Complaints Received from Clients <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Music Marmalade will pass on any communications received from Clients that relate to
                complaints about Artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                It is the responsibility of the Artist to resolve any complaint from a Client where
                such complaint relates to anything other than the Client’s use of the Website.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Termination of this Agreement <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You have the right to terminate your Agreement with Music Marmalade at any time,
                provided that you have no outstanding Booking Requests or Events which have not yet
                been performed. In the event that you wish to cancel, please provide us with at
                least 30 days’ written notice to{' '}
                <a
                  href="mailto:artistsupport@musicmarmalade.com"
                  target="_blank"
                  rel="noreferrer"
                  className={css.link}
                >
                  artistsupport@musicmarmalade.com
                </a>
                , such notice to expire no earlier than the latest Event that you have committed to
                via the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We have the right to terminate this Agreement (i) upon 30 days’ written notice
                provided that such notice will not expire until the completion of the latest Event
                that you have committed to via the Website; (ii) immediately where you are in
                material or persistent breach of this Agreement or any Booking Contract; or (iii)
                where behaviour of the Artist or any person or persons associated with the Artist is
                likely to harm the reputation of Music Marmalade.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event of termination of this Agreement all Fees paid by Clients to Music
                Marmalade for Events completed by the Artist shall be remitted to the Artist in
                accordance with the terms of this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Suspension of Artist Profile <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We may suspend your Artist Profile, resulting in you being unable to accept new
                Bookings, if we reasonably suspect that you have breached these Terms or any Booking
                Contract.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Your Obligations and Restrictions <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>You agree and accept that:</div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>
                    you will provide complete and accurate information to us as and when needed and
                    you are responsible for making sure that the data you provide us, and the Artist
                    Profile you have created, is correct; and
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    you will comply with this Agreement, the Website Terms of Use and the Artist
                    Contract, and any other documents referred to in them, when using our Website,
                    engaging with Clients and/or performing at Events;
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>
                In reading and accepting these Terms, you are aware of and understand your rights
                and responsibilities, and if you are not sure, you have contacted us on our Contact
                Page for help or more information.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Our liability to you <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Nothing in these terms excludes or limits our liability for any death or personal
                injury caused by our negligence, liability for fraud or fraudulent
                misrepresentation, or any other liability that the law does not allow us to exclude
                or limit.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Notwithstanding the above, our liability to you shall be limited to the Service Fees
                paid to us for the Event giving rise to any claim.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Indemnification <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You shall indemnify Music Marmalade against all liabilities, costs, expenses,
                damages and losses (including but not limited to any direct, indirect or
                consequential losses, loss of profit, loss of reputation and all interest, penalties
                and legal costs (calculated on a full indemnity basis) and all other reasonable
                professional costs and expenses) suffered or incurred by us arising out of or in
                connection with your breach of this Agreement, the Booking Contract and the Website
                Terms of Use.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Force Majeure <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                In respect of both this Agreement and the Booking Contract, no Party will be liable
                for their failure to fulfil their obligations if such failure is caused by a Force
                Majeure Event. A Force Majeure Event is defined as a natural disaster (including but
                not limited to; storm, earthquake, hurricane, flood, etc.), epidemic, pandemic,
                civil unrest, war, terrorism, death, illness certified by a medical profession,
                accident, or any directives issued by a government or local authority which prevents
                a party from performing their obligations to the other party.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In respect of death and/or sickness, in order for a party to claim a Force Majeure
                Event has occurred the affected party must be a named party to this Agreement or the
                Booking Contract (or their spouse where the affected party is a Client and the Event
                is a wedding).
              </p>
            </li>
            <li>
              <p className={css.p}>
                If a Party needs to rely on this clause and claim that a Force Majeure Event has
                occurred, they must inform the other party promptly. Any party seeking to claim that
                a Force Majeure Event has taken place must provide sufficient evidence to the other
                party and demonstrate that they took reasonable steps to mitigate the effects of the
                Force Majeure Event.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the party claiming that a Force Majeure Event has occurred is an Artist or
                Client, Music Marmalade will endeavour to work with that party to seek to mitigate
                or resolve the effect of the Force Majeure Event. This may involve Music Marmalade
                assisting to reschedule the Event, cancel the Event or appoint another Artist to
                perform at the Event, at the expense of the Client.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Should the Client choose to reschedule the Event with the same Artist, Music
                Marmalade will provide a list of available dates where the Artist is free.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If it is not possible to reschedule with the same Artist, Music Marmalade will help
                the client find an artist of similar quality and price.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the Event is cancelled due to a Force Majeure Event despite the Client being
                offered a viable alternative, the provisions of clause 13 will apply.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            General <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You are not allowed to transfer your rights or obligations under these terms to
                anyone without our prior written consent. We may transfer our rights and obligations
                under these terms to another business without your consent, but we will notify you
                of the transfer and make sure that your rights are not adversely affected as a
                result.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If any provision of these Terms (or part of any provision) is or becomes illegal,
                invalid or unenforceable, the legality, validity and enforceability of any other
                provision of these terms will not be affected.
              </p>
            </li>
            <li>
              <p className={css.p}>
                This Agreement does not give rise to any rights under the Contracts (Rights of Third
                Parties) Act 1999 to enforce any term of this Agreement.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If you breach these terms and we take no action, we will still be entitled to use
                our rights and remedies in any other situation where you breach these terms.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The laws of England and Wales apply to these terms. Any disputes will be subject to
                the exclusive jurisdiction of the courts of England and Wales.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default ArtistsTerms;
