import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { NamedLink, Tooltip } from '../../components';

import css from './CalendarEvent.module.css';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const CalendarEvent = props => {
  const { title, showTitle, event } = props;

  const intl = useIntl();

  const { resource } = useMemo(() => event || {}, [event]);

  const { id: txId, booking } = useMemo(() => resource || {}, [resource]);

  const { attributes } = booking || {};

  const { protectedData } = attributes || {};

  const { eventType } = protectedData || {};

  const tooltipTrigger = useMemo(
    () => (
      <div data-tooltip-id={txId} className={css.tooltipTrigger}>
        {showTitle ? title : null}
      </div>
    ),

    [showTitle, title, txId]
  );

  return (
    <div className="rbc-event-content">
      <Tooltip
        id={txId}
        trigger={tooltipTrigger}
        effect="solid"
        type="light"
        className={css.tooltip}
        clickable
        delayHide={300}
        noArrow
      >
        <NamedLink
          name="SaleDetailsPage"
          params={{ id: txId }}
          className={classNames(css.bookingsPreview, css.bookingLink)}
        >
          <span className={css.customerName}>
            {intl.formatMessage({ id: `General.${eventType}` })}
          </span>

          {/* <span className={css.eventType}>x{seats}</span> */}
        </NamedLink>
      </Tooltip>
    </div>
  );
};

CalendarEvent.propTypes = {
  showTitle: bool,
};

export default CalendarEvent;
