import React from 'react';
import { func, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldToggleCheckbox.module.css';
import ValidationError from '../ValidationError/ValidationError';

const FieldToggleCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    labelClassName,
    id,
    label,
    onChange: propsOnChange,
    offContent,
    onContent,
    asideFromLabel,
    showDynamicColor,
    disabled,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    propsOnChange?.(event);
    onBlur(event);
  };

  return (
    <div className={classes}>
      {label && (
        <span className={css.labelWrapper}>
          <label htmlFor={id} className={labelClassName}>
            {label}
          </label>
          {asideFromLabel}
        </span>
      )}
      <label className={css.switch}>
        <Field type="checkbox" {...rest}>
          {props => {
            const input = props.input;

            return (
              <>
                <input
                  id={id}
                  {...input}
                  disabled={disabled}
                  onChange={event => handleOnChange(input, event)}
                />
                <span
                  className={classNames(css.slider, {
                    [css.sliderDynamicColor]: showDynamicColor && !input.checked,
                  })}
                >
                  <span>{offContent}</span>
                  <span>{onContent}</span>
                </span>
              </>
            );
          }}
        </Field>
      </label>

      <Field type="hidden" {...rest}>
        {({ meta }) => {
          const { touched, invalid, error } = meta;

          const hasError = !!(touched && invalid && error);

          const fieldMeta = { touched: hasError, error };

          return <ValidationError fieldMeta={fieldMeta} />;
        }}
      </Field>
    </div>
  );
};

FieldToggleCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  labelClassName: string,

  onChange: func,

  asideFromLabel: node,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldToggleCheckboxComponent;
