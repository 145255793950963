import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemEarningFromReservationMaybe = props => {
  const {
    lineItems,
    isProvider,
    marketplaceName,
    intl,
    marketplaceCurrency,
    txLineItems,
    depositLineItems,
  } = props;

  const providerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  // Sharetribe Web Template is using the default-booking and default-purchase transaction processes.
  // They containt the provider commissions, so by default, the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from OrderBreakdown.js file.
  // https://www.sharetribe.com/docs/concepts/transaction-process/
  if (isProvider && providerCommissionLineItem) {
    if (!isValidCommission(providerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', providerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or negative');
    }

    const commission =
      providerCommissionLineItem?.unitPrice?.amount -
      Math.abs(providerCommissionLineItem.lineTotal.amount);
    const formattedCommission = commission
      ? formatMoney(intl, new Money(commission, marketplaceCurrency))
      : null;

    if (commission === 0) return null;

    commissionItem = (
      <>
        <hr className={css.totalDivider} />
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage
              id="OrderBreakdown.reservationEarning"
              values={{ marketplaceName, role: 'provider' }}
            />
          </span>
          <span className={css.itemValue}>{formattedCommission}</span>
        </div>
        <LineItemSubTotalMaybe
          txLineItems={txLineItems}
          depositLineItems={depositLineItems}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          label="OrderBreakdown.artistSubtotal"
        />
      </>
    );
  }

  return commissionItem;
};

LineItemEarningFromReservationMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isProvider: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
  asterisks: string,
};

export default LineItemEarningFromReservationMaybe;
