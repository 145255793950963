import { arrayOf, func, object, oneOfType, shape, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTable } from 'react-table';

import css from './Table.module.css';
import classNames from 'classnames';

const Table = props => {
  const { columns, data, className, noDataMessage, onRowClick, rowClassName } = props;

  const prepareColumn = useCallback(
    column => ({
      Header: column.columnLabel,
      accessor: column.columnName,
      ...(column.renderColumnCell && {
        Cell: ({ cell: { value, row } }) => column.renderColumnCell(value, row),
      }),
      ...(column.columns && { columns: column.columns.map(col => prepareColumn(col)) }),
    }),
    []
  );

  const cols = useMemo(() => columns.map(column => prepareColumn(column)), [
    columns,
    prepareColumn,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: cols,
    data,
  });

  const numOfColumns = headerGroups[headerGroups.length - 1]?.headers?.length || 0;

  return (
    <div className={css.tableWrapper}>
      <table {...getTableProps()} className={classNames(css.table, className)}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className={css.row}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className={css.headerCell}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!data || data.length === 0 ? (
            <tr className={css.row}>
              <td colSpan={numOfColumns} className={css.noDataCell}>
                {noDataMessage}
              </td>
            </tr>
          ) : (
            rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames(css.row, rowClassName)}
                  onClick={() => onRowClick(row)}
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className={css.bodyCell}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

const columnShape = {
  columnName: string,
  columnLabel: oneOfType([string, func]),
  renderColumnCell: func,
};

columnShape.columns = arrayOf(shape(columnShape));

Table.propTypes = {
  columns: arrayOf(shape(columnShape)),
  data: arrayOf(object),
  className: string,
  noDataMessage: string,
  onRowClick: func,
  rowClassName: string,
};

export default Table;
