/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////
import React from 'react';

import { IconArtist, IconArtistCheck, IconArtistPlus, IconMusicalNote } from '../components';
import { ADD_ONS_OPTIONS } from '../constants/listing';
import { generateHourOptions } from '../util/time';

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'leaderName',
    scope: 'public',
    schemaType: 'text',
    showConfig: {
      label: 'General.artistLeaderFirstName',
      isDetail: true,
    },
    saveConfig: {
      label: 'General.artistLeaderFirstName',
      placeholderMessage: 'General.artistNamePlaceholder',
      isRequired: true,
      requiredMessage: 'General.leaderNameRequired',
      inputType: 'text',
    },
  },
  {
    key: 'leaderLastName',
    scope: 'public',
    schemaType: 'text',
    showConfig: {
      label: 'General.artistLeaderLastName',
      isDetail: true,
    },
    saveConfig: {
      label: 'General.artistLeaderLastName',
      placeholderMessage: 'General.artistLastNamePlaceholder',
      isRequired: true,
      requiredMessage: 'General.leaderLastNameRequired',
      inputType: 'text',
    },
  },
  {
    key: 'bandType',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        option: 'weddingAndFunctionBands',
        label: 'Wedding & Function Bands',
        icon: <IconMusicalNote />,
      },
      { option: 'luxuryShowbands', label: 'Luxury Showbands', icon: <IconMusicalNote /> },
      {
        option: 'strollingAndRoamingBands',
        label: 'Strolling & Roaming Bands',
        icon: <IconMusicalNote />,
      },
      { option: 'dj', label: 'DJ', icon: <IconMusicalNote /> },
      {
        option: 'djAndLiveMusicians',
        label: 'DJ & Live Musicians',
        icon: <IconMusicalNote />,
      },
      {
        option: 'ceilidhAndTraditionalActs',
        label: 'Ceilidh & Traditional Acts',
        icon: <IconMusicalNote />,
      },
      { option: 'tributeActs', label: 'Tribute Acts', icon: <IconMusicalNote /> },
      {
        option: 'jazzAndSwingBands',
        label: 'Jazz & Swing Bands',
        icon: <IconMusicalNote />,
      },
      {
        option: 'stringQuartetsAndClassical',
        label: 'String Quartets & Classical',
        icon: <IconMusicalNote />,
      },
      { option: 'soloDuoAndTrios', label: 'Solo, Duo & Trios', icon: <IconMusicalNote /> },
    ],

    showConfig: {
      label: 'Details.bandTypeLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.bandTypeLabel',
      placeholderMessage: 'Details.bandTypePlaceholder',
      isRequired: true,
      requiredMessage: 'Details.bandTypeRequired',
    },
    filterConfig: {
      indexForSearch: true,
      label: 'General.artistTypeFilterLabel',
      group: 'primary',
      searchMode: 'has_any',
      icon: <IconArtist />,
    },
  },
  {
    key: 'bandCategory',
    scope: 'public',
    schemaType: 'multi-enum',

    enumOptions: [
      { label: 'Acoustic', option: 'acoustic', group: 'Genre' },
      { label: 'Classical', option: 'classical', group: 'Genre' },
      { label: 'Country', option: 'country', group: 'Genre' },
      { label: 'Disco', option: 'disco', group: 'Genre' },
      { label: 'Folk', option: 'folk', group: 'Genre' },
      { label: 'Funk', option: 'funk', group: 'Genre' },
      { label: 'Hip Hop', option: 'hipHop', group: 'Genre' },
      { label: 'Indie', option: 'indie', group: 'Genre' },
      { label: 'Jazz', option: 'jazz', group: 'Genre' },
      { label: 'Latin & World', option: 'latinWorld', group: 'Genre' },
      { label: 'Motown', option: 'motown', group: 'Genre' },
      { label: 'Pop', option: 'pop', group: 'Genre' },
      { label: 'Rock', option: 'rock', group: 'Genre' },
      { label: 'R&B', option: 'rAndB', group: 'Genre' },
      { label: 'Soul', option: 'soul', group: 'Genre' },
      { label: 'Traditional', option: 'traditional', group: 'Genre' },
      { label: 'Ceremony Musicians', option: 'ceremonyMusicians', group: 'Time of day' },
      { label: 'Drinks Reception Music', option: 'drinksReceptionMusic', group: 'Time of day' },
      { label: 'Late Night Entertainment', option: 'lateNightEntertainment', group: 'Time of day' },
      { label: 'Evening Entertainment', option: 'eveningEntertainment', group: 'Time of day' },
      { label: 'Entire Day of Music', option: 'entireDayOfMusic', group: 'Time of day' },
      { label: 'Female Vocals only', option: 'femaleVocalsOnly', group: 'Singers' },
      { label: 'Male Vocals only', option: 'maleVocalsOnly', group: 'Singers' },
      { label: 'Male & Female Vocals', option: 'maleAndFemaleVocals', group: 'Singers' },
      { label: 'Classical & Opera Singers', option: 'classicalAndOperaSingers', group: 'Singers' },
      { label: 'Choir', option: 'choir', group: 'Singers' },
      { label: 'Singing Waiters', option: 'singingWaiters', group: 'Types' },
      { label: 'Band', option: 'band', group: 'Types' },
      { label: 'Big Band', option: 'bigBand', group: 'Types' },
      { label: 'Live Band', option: 'liveBand', group: 'Types' },
      { label: 'Party Bands', option: 'partyBands', group: 'Types' },
      { label: 'Brass section', option: 'brassSection', group: 'Types' },
      { label: 'Ceilidh', option: 'ceilidh', group: 'Types' },
      { label: 'String Section', option: 'stringSection', group: 'Types' },
      { label: 'String Quartet', option: 'stringQuartet', group: 'Types' },
      { label: 'DJ', option: 'dj', group: 'Types' },
      { label: 'DJ & Sax', option: 'djAndSax', group: 'Types' },
      { label: 'DJ & Live Musicians', option: 'djAndLiveMusicians', group: 'Types' },
      { label: 'Harpists', option: 'harpists', group: 'Types' },
      { label: 'Pipers & Bagpipers', option: 'pipersAndBagpipers', group: 'Types' },
      { label: 'Solo Piano', option: 'soloPiano', group: 'Types' },
      { label: 'Solo Guitarist', option: 'soloGuitarist', group: 'Types' },
      { label: 'Solo Singer', option: 'soloSinger', group: 'Types' },
      { label: 'Solo Musician', option: 'soloMusician', group: 'Types' },
      { label: 'Solo Saxophone', option: 'soloSaxophone', group: 'Types' },
      { label: 'Duos', option: 'duos', group: 'Types' },
      { label: 'Trios', option: 'trios', group: 'Types' },
      { label: 'Classical Musicians', option: 'classicalMusicians', group: 'Types' },
      { label: 'Multi-Instrumentalist', option: 'multiInstrumentalist', group: 'Types' },
      { label: 'Chill-out', option: 'chillOut', group: 'Vibe' },
      { label: 'High Energy', option: 'highEnergy', group: 'Vibe' },
      { label: 'Luxury', option: 'luxury', group: 'Vibe' },
    ],

    showConfig: {
      label: 'Details.bandCategoryLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.bandCategoryLabel',
      placeholderMessage: 'Details.bandCategoryPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.bandCategoryRequired',
    },
    filterConfig: {
      indexForSearch: true,
      label: 'General.artistCategoryFilterLabel',
      group: 'primary',
      searchMode: 'has_any',
      icon: <IconArtist />,
    },
  },
  {
    key: 'numOfBandMembers',
    scope: 'public',
    schemaType: 'long',
    showConfig: {
      label: 'Details.numOfBandMembersLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.numOfBandMembersLabel',
      placeholderMessage: 'Details.numOfBandMembersPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.numOfBandMembersRequired',
      inputType: 'number',
    },
  },
  {
    key: 'standardPerformanceStartTime',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: generateHourOptions().map(option => ({ option: option, label: option })),
    showConfig: {
      label: 'Details.standardPerformanceStartTimeLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.standardPerformanceStartTimeLabel',
      placeholderMessage: 'Details.standardPerformanceStartTimePlaceholer',
      isRequired: true,
      requiredMessage: 'Details.standardPerformanceStartTimeRequired',
    },
    filterConfig: {
      indexForSearch: true,
      label: 'General.standardPerformanceStartTimeFilterLabel',
      group: 'primary',
    },
  },

  {
    key: 'standardPerformanceDuration',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '15min', label: '15 Min' },
      { option: '30min', label: '30 Min' },
      { option: '45min', label: '45 Min' },
      { option: '60min', label: '1H' },
      { option: '75min', label: '1H 15Min' },
      { option: '90min', label: '1H 30Min' },
      { option: '105min', label: '1H 45Min' },
      { option: '120min', label: '2H' },
      { option: '135min', label: '2H 15Min' },
      { option: '150min', label: '2H 30Min' },
      { option: '165min', label: '2H 45Min' },
      { option: '180min', label: '3H' },
      { option: '195min', label: '3H 15Min' },
      { option: '210min', label: '3H 30Min' },
      { option: '225min', label: '3H 45Min' },
      { option: '240min', label: '4H' },
      { option: '255min', label: '4H 15Min' },
      { option: '270min', label: '4H 30Min' },
      { option: '285min', label: '4H 45Min' },
      { option: '300min', label: '5H' },
      { option: '315min', label: '5H 15Min' },
      { option: '330min', label: '5H 30Min' },
      { option: '345min', label: '5H 45Min' },
      { option: '360min', label: '6H' },
    ],
    showConfig: {
      label: 'Details.standardPerformanceDurationLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.standardPerformanceDurationLabel',
      placeholderMessage: 'Details.standardPerformanceDurationPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.standardPerformanceDurationRequired',
      inputType: 'number',
    },
  },
  {
    key: 'standardBreakDuration',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '0', label: 'No break required' },
      { option: '15min', label: '15 Min' },
      { option: '30min', label: '30 Min' },
      { option: '45min', label: '45 Min' },
      { option: '60min', label: '1H' },
      { option: '75min', label: '1H 15Min' },
      { option: '90min', label: '1H 30Min' },
      { option: '105min', label: '1H 45Min' },
      { option: '120min', label: '2H' },
      { option: '135min', label: '2H 15Min' },
      { option: '150min', label: '2H 30Min' },
      { option: '165min', label: '2H 45Min' },
      { option: '180min', label: '3H' },
    ],
    showConfig: {
      label: 'Details.standardBreakDurationLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.standardBreakDurationLabel',
      placeholderMessage: 'Details.standardBreakDurationPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.standardBreakDurationRequired',
      inputType: 'number',
    },
  },
  {
    key: 'estimatedSetUpDuration',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '15min', label: '15 Min' },
      { option: '30min', label: '30 Min' },
      { option: '45min', label: '45 Min' },
      { option: '60min', label: '1H' },
      { option: '75min', label: '1H 15Min' },
      { option: '90min', label: '1H 30Min' },
      { option: '105min', label: '1H 45Min' },
      { option: '120min', label: '2H' },
      { option: '135min', label: '2H 15Min' },
      { option: '150min', label: '2H 30Min' },
      { option: '165min', label: '2H 45Min' },
      { option: '180min', label: '3H' },
    ],
    showConfig: {
      label: 'Details.estimatedSetUpDurationLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.estimatedSetUpDurationLabel',
      placeholderMessage: 'Details.estimatedSetUpDurationPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.estimatedSetUpDurationRequired',
      inputType: 'number',
    },
  },
  {
    key: 'serviceableLocations',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      // IMPORTANT: Keep up to date with SectionExploreRegion.js -> regionLabelToImage
      { label: 'All UK Regions', option: 'all' },
      { label: 'Aberdeen and North East', option: 'AB' },
      { label: 'East Midlands', option: 'DE,LE,LN,NG,NN,S,SK' },
      { label: 'East of England', option: 'AL,CB,CM,CO,IG,IP,LU,NR,PE,RM,SG,SS,WD' },
      { label: 'Edinburgh and Lothians', option: 'EH' },
      { label: 'Glasgow and Strathclyde', option: 'G,KA,ML,PA' },
      { label: 'Highland and Islands', option: 'HS,IV,KW,ZE' },
      { label: 'London & Greater London', option: 'E,EC,EN,HA,N,NW,SE,SW,TW,UB,W,WC' },
      { label: 'North East', option: 'DH,DL,NE,SR,TD,TS' },
      { label: 'North West', option: 'BB,BL,CA,CH,CW,FY,L,LA,M,OL,PR,SK,WA,WN' },
      { label: 'Scotland South', option: 'DG,TD' },
      { label: 'South East', option: 'BN,BR,CR,CT,DA,HP,KT,ME,MK,OX,PO,RG,RH,SL,SM,SN,SO,SP,TN' },
      { label: 'South West', option: 'BA,BH,BS,DT,EX,GL,PL,SN,SP,TA,TQ,TR' },
      { label: 'Tayside, Central and Fife', option: 'DD,FK,KY,PH' },
      { label: 'Yorkshire and the Humber', option: 'BD,DN,HD,HG,HU,HX,LS,S,TS,WF,YO' },
      { label: 'West Midlands', option: 'B,CV,DY,HR,ST,SY,TF,WR,WS,WV' },
      { label: 'Wales', option: 'CF,LD,LL,NP,SA,SY' },
    ],

    showConfig: {
      label: 'Details.serviceableLocationsLabel',
      isDetail: true,
    },
    saveConfig: {
      label: 'Details.serviceableLocationsLabel',
      placeholderMessage: 'Details.serviceableLocationsPlaceholder',
      isRequired: true,
      requiredMessage: 'Details.serviceableLocationsRequired',
      inputType: 'number',
    },
  },

  {
    key: 'setList',
    scope: 'public',
    schemaType: 'text',
    showConfig: {
      label: 'Gallery.setList',
      isDetail: true,
    },
    saveConfig: {
      label: 'Gallery.setList',
      placeholderMessage: 'Gallery.setListPlaceholder',
      isRequired: false,
      inputType: 'text',
    },
  },
  {
    key: 'hasAddons',
    scope: 'public',
    schemaType: 'boolean',
    filterConfig: {
      indexForSearch: true,
      label: 'General.hasAddonsFilterLabel',
      group: 'primary',
      icon: <IconArtistPlus />,
    },
  },
  {
    key: 'addonsCategories',
    scope: 'public',
    schemaType: 'multi-enum',
    filterConfig: {
      indexForSearch: true,
      label: 'General.addonsCategoriesFilterLabel',
      group: 'primary',
      searchMode: 'has_all',
      icon: <IconArtistCheck />,
    },
    enumOptions: ADD_ONS_OPTIONS.map(option => ({ option: option.value, label: option.label })),
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
