import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import { DATE_TYPE_DATE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { string } from 'prop-types';

const BookingPeriod = props => {
  const { startDate, dateType, timeZone } = props;
  const timeZoneMaybe = timeZone ? { timeZone } : null;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSectionLeft}>
          <div className={css.dayLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div>

          <div className={css.itemValue}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
            , &nbsp;
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { dateOfEvent, dateType, timeZone } = props;

  if (!dateOfEvent) {
    return null;
  }
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  // const { start, displayStart } = booking.attributes;
  const localStartDate = dateOfEvent;

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod startDate={localStartDate} dateType={dateType} timeZone={timeZone} />
      </div>
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateOfEvent: null, dateType: null };

LineItemBookingPeriod.propTypes = {
  dateOfEvent: string,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
