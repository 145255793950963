import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import css from './Dropdown.module.css';
import classNames from 'classnames';
import IconCheckmark from '../IconCheckmark/IconCheckmark';
import IconArrowHead from '../IconArrowHead/IconArrowHead';
import IconClose from '../IconClose/IconClose';

const getGroupedOptions = options => {
  const groupedOptions = options?.reduce((all, opt) => {
    const groupName = opt.group || 'default';

    all[groupName] = [...(all[groupName] || []), opt];

    return all;
  }, {});

  return Object.entries(groupedOptions);
};

const makeSubstringBold = (text, shouldBeBold) => {
  if (!shouldBeBold) return text;
  const textArray = text.split(RegExp(shouldBeBold, 'ig'));
  const match = text.match(RegExp(shouldBeBold, 'ig'));

  return (
    <span>
      {textArray.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
        </React.Fragment>
      ))}
    </span>
  );
};

const Dropdown = props => {
  const {
    options,
    className,
    dropdownInputClassName,
    inputIcon,
    iconClassName,
    placeholder,
    onChange,
    value,
    onFocus,
    onBlur,
    name,
    id,
    disableRest,
    searchable = true,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);

  const dropdownRef = useRef(null);
  const optionsRefs = useRef([]);
  const listRef = useRef(null);

  const remainingOptions = useMemo(
    () =>
      options.filter(option =>
        option.searchString.toLowerCase().includes(searchString.toLowerCase())
      ),
    [options, searchString]
  );

  const remainingOptionsByGroup = useMemo(
    () => getGroupedOptions(remainingOptions.map((option, index) => ({ ...option, index }))),
    [remainingOptions]
  );

  const dropdownClasses = classNames(className, css.dropdown, { [css.dropdownOpen]: dropdownOpen });

  const chooseOption = useCallback(
    newValue => {
      typeof newValue === 'string'
        ? onChange([...value, newValue])
        : onChange([...new Set([...value, ...newValue])]);
    },
    [onChange, value]
  );

  const removeOption = useCallback(
    valueToRemove => {
      typeof newValue === 'string'
        ? onChange(value.filter(val => val !== valueToRemove))
        : onChange(value.filter(el => !valueToRemove.includes(el)));
    },
    [onChange, value]
  );

  const optionChecked = useCallback(
    option =>
      typeof option.value === 'string'
        ? !!value.find(val => val === option.value)
        : option.value.every(v => value.includes(v)),
    [value]
  );

  const optionDisabled = useCallback(option => disableRest && !optionChecked(option), [
    disableRest,
    optionChecked,
  ]);

  const handleOptionClicked = useCallback(
    option => {
      if (optionDisabled(option)) return;

      !optionChecked(option) ? chooseOption(option.value) : removeOption(option.value);
    },
    [chooseOption, optionChecked, optionDisabled, removeOption]
  );

  const handleSearch = searhValue => {
    setSearchString(searhValue);
  };

  const getOptionByValue = value => options.find(option => option.value === value);

  const scrollIntoViewIfNeeded = target => {
    if (!listRef.current) return;

    if (target.getBoundingClientRect().bottom > listRef.current?.getBoundingClientRect().bottom) {
      target.scrollIntoView(false);
    }

    if (target.getBoundingClientRect().top < listRef.current?.getBoundingClientRect().top) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  useEffect(() => {
    const closeDropdownOnClickOutside = e => {
      if (dropdownOpen && !dropdownRef.current?.contains(e.target)) {
        setDropdownOpen(false);
        onBlur();
      }
    };

    const keyDownCallback = e => {
      if (!dropdownOpen) return;

      let newIndex;
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          newIndex = activeIndex > 0 ? activeIndex - 1 : activeIndex;
          scrollIntoViewIfNeeded(optionsRefs.current[newIndex]);
          setActiveIndex(newIndex);
          return;
        case 'ArrowDown':
          e.preventDefault();
          newIndex = activeIndex < remainingOptions.length - 1 ? activeIndex + 1 : activeIndex;
          scrollIntoViewIfNeeded(optionsRefs.current[newIndex]);
          setActiveIndex(newIndex);
          return;
        case 'Enter':
          e.preventDefault();
          if (activeIndex < 0) return;
          handleOptionClicked(remainingOptions.find((option, index) => index === activeIndex));
          return;
        default:
          return;
      }
    };

    document.addEventListener('mousedown', closeDropdownOnClickOutside);
    document.addEventListener('keydown', keyDownCallback);
    return () => {
      document.removeEventListener('mousedown', closeDropdownOnClickOutside);
      document.removeEventListener('keydown', keyDownCallback);
    };
  }, [activeIndex, dropdownOpen, handleOptionClicked, onBlur, remainingOptions]);

  return (
    <div className={dropdownClasses} ref={dropdownRef}>
      <div className={css.dropdownTrigger}>
        {inputIcon && <span className={iconClassName}>{inputIcon}</span>}
        <input
          id={id}
          name={name}
          className={classNames(dropdownInputClassName, css.input)}
          value={searchString}
          onChange={e => searchable && handleSearch(e.target.value)}
          onFocus={e => {
            setDropdownOpen(true);
            onFocus(e);
          }}
          placeholder={placeholder}
          readOnly={!searchable}
        />
        <button
          type="button"
          className={css.openBtn}
          onClick={() => {
            if (dropdownOpen) onBlur();
            setDropdownOpen(prev => !prev);
          }}
        >
          <IconArrowHead direction="down" />
        </button>
      </div>
      {dropdownOpen && (
        <ul className={css.optionsList} ref={listRef}>
          {remainingOptionsByGroup.map(group => {
            const [groupName, options] = group;

            return (
              <React.Fragment key={groupName}>
                {groupName !== 'default' && (
                  <li className={css.group}>
                    <b>{groupName}</b>
                  </li>
                )}
                {options.map(option => {
                  return (
                    <li
                      key={option.value}
                      ref={element => (optionsRefs.current[(option?.index)] = element)}
                      onClick={() => {
                        handleOptionClicked(option);
                        onBlur();
                      }}
                      className={css.option}
                      role="option"
                      aria-selected={option?.index === activeIndex}
                      aria-disabled={optionDisabled(option)}
                      onMouseMove={() => {
                        setActiveIndex(option?.index);
                      }}
                    >
                      {option.icon && <span className={css.optionIcon}>{option.icon}</span>}
                      {makeSubstringBold(option.label, searchString)}

                      {optionChecked(option) && (
                        <IconCheckmark size="small" className={css.checkIcon} />
                      )}
                    </li>
                  );
                })}
              </React.Fragment>
            );
          })}
        </ul>
      )}

      {value.length !== 0 && (
        <div className={css.chipsContainer}>
          {value?.map(val => (
            <div
              className={classNames(css.chip, {
                [css.noIconChip]: !getOptionByValue(val)?.icon,
              })}
              key={val}
            >
              {getOptionByValue(val)?.icon && (
                <span className={css.chipIcon}>{getOptionByValue(val).icon}</span>
              )}

              <span className={css.chipContent}>{getOptionByValue(val)?.label}</span>
              <button
                type="button"
                className={css.closeBtn}
                onClick={() => {
                  removeOption(val);
                  onBlur();
                }}
              >
                <IconClose className={css.closeIcon} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  options: [],
  value: [],
};

Dropdown.propTypes = {
  id: string,
  className: string,
  dropdownInputClassName: string,
  inputIcon: node,
  placeholder: string,
  onChange: func,
  onBlur: func,
  onFocus: func,
  disableRest: bool,
  options: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired,
      searchString: string,
      value: oneOfType([string, arrayOf(string)]).isRequired,
      icon: node,
      group: string,
    })
  ),
  value: arrayOf(string),
};

export default Dropdown;
