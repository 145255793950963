import React from 'react';

const IconArtistCheck = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9647 9.23294C13.1079 8.24342 13.7647 6.80606 13.7647 5.29412C13.7647 2.37026 11.3944 0 8.47059 0C5.54673 0 3.17647 2.37026 3.17647 5.29412C3.17645 6.80606 3.83331 8.24342 4.97647 9.23294C1.94923 10.6037 0.00318293 13.618 0 16.9412C0 17.5259 0.474051 18 1.05882 18C1.6436 18 2.11765 17.5259 2.11765 16.9412C2.11765 13.4325 4.96196 10.5882 8.47059 10.5882C11.9792 10.5882 14.8235 13.4325 14.8235 16.9412C14.8235 17.5259 15.2976 18 15.8824 18C16.4671 18 16.9412 17.5259 16.9412 16.9412C16.938 13.618 14.9919 10.6037 11.9647 9.23294ZM8.4689 8.47055C6.71458 8.47055 5.29243 7.04839 5.29243 5.29408C5.29243 3.53976 6.71458 2.11761 8.4689 2.11761C10.2232 2.11761 11.6454 3.53976 11.6454 5.29408C11.6454 6.13653 11.3107 6.94448 10.715 7.54018C10.1193 8.13589 9.31135 8.47055 8.4689 8.47055ZM20.8703 5.96128C20.6714 5.76083 20.4008 5.64808 20.1185 5.64808C19.8362 5.64808 19.5655 5.76083 19.3667 5.96128L17.2491 8.07892L16.5926 7.41187C16.3938 7.21142 16.1232 7.09867 15.8408 7.09867C15.5585 7.09867 15.2879 7.21142 15.0891 7.41187C14.6785 7.82485 14.6785 8.49183 15.0891 8.90481L16.5079 10.3236C16.9209 10.7342 17.5879 10.7342 18.0008 10.3236L20.8279 7.49657C21.0439 7.30254 21.1708 7.02829 21.1788 6.73802C21.1868 6.44775 21.0753 6.16693 20.8703 5.96128Z"
        fill="#EE2677"
      />
    </svg>
  );
};

export default IconArtistCheck;
