import transactions from '../../api/transactions';
import { CONFIRMED } from '../../constants/booking';
import { USER_ROLE } from '../../constants/user';
import { storableError } from '../../util/errors';

export const BOOKINGS_PER_PAGE = 7;

// ================ Action types ================ //

export const FETCH_BOOKINGS_REQUEST = 'app/BookingsPage/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/BookingsPage/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/BookingsPage/FETCH_BOOKINGS_ERROR';
export const NO_BOOKINGS = 'app/BookingsPage/NO_BOOKINGS';

// ================ Reducer ================ //

const initialState = {
  bookings: [],
  queryParams: null,
  pagination: null,
  queryInProgress: false,
  queryBookingsError: null,
};

const mapTransactionsToBookingsData = (transactions, userRole) => {
  return transactions?.map(transaction => {
    const { protectedData, metadata } = transaction?.attributes || {};
    const { name, postalCode } = protectedData?.location?.selectedPlace || {};

    return {
      id: transaction._id,
      ...(userRole === USER_ROLE.USER && {
        artist: transaction?.artistName,
      }),
      dateOfEvent: metadata?.dateOfEvent,
      dateOfRequest: transaction?.attributes?.createdAt,
      location: name && postalCode ? `${name}, ${postalCode}` : '',
      ...(userRole === USER_ROLE.ARTIST && {
        eventType: transaction?.attributes?.protectedData?.eventType,
      }),
      addOnsSelected: protectedData?.addOns?.length,
      price:
        userRole === USER_ROLE.ARTIST
          ? protectedData?.fullPayoutAmount
          : protectedData?.fullPayinAmount,
      ...(userRole === USER_ROLE.USER && { status: transaction?.status }),
    };
  });
};

const bookingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BOOKINGS_REQUEST:
      return {
        ...state,
        queryParams: payload?.queryParams,
        queryInProgress: true,
        queryBookingsError: null,
        bookings: [],
      };
    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: mapTransactionsToBookingsData(payload?.items, payload?.userRole),
        pagination: {
          page: payload?.currentPage,
          totalItems: payload?.totalItems,
          totalPages: payload?.totalPages,
          perPage: BOOKINGS_PER_PAGE,
        },
        queryInProgress: false,
      };
    case FETCH_BOOKINGS_ERROR:
      console.error(payload);
      return { ...state, queryInProgress: false, queryBookingsError: payload };
    case NO_BOOKINGS:
      return { ...state, queryInProgress: false, bookings: [], pagination: { totalItems: 0 } };
    default:
      return state;
  }
};

// ================ Action creators ================ //

export const queryBookingsRequest = queryParams => ({
  type: FETCH_BOOKINGS_REQUEST,
  payload: { queryParams },
});

export const queryBookingsSuccess = response => ({
  type: FETCH_BOOKINGS_SUCCESS,
  payload: response,
});

export const queryBookingsError = e => ({
  type: FETCH_BOOKINGS_ERROR,
  error: true,
  payload: e,
});

export const noBookings = () => ({
  type: NO_BOOKINGS,
});

export const queryBookings = params => dispatch => {
  const { state, listingId, userRole, ...queryParams } = params || {};
  const page = queryParams.page || 1;
  const year = queryParams.year || new Date().getFullYear();
  const month = queryParams.hasOwnProperty('month') ? queryParams.month : new Date().getMonth();

  if (userRole === USER_ROLE.ARTIST && listingId) {
    dispatch(queryBookingsRequest(params));

    transactions
      .getArtistTransactions({
        listingId,
        status: state,
        page,
        ...(state === CONFIRMED.value && { year }),
        ...(state === CONFIRMED.value && { month }),
      })
      .then(response => dispatch(queryBookingsSuccess({ ...response?.data, userRole })))
      .catch(e => {
        dispatch(queryBookingsError(storableError(e)));
        throw e;
      });
  } else if (userRole === USER_ROLE.ARTIST) {
    //artist has no published listing, so no bookings
    dispatch(noBookings());
  } else {
    //user bookings
    dispatch(queryBookingsRequest(params));
    transactions
      .getUserTransactions({ page: params.page })
      .then(response => dispatch(queryBookingsSuccess({ ...response?.data, userRole })))
      .catch(e => dispatch(queryBookingsError(storableError(e))));
  }
};

export default bookingPageReducer;
