export const PENDING = { value: 'pending', label: 'General.pendingBookingLabel' };
export const CONFIRMED = { value: 'confirmed', label: 'General.confirmedBookingLabel' };
export const PAST = { value: 'past', label: 'General.pastBookingLabel' };

export const BOOKING_STATES = [PENDING, CONFIRMED, PAST];

export const BOOKING_LOCATION_TYPES = [
  'postal_code',
  'bar',
  'restaurant',
  'lodging',
  'point_of_interest',
];

export const BOOKING_POSSIBLE_TIME_RANGE = {
  startPeriod: { amount: 4, unit: 'day' },
  endPeriod: { amount: 3, unit: 'year' },
};
