import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { InlineTextButton, PrimaryButton, SecondaryButton } from '../Button/Button';

import css from './CookieConsent.module.css';
import NamedLink from '../NamedLink/NamedLink';
import { useConfiguration } from '../../context/configurationContext';
import { GoogleAnalyticsHandler } from '../../analytics/handlers';
import CookieConsentForm from './CookieConsentForm';
import { GTAG_COOKIE_GROUPS } from '../../constants/analytics';

const CookieConsent = props => {
  const { rootClassName, className } = props;

  const config = useConfiguration();
  const [showCookies, setShowCookies] = useState(false);
  const [isDetailedView, setIsDetailedView] = useState(false);
  const [initialCookieConsent, setInitialCookieConsent] = useState({});

  const formApiRef = useRef();

  const isServer = typeof window === 'undefined';

  const initialValues = useMemo(() => ({ ...initialCookieConsent, necessary: ['granted'] }), [
    initialCookieConsent,
  ]);

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      setShowCookies(true);
    }

    if (cookies.cookieConsent) {
      const parsedConsent = Object.entries(JSON.parse(cookies.cookieConsent)).reduce(
        (formValues, value) => {
          const [cookieName, cookieValue] = value;

          formValues[cookieName] = cookieValue ? [cookieValue] : [];

          return formValues;
        },
        {}
      );

      setInitialCookieConsent(parsedConsent);
    }
  }, []);

  const saveCookieConsent = useCallback(cookies => {
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    // Save the cookie with expiration date
    document.cookie = 'euCookiesAccepted=1; path=/; expires=' + expirationDate.toGMTString();

    if (cookies) {
      document.cookie = `cookieConsent=${cookies}; path=/; expires=` + expirationDate.toGMTString();
    }
  }, []);

  const closeConsent = useCallback(
    cookies => {
      setShowCookies(false);
      saveCookieConsent(cookies);
    },
    [saveCookieConsent]
  );

  const onAcceptNecessary = useCallback(() => {
    const cookies = Object.values(GTAG_COOKIE_GROUPS).reduce((cookieData, name) => {
      cookieData[name] = 'denied';

      return cookieData;
    }, {});

    GoogleAnalyticsHandler.acceptSelectedCookies(cookies);

    closeConsent(JSON.stringify(cookies));
  }, [closeConsent]);

  const handleAcceptSelection = useCallback(
    values => {
      const { necessary, ...rest } = values;

      const cookies = Object.values(GTAG_COOKIE_GROUPS).reduce((cookieData, name) => {
        const status = rest[name]?.[0] === 'granted' ? 'granted' : 'denied';

        cookieData[name] = status;

        return cookieData;
      }, {});

      GoogleAnalyticsHandler.acceptSelectedCookies(cookies);

      closeConsent(JSON.stringify(cookies));
    },
    [closeConsent]
  );

  const onAcceptAll = useCallback(() => {
    const cookies = Object.values(GTAG_COOKIE_GROUPS).reduce((cookieData, name) => {
      cookieData[name] = 'granted';

      return cookieData;
    }, {});

    GoogleAnalyticsHandler.acceptAllCookies();

    closeConsent(JSON.stringify(cookies));
  }, [closeConsent]);

  // Server side doesn't know about cookie consent
  if (isServer || !showCookies) {
    return null;
  }

  return (
    <div
      className={classNames(
        rootClassName || css.root,
        { [css.rootExpanded]: isDetailedView },
        className
      )}
    >
      <div className={classNames(css.message, { [css.messageExpanded]: isDetailedView })}>
        {isDetailedView ? (
          <CookieConsentForm
            onSubmit={handleAcceptSelection}
            formApiRef={formApiRef}
            initialValues={initialValues}
          />
        ) : null}
        <FormattedMessage
          id="CookieConsent.message"
          values={{
            marketplaceName: config.marketplaceName,
            policyLink: (
              <NamedLink name="PrivacyPolicyPage">
                <FormattedMessage id="General.cookiePolicy" />
              </NamedLink>
            ),
          }}
        />
      </div>

      <div className={css.ctas}>
        <SecondaryButton
          type="button"
          onClick={() => onAcceptNecessary()}
          className={css.secondary}
        >
          <FormattedMessage id="General.acceptNecessary" />
        </SecondaryButton>
        {isDetailedView && (
          <SecondaryButton
            type="button"
            onClick={() => formApiRef.current?.submit()}
            className={css.secondary}
          >
            <FormattedMessage id="General.acceptSelection" />
          </SecondaryButton>
        )}
        <PrimaryButton type="button" onClick={onAcceptAll} className={css.primary}>
          <FormattedMessage id="General.acceptAll" />
        </PrimaryButton>

        {!isDetailedView && (
          <InlineTextButton type="button" onClick={() => setIsDetailedView(true)}>
            <FormattedMessage id="General.viewCookies" />
          </InlineTextButton>
        )}
      </div>
    </div>
  );
};

export default CookieConsent;
