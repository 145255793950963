import React, { useCallback, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import classNames from 'classnames';
import { arrayOf, bool, func, instanceOf, object, shape, string } from 'prop-types';
import { getMomentFromDate, stringifyDateToISO8601 } from '../../util/dates';
import { intlShape, injectIntl } from '../../util/reactIntl';
import CalendarToolbar from './CalendarToolbar';
import CalendarDateCellWrapper from './CalendarDateCellWrapper';
import CalendarEvent from './CalendarEvent';
import { IconSpinner } from '../../components';

import css from './BigCalendar.module.css';

const CALENDAR_STYLE = { minHeight: 500 };

const VIEWS = [Views.MONTH];

const TODAY = new Date();

const FORMATS = {
  weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
};

const getEndAccessor = event => {
  const endMoment = getMomentFromDate(event.end);

  const endTime = endMoment.format('HH:mm');

  const isMidnight = endTime === '00:00';

  if (isMidnight) {
    endMoment.add(1, 'day');
  }

  return endMoment.toDate();
};

const EmptyComponent = () => null;

const BigCalendar = props => {
  const {
    className,
    intl,
    events,
    onRangeChange,
    onSelectEvent,
    selectedEvent,
    fetchEventsInProgress,
    fetchEventsError,
    availableDates,
    notAvailableDates,
    onClickCell,
  } = props;

  const localizer = useMemo(() => momentLocalizer(moment), []);

  const datesWithEvents = useMemo(
    () =>
      events.reduce((dates, event) => {
        const { start, end } = event;

        let date = getMomentFromDate(start);

        while (date.isSameOrBefore(end, 'day')) {
          const dateString = stringifyDateToISO8601(date);

          dates[dateString] = true;

          date.add(1, 'day');
        }

        return dates;
      }, {}),
    [events]
  );

  const components = useMemo(
    () => ({
      toolbar: CalendarToolbar,
      event: CalendarEvent,
      month: {
        header: EmptyComponent,
        dateHeader: props => (
          <CalendarDateCellWrapper
            {...props}
            datesWithEvents={datesWithEvents}
            availableDates={availableDates}
            notAvailableDates={notAvailableDates}
            onClickCell={onClickCell}
          />
        ),
      },
      week: { header: EmptyComponent },
      day: {
        header: EmptyComponent,
        event: props => <CalendarEvent {...props} showTitle />,
      },
    }),
    [availableDates, datesWithEvents, notAvailableDates, onClickCell]
  );

  const handleDayPropGetter = useCallback(
    date => {
      const dateString = stringifyDateToISO8601(date);

      const isDateBeforeToday = getMomentFromDate(TODAY).isAfter(date, 'day');

      const styles = {};

      if (isDateBeforeToday) {
        styles.className = css.calendarOutOfBoundsDays;
      } else if (notAvailableDates[dateString]) {
        styles.className = css.bookingNotAvailable;
      } else if (availableDates[dateString]) {
        styles.className = css.bookingAvailable;
      } else {
        styles.className = css.calendarNoSessionDays;
      }

      return styles;
    },
    [availableDates, notAvailableDates]
  );

  const handleEventPropGetter = useCallback(event => {
    const { resource } = event || {};

    const { booking } = resource || {};

    const { state } = booking;

    const isBookingConfirmed = state === 'accepted';

    const styles = {
      className: classNames(css.booking, {
        // [css.bookingPending]: isBookingPending,
        [css.bookingConfirmed]: isBookingConfirmed,
        // [css.bookingBothStatesHorizontal]: isBookingPending && isBookingConfirmed,
      }),
    };

    return styles;
  }, []);

  return (
    <div className={classNames(css.root, className)}>
      {!fetchEventsError ? (
        <div className={css.calendar}>
          <Calendar
            style={CALENDAR_STYLE}
            endAccessor={getEndAccessor}
            timeslots={1}
            localizer={localizer}
            formats={FORMATS}
            events={events}
            components={components}
            views={VIEWS}
            defaultView="month"
            dayPropGetter={handleDayPropGetter}
            eventPropGetter={handleEventPropGetter}
            onRangeChange={onRangeChange}
            onSelectEvent={onSelectEvent}
            drilldownView={null}
            showMultiDayTimes
            selectable={false}
            selected={selectedEvent}
          />
        </div>
      ) : (
        <p className={css.error}>{intl.formatMessage({ id: 'General.error' })} </p>
      )}

      {fetchEventsInProgress ? (
        <div className={css.spinnerOverlay}>
          <IconSpinner />
        </div>
      ) : null}

      <div className={css.legend}>
        <div className={css.status}>
          <span className={classNames(css.statusCircle, css.bookingAvailable)} />
          {intl.formatMessage({ id: 'EditListingAvailabilityPanel.exceptionAvailable' })}
        </div>
        <div className={css.status}>
          <span className={classNames(css.statusCircle, css.bookingNotAvailable)} />
          {intl.formatMessage({ id: 'EditListingAvailabilityPanel.exceptionNotAvailable' })}
        </div>
        <div className={css.status}>
          <span className={classNames(css.statusCircle, css.bookingConfirmed)} />
          {intl.formatMessage({ id: 'General.booking' })}
        </div>
      </div>
    </div>
  );
};

BigCalendar.defaultProps = {
  events: [],
};

BigCalendar.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  events: arrayOf(
    shape({
      title: string,
      start: instanceOf(Date).isRequired,
      end: instanceOf(Date).isRequired,
      resource: object,
    }).isRequired
  ),
  fetchEventsInProgress: bool,
  fetchEventsError: object,

  selectedEvent: object,

  onClickCell: func,

  onSelectEvent: func,
  onRangeChange: func.isRequired,
};

export default injectIntl(BigCalendar);
