import React from 'react';

import css from './HourInput.module.css';
import classNames from 'classnames';
import { generateHourOptions } from '../../util/time';

const HourInput = props => {
  const {
    value,
    onChange,
    onBlur,
    onFocus,
    id,
    name,
    placeholder,
    className,
    inputClassName,
    icon,
  } = props;

  return (
    <div className={classNames(css.root, className)}>
      {icon}
      <select
        id={id}
        name={`${name}hour`}
        onChange={e => onChange(e?.target?.value)}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classNames(css.hourInput, inputClassName, { [css.placeholder]: !value })}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {generateHourOptions().map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HourInput;
