import React from 'react';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import { node, object, string } from 'prop-types';
import HourInput from './HourInput';

import css from './FieldHourInput.module.css';

const FieldHourComponent = props => {
  const {
    input,
    id,
    label,
    meta,
    placeholder,
    className,
    asideFromLabel,
    hideErrorMessage,
    ...rest
  } = props;
  const { value, ...restInputProps } = input;

  return (
    <div className={className}>
      {label ? (
        <div className={css.labelWrapper}>
          <label htmlFor={id}>{label}</label> {asideFromLabel}
        </div>
      ) : null}
      <HourInput id={id} placeholder={placeholder} {...restInputProps} value={value} {...rest} />
      {!hideErrorMessage && <ValidationError fieldMeta={meta} />}
    </div>
  );
};

FieldHourComponent.propTypes = {
  label: node,
  asideFromLabel: node,
  id: string,
  className: string,
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldHourInput = props => {
  const { ...rest } = props;

  return <Field component={FieldHourComponent} {...rest} />;
};

export default FieldHourInput;
