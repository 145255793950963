import React, { useCallback, useMemo, useState } from 'react';
import Button from '../Button/Button';
import { YoutubeEmbed } from '../../containers/PageBuilder/Primitives/YoutubeEmbed';
import IconPaste from '../IconPaste/IconPaste';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { arrayOf, func, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import IconClose from '../IconClose/IconClose';
import { validVideoUrl } from '../../util/validators';
import { checkVideoExists } from '../../util/videoHelpers';

import css from './VideoLinkImport.module.css';

const VideoLinkImportComponent = props => {
  const {
    intl,
    value,
    onChange,
    onFocus,
    onBlur,
    className,
    inputClassName,
    name,
    id,
    errorMessage,
  } = props;
  const [currentVideoLink, setCurrentVideoLink] = useState('');
  const [checkingVideoInProgress, setCheckingVideoInProgress] = useState(false);
  const [notFoundVideoUrl, setNotFoundVideoUrl] = useState();

  const videoNotFoundErrorMessage = intl.formatMessage({
    id: 'VideoLinkImport.videoLinkNotFound',
  });

  const urlInvalid = useMemo(
    () =>
      currentVideoLink &&
      validVideoUrl(
        intl.formatMessage({
          id: 'VideoLinkImport.videoLinkInvalid',
        })
      )(currentVideoLink),
    [currentVideoLink, intl]
  );

  const videoNotFound = useMemo(() => currentVideoLink === notFoundVideoUrl, [
    currentVideoLink,
    notFoundVideoUrl,
  ]);

  const pasteLink = async () => {
    const text = await navigator.clipboard.readText();
    setCurrentVideoLink(text);
  };

  const handleUploadVideoBtnClicked = useCallback(async () => {
    const url = currentVideoLink;
    if (!url) return;

    setCheckingVideoInProgress(true);
    const exists = await checkVideoExists(url);
    setCheckingVideoInProgress(false);

    if (!exists) setNotFoundVideoUrl(url);

    if (exists && !value.includes(url)) {
      onChange([...value, url]);
      setCurrentVideoLink('');
    }
  }, [currentVideoLink, onChange, value]);

  return (
    <div className={classNames(css.videosWrapper, className)}>
      <div
        className={classNames(css.inputContainer, inputClassName, {
          [css.inputError]: errorMessage || urlInvalid || videoNotFound,
        })}
      >
        <div className={css.videoLinkInput}>
          <input
            id={id}
            name={name}
            type="text"
            placeholder={intl.formatMessage({
              id: 'VideoLinkImport.inputPlaceholder',
            })}
            value={currentVideoLink}
            onChange={e => setCurrentVideoLink(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <button type="button" className={css.pasteBtn} onClick={pasteLink}>
            <IconPaste className={css.pasteIcon} />
          </button>
        </div>

        <Button
          type="button"
          className={css.uploadBtn}
          onClick={handleUploadVideoBtnClicked}
          inProgress={checkingVideoInProgress}
          disabled={urlInvalid || !currentVideoLink}
        >
          <FormattedMessage id="VideoLinkImport.uploadButtonLabel" />
        </Button>
        <div className={css.errorWrapper}>
          {urlInvalid && <small className={css.invalidUrl}>{urlInvalid}</small>}
          {videoNotFound && <small className={css.invalidUrl}>{videoNotFoundErrorMessage}</small>}
          {errorMessage && <small className={css.invalidUrl}>{errorMessage}</small>}
        </div>
      </div>

      {value.map(val => (
        <div key={val}>
          <div className={css.videoWrapper}>
            <button
              className={css.closeBtn}
              type="button"
              onClick={() => onChange(value.filter(v => v !== val))}
            >
              <IconClose />
            </button>

            <YoutubeEmbed url={val} />
          </div>
        </div>
      ))}
    </div>
  );
};

VideoLinkImportComponent.propTypes = {
  id: string,
  name: string,
  value: arrayOf(string),
  onChange: func,
  onFocus: func,
  onBlur: func,
  className: string,
  inputClassName: string,
  intl: intlShape.isRequired,
  errorMessage: string,
};

const VideoLinkImport = compose(injectIntl)(VideoLinkImportComponent);

export default VideoLinkImport;
