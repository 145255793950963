import React from 'react';

import { FormattedMessage } from 'react-intl';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import { H2 } from '../../Heading/Heading';
import NamedLink from '../../NamedLink/NamedLink';
import Creator1Image from '../../../containers/AboutUsPage/images/michael.jpeg';
import Creator2Image from '../../../containers/AboutUsPage/images/jack.jpeg';

import css from './ExpertHelp.module.css';
import classNames from 'classnames';

const Image = props => {
  const { alt, ...rest } = props;

  return <img {...rest} alt={alt} />;
};

const LazyImage = lazyLoadWithDimensions(Image, {
  loadAfterInitialRendering: 3000,
});

const ExpertHelp = props => {
  const { className } = props;

  return (
    <div className={classNames(className, css.root)}>
      <H2 as="h2">
        <FormattedMessage id="OrderPanel.expertHelpTitle" />
      </H2>
      <p>
        <FormattedMessage id="OrderPanel.expertHelpText" />
      </p>
      <div className={css.images}>
        <div className={css.imgContainer}>
          <LazyImage src={Creator1Image} alt="" />
        </div>
        <div className={css.imgContainer}>
          <LazyImage src={Creator2Image} alt="" />
        </div>
      </div>
      <NamedLink name="ContactUsPage" className={css.contactUsLink}>
        <FormattedMessage id="ContactUsPage.title" />
      </NamedLink>
    </div>
  );
};

export default ExpertHelp;
