import classNames from 'classnames';
import React from 'react';
import Tooltip from './Tooltip';

import css from './InfoTooltip.module.css';

const InfoTooltip = props => {
  const { className, triggerClassName, children, ...restOfProps } = props;

  return (
    <Tooltip
      className={classNames(css.tooltip, className)}
      triggerClassName={classNames(css.tooltipTrigger, triggerClassName)}
      effect="solid"
      {...restOfProps}
    >
      {children}
    </Tooltip>
  );
};

export default InfoTooltip;
