import React, { useEffect } from 'react';
import classNames from 'classnames';

import css from './Carousel.module.css';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};

const Carousel = props => {
  const { className, numColumns, children, id } = props;

  const sliderContainerId = `${id}-container`;
  const sliderId = `${id}-slider`;
  const numberOfBlocks = React.Children.count(children)?.length;
  const hasBlocks = numberOfBlocks > 0;

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, [hasBlocks, sliderContainerId]);

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  return (
    <div className={classNames(css.carouselContainer, className)} id={sliderContainerId}>
      <div
        className={classNames(css.carouselArrows, {
          [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
        })}
      >
        <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
          ‹
        </button>
        <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
          ›
        </button>
      </div>
      <div className={getColumnCSS(numColumns)} id={sliderId}>
        {React.Children.map(children, child => {
          return <div className={css.block}>{child}</div>;
        })}
      </div>
    </div>
  );
};

export default Carousel;
