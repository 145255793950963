import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import {
  IconArrowHead,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  SecondaryButton,
  ValidationError,
} from '../../components';

import css from './FieldIconPicker.module.css';

const FieldIconPickerComponent = props => {
  const {
    className,
    id,
    label,
    input,
    meta,
    onChange: propOnChange,
    asideFromLabel,
    options,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const classes = classNames(css.root, className);

  const selectedOption = useMemo(() => options?.find(opt => opt.value === input.value), [
    input.value,
    options,
  ]);

  const menuLabelClasses = classNames(css.menuLabel, {
    [css.error]: hasError,
    [css.labelSelected]: selectedOption,
  });

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const handleChange = value => {
    input.onChange(value);

    propOnChange?.(value);
  };

  const onToggleActive = isOpen => {
    setIsOpen(isOpen);

    if (isOpen) input.onFocus();
    else input.onBlur();
  };

  return (
    <div className={classes}>
      {label ? (
        <div className={css.labelWrapper}>
          <label htmlFor={id}>{label}</label>
          {asideFromLabel}
        </div>
      ) : null}
      <Menu className={css.menu} isOpen={isOpen} onToggleActive={onToggleActive} useArrow={false}>
        <MenuLabel id={id} rootClassName={menuLabelClasses}>
          {selectedOption?.image ? (
            <img src={selectedOption.image} alt="" className={css.img} />
          ) : (
            <IconArrowHead direction="down" className={css.placeholderIcon} />
          )}
        </MenuLabel>

        <MenuContent contentClassName={css.menuContent}>
          {options.map(({ value, image }) => (
            <MenuItem key={value}>
              <SecondaryButton
                type="button"
                className={classNames(css.iconBtn, {
                  [css.iconSelected]: value === selectedOption?.value,
                })}
                onClick={() => handleChange(value)}
              >
                <img className={css.img} src={image} alt="" />
              </SecondaryButton>
            </MenuItem>
          ))}
        </MenuContent>
      </Menu>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldIconPickerComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldIconPickerComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  asideFromLabel: node,

  children: node,
};

const FieldIconPicker = props => {
  return <Field component={FieldIconPickerComponent} {...props} />;
};

export default FieldIconPicker;
