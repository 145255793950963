import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { ADD_ON_ICON_OPTIONS } from '../../util/listing';
import { InlineTextButton } from '../Button/Button';
import { ADD_ONS_OPTIONS } from '../../constants/listing';

import css from './AddOn.module.css';

const AddOn = props => {
  const { className, addOn, isEdit, onEdit, onRemove } = props;

  const intl = useIntl();

  const { title, price, iconName } = addOn || {};

  const formattedPrice = price ? formatMoney(intl, price) : null;

  const image = useMemo(() => ADD_ON_ICON_OPTIONS.find(opt => opt.value === iconName)?.image, [
    iconName,
  ]);

  return (
    <section className={classNames(css.root, className)}>
      <img src={image} alt="" className={css.img} />

      <div className={css.infoWrapper}>
        <span className={css.title}>{title}</span>
        <div className={css.category}>
          <FormattedMessage
            className={css.category}
            id={ADD_ONS_OPTIONS.find(option => option.value === addOn?.category)?.label}
          />
        </div>
        <span className={css.price}>{formattedPrice}</span>
      </div>

      {isEdit && (
        <div className={css.actionBtns}>
          <InlineTextButton type="button" onClick={onEdit}>
            {intl.formatMessage({ id: 'General.edit' })}
          </InlineTextButton>
          <span>•</span>
          <InlineTextButton type="button" onClick={onRemove}>
            {intl.formatMessage({ id: 'General.remove' })}
          </InlineTextButton>
        </div>
      )}
    </section>
  );
};

export default AddOn;
