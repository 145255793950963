// ================ Action types ================ //

import api from '../api';
import { stringifyDateToISO8601 } from '../util/dates';

export const GET_AVAILABILITY_REQUEST = 'app/availability/GET_AVAILABILITY_REQUEST';
export const GET_AVAILABILITY_SUCCESS = 'app/availability/GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_ERROR = 'app/availability/GET_AVAILABILITY_ERROR';

export const CREATE_AVAILABILITY_REQUEST = 'app/availability/CREATE_AVAILABILITY_REQUEST';
export const CREATE_AVAILABILITY_SUCCESS = 'app/availability/CREATE_AVAILABILITY_SUCCESS';
export const CREATE_AVAILABILITY_ERROR = 'app/availability/CREATE_AVAILABILITY_ERROR';

const CREATE_EXCEPTION_REQUEST = 'app/availability/CREATE_EXCEPTION_REQUEST';
const CREATE_EXCEPTION_SUCCESS = 'app/availability/CREATE_EXCEPTION_SUCCESS';
const CREATE_EXCEPTION_ERROR = 'app/availability/CREATE_EXCEPTION_ERROR';

const DELETE_EXCEPTION_REQUEST = 'app/availability/DELETE_EXCEPTION_REQUEST';
const DELETE_EXCEPTION_SUCCESS = 'app/availability/DELETE_EXCEPTION_SUCCESS';
const DELETE_EXCEPTION_ERROR = 'app/availability/DELETE_EXCEPTION_ERROR';

const GET_EXCEPTIONS_REQUEST = 'app/availability/GET_EXCEPTIONS_REQUEST';
const GET_EXCEPTIONS_SUCCESS = 'app/availability/GET_EXCEPTIONS_SUCCESS';
const GET_EXCEPTIONS_ERROR = 'app/availability/GET_EXCEPTIONS_ERROR';

const GET_BOOKINGS_REQUEST = 'app/availability/GET_BOOKINGS_REQUEST';
const GET_BOOKINGS_SUCCESS = 'app/availability/GET_BOOKINGS_SUCCESS';
const GET_BOOKINGS_ERROR = 'app/availability/GET_BOOKINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  availability: [],
  getAvailabilityError: null,
  getAvailabilityInProgress: false,

  createAvailabilityInProgress: false,
  createAvailabilityError: null,

  createExceptionInProgress: false,
  createExceptionError: null,
  deleteExceptionInProgress: false,
  deleteExceptionError: null,
  exceptions: [],
  getExceptionsInProgress: false,
  getExceptionsError: null,

  bookings: [],
  getBookingsInProgress: false,
  getBookingsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_AVAILABILITY_REQUEST:
      return {
        ...state,
        getAvailabilityInProgress: true,
        getAvailabilityError: null,
      };
    case GET_AVAILABILITY_SUCCESS:
      return { ...state, getAvailabilityInProgress: false, availability: payload };
    case GET_AVAILABILITY_ERROR:
      return { ...state, getAvailabilityInProgress: false, getAvailabilityError: payload };

    case CREATE_AVAILABILITY_REQUEST:
      return { ...state, createAvailabilityInProgress: true, createAvailabilityError: null };
    case CREATE_AVAILABILITY_SUCCESS:
      return { ...state, createAvailabilityInProgress: false, availability: payload };
    case CREATE_AVAILABILITY_ERROR:
      return { ...state, createAvailabilityInProgress: false, createAvailabilityError: payload };

    case CREATE_EXCEPTION_REQUEST:
      return { ...state, createExceptionInProgress: true, createExceptionError: null };
    case CREATE_EXCEPTION_SUCCESS:
      return {
        ...state,
        createExceptionInProgress: false,
        exceptions: [...state.exceptions, payload],
      };
    case CREATE_EXCEPTION_ERROR:
      return { ...state, createExceptionInProgress: false, createExceptionError: payload };

    case DELETE_EXCEPTION_REQUEST:
      return { ...state, deleteExceptionInProgress: true, deleteExceptionError: null };
    case DELETE_EXCEPTION_SUCCESS:
      return {
        ...state,
        deleteExceptionInProgress: false,
        exceptions: state.exceptions.filter(({ _id }) => payload._id !== _id),
      };
    case DELETE_EXCEPTION_ERROR:
      return { ...state, deleteExceptionInProgress: false, deleteExceptionError: payload };

    case GET_EXCEPTIONS_REQUEST:
      return {
        ...state,
        getExceptionsInProgress: true,
        getExceptionsError: null,
      };
    case GET_EXCEPTIONS_SUCCESS:
      return { ...state, getExceptionsInProgress: false, exceptions: payload };
    case GET_EXCEPTIONS_ERROR:
      return { ...state, getExceptionsInProgress: false, getExceptionsError: payload };

    case GET_BOOKINGS_REQUEST:
      return {
        ...state,
        getBookingsInProgress: true,
        getBookingsError: null,
      };
    case GET_BOOKINGS_SUCCESS:
      return { ...state, getBookingsInProgress: false, bookings: payload };
    case GET_BOOKINGS_ERROR:
      return { ...state, getBookingsInProgress: false, getBookingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const getAvailabilityRequest = () => ({ type: GET_AVAILABILITY_REQUEST });
const getAvailabilitySuccess = payload => ({ type: GET_AVAILABILITY_SUCCESS, payload });
const getAvailabilityError = error => ({
  type: GET_AVAILABILITY_ERROR,
  payload: error,
  error: true,
});

const createAvailabilityRequest = () => ({ type: CREATE_AVAILABILITY_REQUEST });
const createAvailabilitySuccess = payload => ({ type: CREATE_AVAILABILITY_SUCCESS, payload });
const createAvailabilityError = error => ({
  type: CREATE_AVAILABILITY_ERROR,
  payload: error,
  error: true,
});

const createExceptionRequest = () => ({ type: CREATE_EXCEPTION_REQUEST });
const createExceptionSuccess = payload => ({ type: CREATE_EXCEPTION_SUCCESS, payload });
const createExceptionError = error => ({
  type: CREATE_EXCEPTION_ERROR,
  payload: error,
  error: true,
});

const deleteExceptionRequest = () => ({ type: DELETE_EXCEPTION_REQUEST });
const deleteExceptionSuccess = payload => ({ type: DELETE_EXCEPTION_SUCCESS, payload });
const deleteExceptionError = error => ({
  type: DELETE_EXCEPTION_ERROR,
  payload: error,
  error: true,
});

const getExceptionsRequest = () => ({ type: GET_EXCEPTIONS_REQUEST });
const getExceptionsSuccess = payload => ({ type: GET_EXCEPTIONS_SUCCESS, payload });
const getExceptionsError = error => ({
  type: GET_EXCEPTIONS_ERROR,
  payload: error,
  error: true,
});

const getBookingsRequest = () => ({ type: GET_BOOKINGS_REQUEST });
const getBookingsSuccess = payload => ({ type: GET_BOOKINGS_SUCCESS, payload });
const getBookingsError = error => ({
  type: GET_BOOKINGS_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const getAvailabilityForListing = (listingId, params) => (dispatch, getState, sdk) => {
  dispatch(getAvailabilityRequest());

  return api.listings
    .getAvailability(listingId, params)
    .then(res => {
      dispatch(getAvailabilitySuccess(res.data));

      return res;
    })
    .catch(e => {
      dispatch(getAvailabilityError(e));
    });
};

export const createUpdateAvailabilityForListing = (listingId, plan, isUpdate) => dispatch => {
  dispatch(createAvailabilityRequest());

  const { timezone, entries } = plan;

  return api.listings
    .updateAvailabilityPlan(listingId, { entries, timezone })
    .then(res => {
      dispatch(createAvailabilitySuccess(res.data));

      return res;
    })
    .catch(e => {
      dispatch(createAvailabilityError(e));
    });
};

export const createException = (listingId, exception) => dispatch => {
  dispatch(createExceptionRequest());

  return api.listings
    .addException(listingId, exception)
    .then(res => {
      dispatch(createExceptionSuccess(res.data.exception));

      return res;
    })
    .catch(e => {
      dispatch(createExceptionError(e));

      throw e;
    });
};

export const deleteException = (listingId, exception) => dispatch => {
  dispatch(deleteExceptionRequest());

  return api.listings
    .deleteException(listingId, exception._id)
    .then(res => {
      dispatch(deleteExceptionSuccess(exception));

      return res;
    })
    .catch(e => {
      dispatch(deleteExceptionError(e));
      throw e;
    });
};

export const getExceptions = (listingId, params) => dispatch => {
  dispatch(getExceptionsRequest());

  return api.listings
    .getExceptions(listingId, params)
    .then(res => {
      dispatch(getExceptionsSuccess(res.data));

      return res;
    })
    .catch(e => {
      dispatch(getExceptionsError(e));
    });
};

export const getBookings = (start, end) => dispatch => {
  dispatch(getBookingsRequest());

  return api.transactions
    .getBookings({ start: stringifyDateToISO8601(start), end: stringifyDateToISO8601(end) })
    .then(res => {
      dispatch(getBookingsSuccess(res.data));

      return res;
    })
    .catch(e => {
      dispatch(getBookingsError(e));
    });
};
