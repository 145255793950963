import httpClient from '../httpClient';

const updateAvailabilityPlan = (listingId, plan) => {
  return httpClient.patch(`listings/${listingId}/availability`, plan);
};

const getAvailability = (listingId, params) => {
  return httpClient.get(`listings/${listingId}/availability`, { params });
};

/**
 * @param {{ seats: number, start: "YYYY-MM-DD", end: "YYYY-MM-DD"}} exception
 */
const addException = (listingId, exception) => {
  return httpClient.post(`listings/${listingId}/exceptions`, exception);
};

const deleteException = (listingId, exceptionId) => {
  return httpClient.delete(`listings/${listingId}/exceptions/${exceptionId}`);
};

const getExceptions = (listingId, params) => {
  return httpClient.get(`listings/${listingId}/exceptions`, { params });
};

const getListings = params => {
  return httpClient.get(`listings`, { params });
};

const addExternalReviews = params => {
  return httpClient.post(`listings/external-reviews`, params);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateAvailabilityPlan,
  getAvailability,
  addException,
  deleteException,
  getExceptions,
  getListings,
  addExternalReviews,
};
