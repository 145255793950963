import React from 'react';

import css from './ClientsTerms.module.css';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import NamedLink from '../NamedLink/NamedLink';
import ExternalLink from '../ExternalLink/ExternalLink';

const ClientsTerms = props => {
  const { className } = props;

  const config = useConfiguration();
  const vatNumber = config.vatNumber;

  return (
    <div className={classNames(css.root, className)}>
      <p className={css.center}>
        <b>Music Marmalade and Client Terms</b>
      </p>
      <p className={css.p}>
        Please read these terms carefully. Your attention is drawn, in particular, to clause 4 which
        deals with payment, clause 5 which deals with your rights to cancel and clause 7 which deals
        with when additional costs may become payable by you.
      </p>
      <ol className={css.orderList}>
        <li>
          <h2 className={css.h2}>
            Who are we and what do we do?
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We are Entium Platforms Ltd (trading as Music Marmalade), a company registered in
                Scotland under company registration number SC797777. Our registered office is at
                Unit A7 Somervell Trading Estate, Somervell Street, Cambuslang, Glasgow, G72 7EB.
                Our VAT registration number is {vatNumber}.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We will refer to ourselves as <b>Music Marmalade, us, or we </b>throughout these
                Terms. We may refer to you as <b>you </b>or the <b>Client</b>.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We provide an online booking platform on our <b>Website</b>, allowing Clients to
                make contact with and book music artists (<b>Artists</b>) to perform at events (
                <b>Events</b>). This is the only service we provide to Clients.
              </p>
            </li>
            <li>
              <p className={css.p}>
                When a Client uses the Website to book an Artist (<b>Booking</b>) they will enter
                into a Booking Contract (see below) with the Artist directly for their attendance at
                and performance at the Event. Music Marmalade is not involved in the Event at all
                and has no liability for the Artist and their actions.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Music Marmalade is not affiliated in any way with Artists that advertise on the
                Website, nor does it employ them, act as an agent or endorse them, although we
                always do our best to ensure Artists listed on the Website are of high quality. We
                encourage Clients to leave reviews of Artists they have booked, and recommend that
                Clients check the reviews before making a Booking.
              </p>
            </li>
            <li>
              <p className={css.p}>
                These terms and conditions (<b>Terms</b>) set out the terms on which you can book
                Artists via the Website and form an agreement (<b>Agreement</b>) between you and us
                when you use the Website to make a Booking Request (defined below). By using the
                Website to make a Booking Request, you confirm that you accept these Terms and you
                become legally bound by them.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Please read these Terms carefully. These Terms tell you who we are and other
                important information. They describe what we do, set out your rights and
                responsibilities and tell you what to do if there is a problem. These terms also
                limit our liability.
              </p>
            </li>
            <li>
              <p className={css.p}>
                These Terms apply to you if you are a consumer. This means that you primarily use
                our Website for personal use (and will not use our Website for commercial, business
                or resale purposes).
              </p>
            </li>
            <li>
              <p className={css.p}>
                You may only use our Website to make Bookings for Events that take place in the
                United Kingdom.
              </p>
            </li>
            <li>
              <p className={css.p}>
                By using the Website, you confirm that you are an individual who is at least 18
                years old (because people under the age of 18 are not legally capable of concluding
                a contract).
              </p>
            </li>
            <li>
              <p className={css.p}>
                Please note that we may update, change or replace any part of these Terms in our
                sole discretion. However, the terms which apply to your Booking will be those in
                force at the time you make your Booking.
              </p>
            </li>
            <li>
              <p className={css.p}>
                For information regarding access to and use of our Website, please see our{' '}
                <NamedLink name="TermsOfUsePage">Website Terms of Use </NamedLink>
                which you agree to be bound by.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Registering with Music Marmalade
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                In order to make full use of the Website you must register as a user of the Website
                and create a profile (<b>Client Profile</b>).
              </p>
            </li>
            <li>
              <p className={css.p}>
                We will collect some personal data about you during the registration process and in
                order to process your Booking (e.g. your name, email address, payment details and
                delivery information). For information regarding how we process personal data,
                please see our{' '}
                <NamedLink name="PrivacyPolicyPage"> Privacy and Cookies Notice</NamedLink>.
              </p>
            </li>
            <li data-list-text="2.3.">
              <p className={css.p}>
                You acknowledge that we have permission to pass on your personal data to any Artist
                that you seek to engage via the Website by submitting a Booking Request.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={3}>
          <h2 className={css.h2}>
            Making a Booking
            <hr />
          </h2>

          <h3 className={css.h3}>Searching for an Artist</h3>
          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                In order to make an enquiry with an Artist, you must undertake a search of the
                Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Our search engine on the Website will then provide you with a list of Artists that
                have declared themselves as available (<b>Available</b>) for your Event alongside an
                estimated price. You can then browse the Artist profiles to select which Artist you
                would like to request to book.
              </p>
            </li>
            <h3 className={css.h3}>Connecting with an Artist</h3>
            <li>
              <p className={css.p}>
                When you are happy to proceed to book an Artist you can make a booking request (
                <b>Booking Request</b>). The Booking Request will contain all details of the Event
                and the total price of the booking (<b>Fees</b>). Please check your Booking Request
                carefully before submitting it. You are responsible for ensuring that your Booking
                Request is complete and accurate.
              </p>
            </li>
            <li>
              <p className={css.p}>
                This Booking Request will be sent to the Artist who will then have 48 hours to
                review the Booking Request and make a decision about whether they are happy to
                accept the Booking Request.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the Artist chooses to accept the Booking, we will send you a notification to
                confirm the Booking ( Confirmation ). When we send you Confirmation, a contract
                between you and the Artist will be created which will consist of the terms that
                apply to the relationship between the Client and the Artist, and the information
                contained within the Confirmation, which we have taken from the Booking Request (
                <NamedLink name="BookingContractPage">the Booking Contract</NamedLink>
                ).
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2 className={css.h2}>
            Payment
            <hr />
          </h2>
          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>The Fees payable may be split into two payments:</div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>
                    The <b>Reservation Fee</b> which is 15% of the total Fees
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    A <b>Final Payment</b> of any remaining Fees which have not been paid under the
                    Reservation Fee
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>
                When you submit your Booking Request we will require you to provide us with your
                payment card information so that we can pre-authorise the Reservation Fee to secure
                your Event with the Artist.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Once the Artist has accepted your Booking and 48 hours after you made your Booking
                Request, we will charge your payment card for the full Reservation Fee.
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>The Final Payment is due in line with the table below:</div>
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Time of Booking</th>
                    <th>Invoice for Final Payment Sent</th>
                    <th>Final Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>60 or more days before the event</td>
                    <td>60 days before the Event</td>
                    <td>30 days before the Event</td>
                  </tr>
                  <tr>
                    <td>31 to 59 days before the Event</td>
                    <td>When the Artist accepts the Booking Request</td>
                    <td>14 days before the Event</td>
                  </tr>
                  <tr>
                    <td>30 days or less before the Event</td>
                    <td>When the Artist accepts the Booking Request</td>
                    <td>Within 24 hours of acceptance by the Artist of the Booking Request</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p className={css.p}>
                If you do not make payments when requested, we will do our best to make contact with
                you in order to resolve the issue. In the event we are unable to make contact with
                you, the Artist shall be entitled to cancel the Booking Contract and we shall be
                entitled to cancel this Agreement between you and us.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We use a trusted third party payment provider called Stripe to process payments on
                our website. Stripe have received certification as a PCI Service Provider Level 1,
                which means they adhere to a strict regime of security when processing payments.
                They have their own separate terms of use which can be found{' '}
                <ExternalLink href="https://stripe.com/legal/consumer">here</ExternalLink>.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h2 className={css.h2}>
            Your Rights to Cancel
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Pursuant to section 28(1)(h) of the Consumer Contracts (Information, Cancellation
                and Additional Charges) Regulations 2013, the 14 day “cooling off period” which
                would usually apply to consumer contracts concluded online does not apply to this
                agreement between you and Music Marmalade.
              </p>
            </li>
            <li>
              <p className={css.p}>
                You can cancel your Booking Contract by notifying Music Marmalade via your Customer
                Account on the Website.
              </p>
            </li>
            <li className={css.itemNestedList}>
              <div>
                You can cancel your Booking Contract at any time, subject to the following payments
                being made:
              </div>
              <br />
              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Time of Cancellation</th>
                    <th>Cancellation Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Within 48 hours of submitting your Booking Request</td>
                    <td>No payment will be due.</td>
                  </tr>
                  <tr>
                    <td>More than 182 days before the Event</td>
                    <td>
                      No additional payment will be due, but the Reservation Fee will not be
                      refunded.
                    </td>
                  </tr>
                  <tr>
                    <td>92 to 181 days before the Event</td>
                    <td>50% of Fee is due.</td>
                  </tr>
                  <tr>
                    <td>31 to 91 days before the Event</td>
                    <td>75% of Fee is due.</td>
                  </tr>
                  <tr>
                    <td>30 or less days before the event</td>
                    <td>100% of Fee is due.</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p className={css.p}>To cancel your Booking, please use your Client Profile.</p>
            </li>
            <li>
              <p className={css.p}>
                If you would like to discuss your Booking prior to cancellation, please email Music
                Marmalade at{' '}
                <a href="mailto:hello@musicmarmalade.com" target="_blank" rel="noreferrer">
                  hello@musicmarmalade.com
                </a>
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Varying the Booking Contract
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You must comply with the terms of the
                <NamedLink name="BookingContractPage"> Booking Contract</NamedLink>. Any breach of
                the Booking Contract by you will be deemed to be a breach of these Terms.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If an Artist or a Client want to vary a Booking Contract once it has been entered
                into, this must be done via the messaging function on the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Any additional payment that is required for any changes to the Booking Contract must
                be arranged directly between the Client and Artist and will not be handled via the
                Website.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Additional Fees
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                The Client will be responsible for any reasonable additional costs or fees that the
                Artist may incur following confirmation of the Booking due to a variation to the
                Booking Contract, or other incidental expenses, and such costs or fees must be dealt
                with between the Artist and Client directly. Music Marmalade shall not be liable for
                any additional fees.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In the event that the Artist requires overnight accommodation in order to perform at
                the Event, the Client will be responsible for organising and paying for suitable
                accommodation. Failure to provide such overnight accommodation may result in the
                Artist being unable to attend the Event in which case the Client shall remain liable
                for the full Fee. You will be informed of the Artist’s overnight accommodation
                requirements at point of sending Booking Request.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Support from Music Marmalade
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We are available seven days a week to provide support to Clients and Artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The Website has an approximate uptime of 99.9%, although we cannot guarantee that it
                will be accessible at all times as we may need to conduct maintenance, or there
                could be an unexpected issue that affects the Website.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Clients can contact us at{' '}
                <a href="mailto:hello@musicmarmalade.com" target="_blank" rel="noreferrer">
                  hello@musicmarmalade.com
                </a>{' '}
                in order to receive support in the unlikely event that something does not go to
                plan.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Your Obligations and Restrictions
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>You agree and accept that:</div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>
                    you will provide complete and accurate information to us as and when needed and
                    you are responsible for making sure that the data you provide us, and the
                    Booking Request you have created, is correct; and
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    you will comply with these Terms, the Website Terms of Use and the Booking
                    Contract, and any other documents referred to in them, when using our Website
                    and making a booking with an Artist.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>
                In reading and accepting these Terms, you are aware of and understand your rights
                and responsibilities, and if you are not sure, you have contacted us on our{' '}
                <NamedLink name="ContactUsPage">Contact Page </NamedLink>
                for help or more information.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Our Right to Cancel
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>
                We may terminate any and all Agreements we have with you at any time by contacting
                you in writing if:
              </div>
              <p></p>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <p className={css.p}>
                    you commit a serious breach of these Terms, the Website Terms of Use or the
                    Booking Contract;
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    you do or take part in anything illegal when using our Website;
                  </p>
                </li>
                <li>
                  <p className={css.p}>
                    you fail to pay any amount due under the these Terms on the due date; or
                  </p>
                </li>
                <li>
                  <p className={css.p}>the Artist cancels the Booking Contract.</p>
                </li>
              </ol>
            </li>
            <li>
              <p className={css.p}>Our right to terminate does not affect any of your rights.</p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Feedback about the Website and Complaints about Artists
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                We welcome any feedback about the Music Marmalade Website and we are always keen to
                hear about ways to improve what we do. If you have any comments, please submit these
                to <NamedLink name="ContactUsPage">Contact Page</NamedLink> .
              </p>
            </li>
            <li>
              <p className={css.p}>
                If you do have any complaints about the Artist, please let the Artist know as soon
                as possible in accordance with the terms of the Booking Contract. You can also let
                us know via the Website as it is very important to us that we know about experiences
                that Clients have with Artists.
              </p>
            </li>
            <li>
              <p className={css.p}>
                We encourage Clients to leave reviews of Artists they have Booked on our Website. We
                will not tolerate any form of abuse of Artists and reserve the right to remove
                reviews which are deemed to be offensive, untrue or which breach the Website Terms
                of Use.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Our Liability to You
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                Our Website is intended for use by Clients who are consumers, and we will not be
                liable for losses for non-consumers.
              </p>
            </li>
            <li>
              <p className={css.p}>
                For the avoidance of doubt, Music Marmalade provides the Website and ability for
                Clients to make Bookings and payments only. Music Marmalade has no liability for the
                acts or omissions of Artists, or anything that may happen at an Event or pursuant to
                a Booking Contract.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If we breach these terms or are negligent, we are liable to you for foreseeable loss
                or damage that you suffer as a result. By ‘foreseeable’ we mean that, at the time
                the Agreement was made, it was either clear that such loss or damage would occur, or
                you and we both knew that it might reasonably occur, as a result of something we did
                (or failed to do).
              </p>
            </li>
            <li>
              <p className={css.p}>
                We are not liable to you for any loss or damage that was not foreseeable, any loss
                or damage not caused by our breach or negligence, or any business loss or damage.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If we are affected by an unforeseeable event, we will promptly let you know if this
                means we are unable to fulfil the Agreement.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In no event shall Music Marmalade be liable to you for provision of services which
                are due to be performed by the Artist and you understand and acknowledge that Music
                Marmalade is not able to control the acts or omissions of such Artists. In the event
                that you think that you have a claim against an Artist, you must contact that Artist
                directly.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Nothing in these terms excludes or limits our liability for any death or personal
                injury caused by our negligence, liability for fraud or fraudulent
                misrepresentation, or any other liability that the law does not allow us to exclude
                or limit.
              </p>
            </li>
          </ol>
        </li>
        <li data-list-text={13}>
          <h2 className={css.h2}>
            Indemnification
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You shall indemnify Music Marmalade against all liabilities, costs, expenses,
                damages and losses (including but not limited to any direct, indirect or
                consequential losses, loss of profit, loss of reputation and all interest, penalties
                and legal costs (calculated on a full indemnity basis) and all other reasonable
                professional costs and expenses) suffered or incurred by us arising out of or in
                connection with your breach of this Agreement, the Booking Agreement and the Website
                Terms of Use.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            Force Majeure
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                In respect of both this Agreement and the Booking Contract, no Party will be liable
                for their failure to fulfil their obligations if such failure is caused by a Force
                Majeure Event. A Force Majeure Event is defined as a natural disaster (including but
                not limited to; storm, earthquake, hurricane, flood, etc.), epidemic, pandemic,
                civil unrest, war, terrorism, death, illness certified by a medical profession,
                accident, or any directives issued by a government or local authority which prevents
                a party from performing their obligations to the other party.
              </p>
            </li>
            <li>
              <p className={css.p}>
                In respect of death and/or sickness, in order for a party to claim a Force Majeure
                Event has occurred the affected party must be a named party to this Agreement or the
                Booking Contract (or their spouse where the affected party is a Client and the Event
                is a wedding).
              </p>
            </li>
            <li>
              <p className={css.p}>
                If a Party needs to rely on this clause and claim that a Force Majeure Event has
                occurred, they must inform the other party promptly. Any party seeking to claim that
                a Force Majeure Event has taken place must provide sufficient evidence to the other
                party and demonstrate that they took reasonable steps to mitigate the effects of the
                Force Majeure Event.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the party claiming that a Force Majeure Event has occurred is an Artist or
                Client, Music Marmalade will endeavour to work with that party to seek to mitigate
                or resolve the effect of the Force Majeure Event. This may involve Music Marmalade
                assisting to reschedule the Event, cancel the Event or appoint another Artist to
                perform at the Event, at the expense of the Client.
              </p>
            </li>
            <li>
              <p className={css.p}>
                Should the Client choose to reschedule the Event with the same Artist, Music
                Marmalade will provide a list of available dates where the Artist is free.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If it is not possible to reschedule with the same Artist, Music Marmalade will help
                the client find an artist of similar quality and price.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If the Event is cancelled due to a Force Majeure Event despite the Client being
                offered a viable alternative, the provisions of clause 5 will apply.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2 className={css.h2}>
            General
            <hr />
          </h2>

          <ol className={css.nestedOrderList}>
            <li>
              <p className={css.p}>
                You are not allowed to transfer your rights or obligations under these terms to
                anyone without our prior written consent. We may transfer our rights and obligations
                under these terms to another business without your consent, but we will notify you
                of the transfer and make sure that your rights are not adversely affected as a
                result.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If any provision of these Terms (or part of any provision) is or becomes illegal,
                invalid or unenforceable, the legality, validity and enforceability of any other
                provision of these terms will not be affected.
              </p>
            </li>
            <li>
              <p className={css.p}>
                This Agreement does not give rise to any rights under the Contracts (Rights of Third
                Parties) Act 1999 to enforce any term of this Agreement.
              </p>
            </li>
            <li>
              <p className={css.p}>
                If you breach these terms and we take no action, we will still be entitled to use
                our rights and remedies in any other situation where you breach these terms.
              </p>
            </li>
            <li>
              <p className={css.p}>
                The laws of England and Wales apply to these terms. Any disputes will be subject to
                the exclusive jurisdiction of the courts of England and Wales, unless you are a
                consumer and live in either Northern Ireland or Scotland. In which case, you can
                choose to bring a claim in England and Wales or in the courts of another part of the
                UK in which you live.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default ClientsTerms;
