import React from 'react';

const IconClock = props => {
  const { className } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM9.99949 17.9999C5.58121 17.9999 1.99949 14.4182 1.99949 9.99988C1.99949 5.5816 5.58121 1.99988 9.99949 1.99988C14.4178 1.99988 17.9995 5.5816 17.9995 9.99988C17.9995 12.1216 17.1566 14.1564 15.6563 15.6567C14.1561 17.157 12.1212 17.9999 9.99949 17.9999ZM13.1002 10.63L11.0002 9.42V5C11.0002 4.44771 10.5524 4 10.0002 4C9.44787 4 9.00016 4.44771 9.00016 5V10C9.00016 10 9.00016 10.08 9.00016 10.12C9.00608 10.1889 9.02296 10.2564 9.05016 10.32C9.07075 10.3793 9.09757 10.4363 9.13016 10.49C9.15752 10.5468 9.19106 10.6005 9.23016 10.65L9.39016 10.78L9.48016 10.87L12.0802 12.37C12.2326 12.4564 12.405 12.5012 12.5802 12.5C13.0355 12.5032 13.4356 12.1983 13.5532 11.7583C13.6708 11.3184 13.4764 10.8545 13.0802 10.63H13.1002Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconClock;
