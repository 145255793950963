import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { AspectRatioWrapper, IconArtist, IconSpinner, NamedLink, ResponsiveImage } from '..';

import css from './BlogList.module.css';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';

const RENDER_SIZES = '200px';

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000,
});

const BlogList = props => {
  const { className, blogs, inProgress, error } = props;

  const intl = useIntl();

  return (
    <div className={classNames(css.root, className)}>
      {error ? (
        <div className={css.error}>{intl.formatMessage({ id: 'General.error' })}</div>
      ) : null}
      {inProgress ? (
        <IconSpinner />
      ) : !blogs?.length ? (
        <div>{intl.formatMessage({ id: 'General.noBlogs' })}</div>
      ) : (
        <ul className={css.blogs}>
          {blogs.map((blog, i) => {
            const { assetPath, data } = blog;

            const splitPath = assetPath.split('/');

            const pageId = splitPath[splitPath.length - 1].split('.')[0];

            const heroSection = data.sections?.find(
              sec => sec.sectionId === 'hero' || sec.sectionType === 'hero'
            );

            const isClientBlog = assetPath.startsWith('/content/pages/blogs-client');

            if (!heroSection) return null;

            const { title, description, appearance } = heroSection;

            const { content: titleContent } = title || {};
            const { content: descContent } = description || {};

            const { backgroundImage } = appearance || {};

            const variants = backgroundImage
              ? Object.keys(backgroundImage?.attributes?.variants)
              : [];

            return (
              <li key={i}>
                <NamedLink name="CMSPage" params={{ pageId }} className={css.blog}>
                  <AspectRatioWrapper width={260} height={180} className={css.aspectRatioWrapper}>
                    <LazyImage
                      rootClassName={css.rootForImage}
                      alt={titleContent}
                      image={backgroundImage}
                      variants={variants}
                      sizes={RENDER_SIZES}
                    />
                  </AspectRatioWrapper>
                  <div className={css.info}>
                    <div className={css.blogTitle}>{titleContent}</div>
                    <div className={css.blogFor}>
                      <IconArtist />
                      {isClientBlog
                        ? intl.formatMessage({ id: 'General.forClients' })
                        : intl.formatMessage({ id: 'General.forArtists' })}
                    </div>
                    <div className={css.blogDesc}>{descContent}</div>
                  </div>
                </NamedLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default BlogList;
