import React, { useMemo } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug, stringify } from '../../util/urlHelpers';

import {
  AspectRatioWrapper,
  IconArtist,
  IconLocation,
  IconReviewStar,
  NamedLink,
  ResponsiveImage,
} from '../../components';
import { calculateTotalRating } from '../../util/listing';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.perUnit}>{intl.formatMessage({ id: 'General.from' }) + ':'}</div>

      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showExtendedCategories = false,
    searchQueryToAppend,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const image = publicData?.image;
  const variants =
    firstImage || image
      ? Object.keys(firstImage?.attributes?.variants || image).filter(k =>
          k.startsWith(variantPrefix)
        )
      : [];

  const bandCategoryOptions = config.listing.listingFields.find(f => f.key === 'bandCategory')
    ?.enumOptions;

  const { bandCategory, location } = publicData || {};

  const { city } = location || {};

  const selectedCategories = useMemo(() => {
    if (!bandCategory?.length) return null;

    const categories = bandCategory.map(
      cat => bandCategoryOptions?.find(opt => opt.option === cat)?.label
    );

    if (showExtendedCategories) return categories.slice(0, 3).join(', ');

    if (categories.length > 1) return categories[0] + ', +1';

    return categories[0];
  }, [bandCategory, bandCategoryOptions, showExtendedCategories]);

  const { averageRating, ratingCount, externalRatingCount, externalAverageRating } = metadata || {};

  const { totalRating, totalRatingCount } = calculateTotalRating(
    averageRating,
    ratingCount,
    externalRatingCount,
    externalAverageRating
  );

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const toMaybe = useMemo(
    () => (searchQueryToAppend ? { to: { search: stringify(searchQueryToAppend) } } : {}),
    [searchQueryToAppend]
  );

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }} {...toMaybe}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage || { attributes: { variants: image } }}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.mainInfo}>
          <div>
            <div className={css.location}>
              <IconLocation />
              <div>{city || '-'}</div>
            </div>
            <div className={css.rating}>
              <IconReviewStar isFilled />
              <b>
                {intl.formatNumber(totalRating || 0, {
                  maximumFractionDigits: 1,
                })}
              </b>
              <span> {`(${totalRatingCount || 0})`}</span>
            </div>
          </div>
          <div>
            <div className={css.categories}>
              <IconArtist />
              <div>{selectedCategories}</div>
            </div>
            <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
