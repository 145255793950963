import React from 'react';

const IconCheck = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4408 13.58L16.8608 22.18L13.5608 18.88C13.072 18.3092 12.3046 18.0606 11.574 18.2364C10.8434 18.4121 10.2729 18.9826 10.0972 19.7132C9.92145 20.4438 10.1701 21.2112 10.7408 21.7L15.4408 26.42C15.8183 26.7944 16.3292 27.0031 16.8608 27C17.3855 26.9978 17.8883 26.7895 18.2608 26.42L28.2608 16.42C28.6394 16.0445 28.8524 15.5333 28.8524 15C28.8524 14.4667 28.6394 13.9555 28.2608 13.58C27.4807 12.8046 26.2209 12.8046 25.4408 13.58ZM20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0ZM20 36C11.1634 36 4 28.8366 4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 24.2435 34.3143 28.3131 31.3137 31.3137C28.3131 34.3143 24.2435 36 20 36Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCheck;
