import React from 'react';
import { H3 } from '../Heading/Heading';

import css from './BookingContract.module.css';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const BookingContract = props => {
  const { className } = props;

  const intl = useIntl();

  const desc = intl.formatMessage({ id: 'BookingContract.desc' });

  return (
    <div className={classNames(css.root, className)}>
      <H3 as="h2">
        {desc}
        <hr />
      </H3>

      <ul className={css.discList}>
        <li>
          <div>
            This Booking Contract applies to you if you are a Client or an Artist making or
            accepting a booking via the Music Marmalade Website.
          </div>
        </li>
        <li>
          <div>
            This Booking Contract does not in any way bind Music Marmalade and can be varied by the
            Client and Artist by written agreement.
          </div>
        </li>
        <li>
          <div>
            The purpose of this Booking Contract is to govern the relationship between the Client
            and the Artist for an Event. The Booking Contract will be supplemented by any details
            communicated between the Client and the Artist via the Music Marmalade Website in
            relation to the Event.
          </div>
        </li>
        <li>
          <div>
            Defined terms set out in this Booking Contract are derived from the Client Terms and
            Artist Terms.
          </div>
        </li>
        <li className={css.itemNestedList}>
          <div>If you are a Client, the following terms also apply to you:</div>
          <ul className={css.dashList}>
            <li>
              <div>Website Terms of Use</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
            <li>
              <div>Music Marmalade Client Terms</div>
            </li>
          </ul>
        </li>
        <li className={css.itemNestedList}>
          <div>If you are an Artist, the following additional terms also apply to you:</div>
          <ul className={css.dashList}>
            <li>
              <div>Website Terms of Use</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
            <li>
              <div>Music Marmalade Artist Terms</div>
            </li>
          </ul>
        </li>
      </ul>
      <ol className={css.orderList}>
        <li>
          <H3 as="h2">The Event</H3>
          <hr />

          <ol className={css.nestedOrderList}>
            <li>
              <div>
                The Event will be documented in the Confirmation and the total performance time is
                the total of the full performance time, plus the break, plus any set up and take
                down time. If the Client requires the Artist to attend the Event for longer than
                anticipated, this must be agreed in writing between the Client and the Artist and
                the Client accepts that it will be liable directly to the Artist for additional
                fees.
              </div>
            </li>
            <li>
              <div>
                Details of the Event may be varied by the Client and/or Artist if agreed in writing
                by both parties using the Music Marmalade messaging function within your profile.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Communication Between Client and Artist</H3>
          <hr />

          <ol className={css.nestedOrderList}>
            <li>
              <div>
                All communications between the Client and Artist should take place via the Music
                Marmalade messaging function within your profile.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Artist Responsibilities</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li className={css.itemNestedList}>
              <div>
                The Artist agrees that it will adhere strictly to the following Artist Code of
                Conduct:
              </div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <div>
                    The Artist shall perform the Event to the best of their ability and in a manner
                    that reflects the Artist Profile that the Client viewed prior to the Event and
                    shall conduct themselves in a manner that is professional and appropriate at all
                    times.
                  </div>
                </li>
                <li>
                  <div>
                    The Artist will comply at all times with the Booking Contract including any
                    subsequent changes that have been agreed with the Client in advance of the
                    Event.
                  </div>
                </li>
                <li>
                  <div>
                    The Artist will arrive at the Event in a timely manner and will conduct
                    themselves in a professional manner throughout the Event, including during
                    set-up and take-down.
                  </div>
                </li>
                <li>
                  <div>
                    The Artist will ensure it has with it all necessary equipment and instruments at
                    the Event and will dress in an appropriate manner for the Event.
                  </div>
                </li>
                <li>
                  <div>
                    The Artist will not engage in any form of anti-social behaviour, or behaviour
                    which could be seen to bring Music Marmalade, the Client, or the Event venue
                    into disrepute.
                  </div>
                </li>
                <li>
                  <div>
                    The Artist will not smoke, drink alcohol excessively or take drugs, before or
                    during an Event.
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Client Responsibilities</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                The Client will comply at all times with the Booking Contract including any
                subsequent changes that have been agreed with the Artist in advance of the Event.
              </div>
            </li>
            <li className={css.itemNestedList}>
              <div>The Client will ensure the venue for the Event:</div>
              <ol className={classNames(css.nestedOrderList, css.secondNestedOrderList)}>
                <li>
                  <div>is suitable and safe for the Artist to perform;</div>
                </li>
                <li>
                  <div>
                    is fully available during the Event times (including any set up and take down
                    times) for the Artist, failure to do so may delay the performance start time and
                    the Artist will not be required to perform late in order to make up any lost
                    time;
                  </div>
                </li>
                <li>
                  <div>has sufficient electrical power and internet connection (if required);</div>
                </li>
                <li>
                  <div>has the relevant licenses (if any) in place for the Artist to perform.</div>
                </li>
              </ol>
            </li>
            <li>
              <div>
                The Client must ensure that the Artist is provided with a safe, secure and lockable
                area to store equipment.
              </div>
            </li>
            <li>
              <div>
                The Client should ensure the Artist is provided with a free unlimited supply of
                drinking water and a hot meal or buffet.
              </div>
            </li>
            <li>
              <div>
                The Client will ensure that nobody uses or interferes with the Artist's equipment or
                instruments before, during or after the Event unless explicit consent has been
                provided by the Artist.
              </div>
            </li>
            <li>
              <div>
                The Client will inform the Artist of any noise limiters in the venue and they will
                accept that the Artist's performance may be affected by this. To avoid any doubt, if
                an Artist's performance is detrimentally affected by any noise limiter, or any other
                issue affecting the venue, the Client will still be liable for the full Artist Fee.
              </div>
            </li>
            <li>
              <div>
                The Client will not engage in any form of anti-social behaviour, or behaviour which
                could be seen to bring Music Marmalade, the Artist, or the Event venue into
                disrepute.
              </div>
            </li>
            <li>
              <div>
                The Client must meet all requirements of this clause 4 at its own expense. Any
                variations must be agreed in writing between the Client and the Artist within the
                Music Marmalade message function.
              </div>
            </li>
            <li>
              <div>
                It is expected that the Client will liaise with the Event venue in order to ensure
                that the requirements set out above are achievable. If the Client does not do so and
                the Artist is unable to perform as expected, the Artist may cancel the Event and the
                Client will be responsible for paying all Fees.
              </div>
            </li>
            <li>
              <div>
                In the event that the Client breaches, or allows to be breached, any of the
                requirements of this clause 4, the Artist shall have the right to terminate this
                Booking Contract and cancel the Event and the Client shall remain liable for the
                Fees.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Use of Alternative Performers</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                The Artist must use best endeavours to provide the line-up as represented to the
                Client on the Artist Profile. The Artist may only substitute any members of the
                line-up in extreme and unforeseen circumstances (<b>Substitute Members</b>).
              </div>
            </li>
            <li>
              <div>
                The Substitute Members must be sufficiently rehearsed in order to perform to a high
                standard.
              </div>
            </li>
            <li>
              <div>
                The attendance of Substitute Members is not a sufficient reason for the Client to
                cancel the Event and where Substitute Members are used the Client will remain liable
                for the Fee.
              </div>
            </li>
            <li>
              <div>
                Artist line-ups may be subject to change and this may occur without notice, unless
                the individual is of sufficient celebrity such that their non-attendance would mean
                the Event is significantly affected by their absence.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Performance Schedule and Alterations to the Booking Contract</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                The performance schedule shall be as agreed within the Confirmation, and/or any
                subsequent communications between the Client and Artist where such changes have been
                mutually agreed.
              </div>
            </li>
            <li>
              <div>
                If an Event is running late due to events outside of the Artist's control, the
                Artist is under no obligation to extend the performance time or run late with the
                performance.
              </div>
            </li>
            <li>
              <div>
                Under no circumstances shall an Artist cut short their break as set out in their
                Artist Profile.
              </div>
            </li>
            <li>
              <div>
                Any alterations to the Booking Contract must be agreed in writing between the Client
                and the Artist within the Music Marmalade messaging function.
              </div>
            </li>
            <li>
              <div>
                Changes to the Booking Contract may result in additional charges being payable by
                the Client to the Artist.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Cancellation of an Event by Artist</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                Once an Artist and Client have entered into a Booking Contract, the Artist must make
                every effort to perform the Event.
              </div>
            </li>
            <li>
              <div>
                The Artist has the right to terminate the Booking Contract in the event that the
                Client fails to make payment of Fees when due.
              </div>
            </li>
            <li>
              <div>
                In the event that an Artist cancels an Event, except where such cancellation is the
                result of non- payment by the Client, it must make every effort to source a suitable
                substitute artist to cover the Event (<b>Substitute Artist</b>) and the Artist will
                be liable for the fees charged by such substitute and any uplift in fees that the
                substitute may charge to the Client, up to a total of 110% of the original Fee.
              </div>
            </li>
            <li>
              <div>
                The Substitute Artist must be of equivalent standard to the Artist and the Artist
                will be responsible for the Substitute Artist's compliance with the terms of this
                Booking Contract.
              </div>
            </li>
            <li>
              <div>
                If the Artist is unable to source a Substitute Artist or the Client reasonably
                rejects the Substitute Artist, the Artist must refund the Client the full Fee.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Artist Reviews</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                Clients are able to leave reviews for Artists they have booked via the Website, this
                is an important element of building the profile of an Artist and supports an Artist
                in securing future Bookings.
              </div>
            </li>
            <li>
              <div>
                Reviews must only be left for an Artist by Clients that have booked an Artist via
                the Website, any reviews which break this rule may be removed without notice.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Complaints and Queries</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                Any disputes between thew Client and the Artist should be resolved directly between
                the Client and the Artist.
              </div>
            </li>
            <li>
              <div>
                Music Marmalade is not liable for any obligations of the Client or the Artist.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Force Majeure</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                No Party will be liable for their failure to fulfil their obligations if such
                failure is caused by a Force Majeure Event. A Force Majeure Event is defined as a
                natural disaster (including but not limited to; storm, earthquake, hurricane, flood,
                etc.), epidemic, pandemic, civil unrest, war, terrorism, death, illness certified by
                a medical profession, accident, or any directives issued by a government or local
                authority which prevents a party from performing their obligations to the other
                party.
              </div>
            </li>
            <li>
              <div>
                In respect of death and/or sickness, in order for a party to claim a Force Majeure
                Event has occurred the affected party must be a named party to this Agreement or the
                Booking Contract (or their spouse where the affected party is a Client and the Event
                is a wedding).
              </div>
            </li>
            <li>
              <div>
                If a Party needs to rely on this clause and claim that a Force Majeure Event has
                occurred, they must inform the other party promptly. Any party seeking to claim that
                a Force Majeure Event has taken place must provide sufficient evidence to the other
                party and demonstrate that they took reasonable steps to mitigate the effects of the
                Force Majeure Event.
              </div>
            </li>
            <li className={css.itemNestedList}>
              <div>
                If the Event is cancelled due to a Force Majeure Event despite the Client being
                offered a viable alternative, the following will apply:
              </div>

              <table cellSpacing={0}>
                <thead>
                  <tr>
                    <th>
                      <div>Time of Cancellation</div>
                    </th>
                    <th>
                      <div>Cancellation Payment Due</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>Within 48 hours of submitting</div>
                      <div>your Booking Request</div>
                    </td>
                    <td>
                      <div>No payment will be due.</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>More than 182 days before the</div>
                      <div>Event</div>
                    </td>
                    <td>
                      <div>No additional payment will be due, but the Reservation Fee will</div>
                      <div>not be refunded.</div>
                    </td>
                  </tr>
                  <tr style={{ height: '23pt' }}>
                    <td>
                      <div>92 to 181 days before the Event</div>
                    </td>
                    <td>
                      <div>50% of Fee is due.</div>
                    </td>
                  </tr>
                  <tr style={{ height: '23pt' }}>
                    <td>
                      <div>31 to 91 days before the Event</div>
                    </td>
                    <td>
                      <div>75% of Fee is due.</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>30 or less days before the</div>
                      <div>event</div>
                    </td>
                    <td>
                      <div>100% of Fee is due.</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ol>
        </li>
        <li>
          <H3 as="h2">Law and Jurisdiction</H3>
          <hr />
          <ol className={css.nestedOrderList}>
            <li>
              <div>
                The laws of England and Wales apply to these terms. Any disputes will be subject to
                the exclusive jurisdiction of the courts of England and Wales, unless you are a
                consumer and live in either Northern Ireland or Scotland. In which case, you can
                choose to bring a claim in England and Wales or in the courts of another part of the
                UK in which you live.
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default BookingContract;
