import React from 'react';

const IconSort = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57834 6.9082L3.33668 7.15819V0.833317C3.33668 0.373089 2.96359 0 2.50336 0C2.04313 0 1.67004 0.373089 1.67004 0.833317V7.15819L1.42838 6.9082C1.10162 6.58144 0.571834 6.58144 0.245072 6.9082C-0.0816905 7.23496 -0.0816905 7.76475 0.245072 8.09151L1.91171 9.75814C1.99096 9.83401 2.08441 9.89348 2.1867 9.93314C2.3884 10.0223 2.61833 10.0223 2.82002 9.93314C2.92231 9.89348 3.01576 9.83401 3.09502 9.75814L4.76165 8.09151C4.97303 7.88013 5.05558 7.57204 4.97821 7.28329C4.90084 6.99455 4.6753 6.76901 4.38656 6.69164C4.09781 6.61427 3.78972 6.69682 3.57834 6.9082ZM7.50399 1.66663H15.8372C16.2974 1.66663 16.6705 1.29355 16.6705 0.833317C16.6705 0.373089 16.2974 0 15.8372 0H7.50399C7.04376 0 6.67068 0.373089 6.67068 0.833317C6.67068 1.29355 7.04376 1.66663 7.50399 1.66663ZM15.8372 4.1665H7.50399C7.04376 4.1665 6.67068 4.53959 6.67068 4.99982C6.67068 5.46005 7.04376 5.83314 7.50399 5.83314H15.8372C16.2974 5.83314 16.6705 5.46005 16.6705 4.99982C16.6705 4.53959 16.2974 4.1665 15.8372 4.1665ZM15.8372 8.33318H7.50399C7.04376 8.33318 6.67068 8.70627 6.67068 9.1665C6.67068 9.62673 7.04376 9.99982 7.50399 9.99982H15.8372C16.2974 9.99982 16.6705 9.62673 16.6705 9.1665C16.6705 8.70627 16.2974 8.33318 15.8372 8.33318Z"
        fill="#EE2677"
      />
    </svg>
  );
};

export default IconSort;
