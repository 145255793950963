import React from 'react';

const IconPlay = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7916 5.99888L5.1307 0.45836C4.06652 -0.156145 2.75448 -0.152541 1.6937 0.4678C0.632918 1.08814 -0.0135523 2.22986 0.000215605 3.45864V14.5797C0.000215605 16.4687 1.53154 18 3.42054 18C4.02103 17.999 4.61073 17.8403 5.1307 17.54L14.7916 11.9994C15.8481 11.388 16.4986 10.2598 16.4986 9.03916C16.4986 7.81849 15.8481 6.69034 14.7916 6.07889V5.99888ZM13.7914 10.1893L4.13053 15.8098C3.69006 16.0594 3.15087 16.0594 2.7104 15.8098C2.27093 15.5561 2.00024 15.0871 2.00033 14.5797V3.41863C2.00024 2.91118 2.27093 2.44224 2.7104 2.18851C2.92782 2.06766 3.17174 2.00239 3.42046 1.9985C3.66902 2.0036 3.91265 2.06879 4.13053 2.18851L13.7914 7.76904C14.2311 8.02265 14.5019 8.49161 14.5019 8.99915C14.5019 9.50669 14.2311 9.97565 13.7914 10.2293V10.1893Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPlay;
