/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_UNIT_TYPES,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_LAYOVER,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemDeposit from './LineItemDeposit';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemAddOnsMaybe from './LineItemAddOnsMaybe';
import LineItemDistance from './LineItemDistance';
import LineItemFullPrice from './LineItemFullPrice';
import { parseProtectedDataLineItems } from '../../util/booking';
import LineItemLayoverMaybe from './LineItemLayoverMaybe';
import LocationMaybe from './LocationMaybe';
import EventTypeMaybe from './EventTypeMaybe';
import { transitions } from '../../transactions/transactionProcessBooking';
import LineItemAddonTravelExpensesMaybe from './LineItemAddonsTravelExpensesMaybe';
import NamedLink from '../NamedLink/NamedLink';
import LineItemEarningFromReservationMaybe from './LineItemEarningFromReservationMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';

import css from './OrderBreakdown.module.css';

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    transaction,
    intl,
    vatRegistered,
    currency,
    marketplaceName,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const { txLineItems: pLineItems, addOns } = transaction.attributes.protectedData || {};

  const depositLineItems = transaction.attributes.lineItems || [];

  const txLineItems = parseProtectedDataLineItems(pLineItems, currency);

  const allLineItems = [...(txLineItems || []), ...depositLineItems];

  // We'll show only line-items that are specific for the current userRole (customer vs provider)
  const lineItems = allLineItems.filter(lineItem => lineItem.includeFor.includes(userRole));
  const unitLineItem = lineItems.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  // Line-item code that matches with base unit: day, night, hour, item
  const lineItemUnitType = unitLineItem?.code;

  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const isRefunded = transaction?.attributes?.payinTotal?.amount === 0;

  const hasLayoverLineItem = !!lineItems.find(item => item.code === LINE_ITEM_LAYOVER);

  const hasLayoverNote = hasLayoverLineItem && isCustomer;
  const hasComissionNote = hasCommissionLineItem;
  const hasDepositNote =
    isCustomer &&
    !(
      transaction?.attributes?.payinTotal?.amount === 0 ||
      transaction?.attributes?.transitions?.find(
        transition => transition.transition === transitions.ACCEPT
      )
    );

  const comissionNoteAterisks = '*';
  const depositNoteAterisks = '*' + ((hasComissionNote && '*') || '');

  const totalPriceAsterisks =
    '*' + ((hasComissionNote && '*') || '') + ((hasDepositNote && '*') || '');

  const classes = classNames(rootClassName || css.root, className);

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by this template doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemDeposit: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod
        dateOfEvent={transaction?.attributes?.metadata?.dateOfEvent}
        code={lineItemUnitType}
        timeZone={transaction?.listing?.attributes?.availabilityPlan?.timeZone}
      />
      <EventTypeMaybe eventType={transaction?.attributes?.protectedData?.eventType} />
      <LocationMaybe
        location={transaction?.attributes?.protectedData?.location}
        geolocation={transaction?.attributes?.protectedData?.location?.selectedPlace?.origin}
      />

      <LineItemBasePriceMaybe lineItems={lineItems} code={lineItemUnitType} intl={intl} />
      <LineItemDistance lineItems={lineItems} intl={intl} />
      <LineItemAddOnsMaybe addOns={addOns} lineItems={lineItems} intl={intl} />
      <LineItemAddonTravelExpensesMaybe lineItems={lineItems} intl={intl} />

      <LineItemLayoverMaybe lineItems={lineItems} intl={intl} isCustomer={isCustomer} />

      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      <LineItemCustomerCommissionMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
        asterisks={comissionNoteAterisks}
      />
      <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        marketplaceName={marketplaceName}
        intl={intl}
      />
      <LineItemProviderCommissionMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
        asterisks={comissionNoteAterisks}
      />
      <LineItemEarningFromReservationMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        marketplaceName={marketplaceName}
        intl={intl}
        marketplaceCurrency={currency}
        txLineItems={txLineItems}
        depositLineItems={depositLineItems}
      />
      {isCustomer && (
        <LineItemSubTotalMaybe
          txLineItems={txLineItems}
          depositLineItems={depositLineItems}
          isCustomer={isCustomer}
          intl={intl}
          marketplaceCurrency={currency}
          showHr
        />
      )}

      {isCustomer && (
        <LineItemDeposit
          transaction={transaction}
          isProvider={isProvider}
          intl={intl}
          asterisks={hasDepositNote ? depositNoteAterisks : ''}
          lineItems={lineItems}
          isRefunded={isRefunded}
          marketplaceCurrency={currency}
        />
      )}

      {isCustomer && isRefunded && (
        <LineItemSubTotalMaybe
          txLineItems={txLineItems}
          depositLineItems={depositLineItems}
          isCustomer={isCustomer}
          intl={intl}
          marketplaceCurrency={currency}
          isRefund
          showHr
        />
      )}
      {isCustomer && (
        <LineItemRefundMaybe lineItems={lineItems} intl={intl} marketplaceCurrency={currency} />
      )}

      <LineItemFullPrice
        txLineItems={txLineItems}
        depositLineItems={depositLineItems}
        isProvider={isProvider}
        intl={intl}
        currency={currency}
        transaction={transaction}
        refunded={isRefunded && isCustomer}
        asterisks={hasLayoverLineItem ? totalPriceAsterisks : ''}
        vatRegistered={vatRegistered}
      />

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          {comissionNoteAterisks}
          &nbsp;
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null}

      {isCustomer && hasDepositNote && (
        <span className={css.feeInfo}>
          {depositNoteAterisks}
          &nbsp;
          <FormattedMessage
            id="OrderBreakdown.depositNote"
            values={{
              link: (
                <NamedLink target="_blank" name="HowItWorksPage" to={{ hash: 'payments' }}>
                  <FormattedMessage id="General.policy" />
                </NamedLink>
              ),
            }}
          />
        </span>
      )}
      {isCustomer && hasLayoverNote && (
        <span className={css.feeInfo}>
          {totalPriceAsterisks}
          &nbsp;
          <FormattedMessage
            id="OrderBreakdown.totalPriceNote"
            values={{
              link: (
                <NamedLink target="_blank" name="HowItWorksPage" to={{ hash: 'quote_includes' }}>
                  <FormattedMessage id="General.here" />
                </NamedLink>
              ),
            }}
          />
        </span>
      )}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

OrderBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  userRole: oneOf(['customer', 'provider']).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
