import React from 'react';
import { Field } from 'react-final-form';
import Dropdown from './Dropdown';
import ValidationError from '../ValidationError/ValidationError';
import { arrayOf, node, number, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import css from './FieldDropdown.module.css';

const FieldDropdownComponent = props => {
  const {
    input,
    id,
    label,
    options,
    meta,
    placeholder,
    className,
    asideFromLabel,
    maxLength,
    searchable,
    ...rest
  } = props;
  const { invalid, touched, error } = meta;
  const { value, onChange, ...restInputProps } = input;

  const hasError = !!(touched && invalid && error);

  const handleOnChangeValue = value => {
    //if 'all' option gets selected remove all other selected values
    if (value?.includes('all')) return onChange(['all']);
    onChange(value);
  };

  return (
    <div className={className}>
      {label ? (
        <div className={css.labelWrapper}>
          <label htmlFor={id}>{label}</label> {asideFromLabel}
        </div>
      ) : null}
      <Dropdown
        id={id}
        options={options}
        placeholder={placeholder}
        {...restInputProps}
        value={!value ? [] : value}
        onChange={handleOnChangeValue}
        dropdownInputClassName={classNames({ [css.inputError]: hasError })}
        disableRest={value?.length === maxLength || value?.includes('all')}
        searchable={searchable}
        {...rest}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldDropdownComponent.propTypes = {
  maxLength: number,
  label: node,
  id: string,
  className: string,
  input: object.isRequired,
  meta: object.isRequired,
  asideFromLabel: node,
  options: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired,
      searchString: string,
      value: oneOfType([string, arrayOf(string)]).isRequired,
      icon: node,
    })
  ),
};

const FieldDropdown = props => {
  const { ...rest } = props;

  return <Field component={FieldDropdownComponent} {...rest} />;
};

export default FieldDropdown;
