import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9557 6.04621C16.8444 5.70813 16.539 5.46802 16.1778 5.43605L11.2712 4.99849L9.33094 0.538446C9.18788 0.211582 8.86207 0 8.50007 0C8.13807 0 7.81225 0.211582 7.66919 0.53921L5.72897 4.99849L0.821535 5.43605C0.460961 5.46878 0.156419 5.70813 0.0444859 6.04621C-0.0674476 6.38428 0.0359255 6.75509 0.30869 6.98884L4.01754 10.1833L2.92389 14.9147C2.84386 15.2626 2.98135 15.6222 3.27525 15.8308C3.43323 15.9429 3.61806 16 3.80444 16C3.96514 16 4.12455 15.9575 4.26761 15.8734L8.50007 13.389L12.731 15.8734C13.0406 16.0563 13.4308 16.0396 13.7241 15.8308C14.0181 15.6215 14.1555 15.2618 14.0755 14.9147L12.9818 10.1833L16.6907 6.98947C16.9634 6.75509 17.0676 6.38492 16.9557 6.04621Z"
        fill="currentColor"
      />
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
