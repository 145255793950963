import React from 'react';

import css from './OrderBreakdown.module.css';
import { FormattedMessage } from 'react-intl';
import AddressLinkMaybe from '../../containers/TransactionPage/TransactionPanel/AddressLinkMaybe';

const LocationMaybe = props => {
  const { location, geolocation } = props;

  if (!geolocation) return null;

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="General.venueName" />
        </span>
        <span className={css.itemValue}>{location?.selectedPlace?.name}</span>
      </div>
      {location?.selectedPlace?.line1 && (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="General.streetName" />
          </span>
          <span className={css.itemValue}>{location?.selectedPlace?.line1}</span>
        </div>
      )}
      {location?.selectedPlace?.city && (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="General.city" />
          </span>
          <span className={css.itemValue}>{location?.selectedPlace?.city}</span>
        </div>
      )}

      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="General.postCode" />
        </span>
        <span className={css.itemValue}>{location?.selectedPlace?.postalCode}</span>
      </div>
      <span className={css.itemValue}>
        <AddressLinkMaybe
          linkRootClassName={css.bookingLocationAddress}
          location={location?.selectedPlace}
          showAddress={true}
          geolocation={geolocation}
          dontShowAddress
        />
      </span>
      <hr className={css.totalDivider} />
    </>
  );
};

export default LocationMaybe;
