import React from 'react';
import { FieldLocationAutocompleteInput } from '../LocationAutocompleteInput/LocationAutocompleteInput';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from '../../util/validators';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import { debounce } from 'lodash';

import css from './FieldHomeLocation.module.css';
import classNames from 'classnames';

const handleHomeLocationChanged = (location, formRef) => {
  const { selectedPlace } = location || {};

  if (!selectedPlace) return;

  formRef?.change('city', selectedPlace?.city);
  formRef?.change('location', { selectedPlace, search: selectedPlace?.postalCode });

  debounce(() => {
    formRef?.getFieldState('location').focus();
    formRef?.getFieldState('location').blur();
  }, 300)();
};

const FieldHomeLocation = props => {
  const { intl, formId, fieldClassName, formRef } = props;

  return (
    <div className={css.root}>
      <FieldLocationAutocompleteInput
        rootClassName={fieldClassName}
        inputClassName={classNames(css.input, css.locationInput)}
        iconClassName={css.locationIcon}
        id={formId ? `${formId}.address` : 'address'}
        name="address"
        autoComplete="address"
        label={intl.formatMessage({
          id: 'General.streetAddress',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.addressPlaceholder',
        })}
        types={['street_address']}
        onChange={value => handleHomeLocationChanged(value, formRef)}
        format={value => {
          if (!value) return value;
          const { selectedPlace } = value || {};
          if (!selectedPlace) return value;
          return { ...value, search: selectedPlace?.line1 };
        }}
      />
      <FieldTextInput
        className={fieldClassName}
        inputRootClass={classNames(css.input, css.venueName)}
        name="city"
        id="city"
        label={intl.formatMessage({
          id: 'General.city',
        })}
        placeholder={intl.formatMessage({ id: 'General.cityPlaceholder' })}
      />
      <FieldLocationAutocompleteInput
        rootClassName={fieldClassName}
        inputClassName={classNames(css.input, css.locationInput)}
        iconClassName={css.locationIcon}
        format={value => {
          if (!value) return value;
          const { selectedPlace } = value || {};
          if (!selectedPlace) return value;
          return { ...value, search: selectedPlace?.postalCode };
        }}
        id="location"
        name="location"
        label={intl.formatMessage({
          id: 'General.postCode',
        })}
        placeholder={intl.formatMessage({
          id: 'General.postCodePlaceholder',
        })}
        validate={composeValidators(
          autocompleteSearchRequired(
            intl.formatMessage({
              id: 'EditListingLocationForm.addressRequired',
            })
          ),
          autocompletePlaceSelected(
            intl.formatMessage({
              id: 'EditListingLocationForm.addressNotRecognized',
            })
          )
        )}
        types={['postal_code']}
        usePredictionAddress
      />
    </div>
  );
};

export default FieldHomeLocation;
