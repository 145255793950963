import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl } = props;

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {intl.formatMessage({ id: 'General.coreServicePrice' })}
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
