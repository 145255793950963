import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BigCalendar } from '..';
import {
  addTime,
  getMomentFromDate,
  getMonthStartInTimeZone,
  nextMonthFn,
  parseDateFromISO8601,
  resetToStartOfDay,
  stringifyDateToISO8601,
  subtractTime,
} from '../../util/dates';
import { createEventFromBooking } from '../../util/booking';
import {
  getAvailabilityForListing,
  getBookings,
  getExceptions,
} from '../../ducks/availability.duck';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';

const TODAY = new Date();
const START_OF_MONTH = getMonthStartInTimeZone(TODAY);
const END_OF_MONTH = nextMonthFn(START_OF_MONTH);

// When we get to the page, the first view is the month view
// so we want to fetch this range [startOfMonth - 7 days, endOfMonth + 7 days].
// +- 7 days, because we see days from other months
const INITIAL_START_PARAM = subtractTime(START_OF_MONTH, 7, 'days');
const INITIAL_END_PARAM = addTime(END_OF_MONTH, 7, 'days');

const parseAvailability = (availability, exceptions) => {
  const parsedEntries = availability?.reduce(
    (dates, entry) => {
      const { date, available } = entry;

      const dateString = date.slice(0, 10);

      const key = available ? 'availableDates' : 'notAvailableDates';

      dates[key][dateString] = true;

      return dates;
    },
    {
      availableDates: {},
      notAvailableDates: {},
    }
  );

  const parsedExceptions = exceptions?.reduce(
    (dates, exception) => {
      const { _id, start, end, seats } = exception;

      const isAvailable = seats === 1;

      const key = isAvailable ? 'availableDates' : 'notAvailableDates';

      let date = parseDateFromISO8601(start);
      const endDate = parseDateFromISO8601(end);

      while (date <= endDate) {
        const dateString = stringifyDateToISO8601(date);

        dates[key][dateString] = _id;

        // Remove opposite availability entry if exception overrides it.
        // (e.g. 'not available' day now becomes 'available' because of exception)
        const entryToRemove = isAvailable ? 'notAvailableDates' : 'availableDates';
        parsedEntries[entryToRemove][dateString] = null;

        date = getMomentFromDate(date)
          .add(1, 'days')
          .toDate();
      }

      return dates;
    },
    {
      availableDates: {},
      notAvailableDates: {},
    }
  );

  return {
    availableDates: {
      ...parsedEntries?.availableDates,
      ...parsedExceptions?.availableDates,
    },
    notAvailableDates: {
      ...parsedEntries?.notAvailableDates,
      ...parsedExceptions?.notAvailableDates,
    },
  };
};

const BookingsCalendar = props => {
  const { listingId, className, onClickCell, onDatesChange } = props;

  const intl = useIntl();

  const dispatch = useDispatch();
  const {
    availability,
    getAvailabilityError,
    getAvailabilityInProgress,
    exceptions,
    getExceptionsInProgress,

    bookings,
    getBookingsInProgress,
    getBookingsError,
  } = useSelector(state => state.availability);

  const calendarEvents = useMemo(
    () => bookings?.map(booking => createEventFromBooking(intl, booking)),
    [bookings, intl]
  );

  const { availableDates, notAvailableDates } = useMemo(
    () => parseAvailability(availability, exceptions),
    [availability, exceptions]
  );

  const fetchEventsInProgress =
    getBookingsInProgress || getExceptionsInProgress || getAvailabilityInProgress;

  const fetchEventsError = useMemo(() => getBookingsError || getAvailabilityError, [
    getAvailabilityError,
    getBookingsError,
  ]);

  const handleRangeChange = useCallback(
    datesRange => {
      const { start, end } = datesRange;

      const dayAfterEnd = resetToStartOfDay(end, null, 1);

      const params = {
        startsAt: stringifyDateToISO8601(start),
        endsAt: stringifyDateToISO8601(dayAfterEnd),
      };

      dispatch(getAvailabilityForListing(listingId, params));
      dispatch(getExceptions(listingId, params));
      dispatch(getBookings(start, dayAfterEnd));

      onDatesChange?.(params);
    },
    [dispatch, listingId, onDatesChange]
  );

  useEffect(() => {
    if (!listingId) return;

    const params = {
      startsAt: stringifyDateToISO8601(INITIAL_START_PARAM),
      endsAt: stringifyDateToISO8601(INITIAL_END_PARAM),
    };

    dispatch(getAvailabilityForListing(listingId, params));
    dispatch(getExceptions(listingId, params));

    dispatch(getBookings(INITIAL_START_PARAM, INITIAL_END_PARAM));
  }, [dispatch, listingId]);

  return (
    <BigCalendar
      className={className}
      events={calendarEvents}
      onRangeChange={handleRangeChange}
      fetchEventsInProgress={fetchEventsInProgress}
      fetchEventsError={fetchEventsError}
      availableDates={availableDates}
      notAvailableDates={notAvailableDates}
      onClickCell={onClickCell}
    />
  );
};

BookingsCalendar.propTypes = {
  listingId: string,
};

export default BookingsCalendar;
