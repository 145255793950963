import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADD_ON, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemAddOnsMaybe = props => {
  const { lineItems, addOns, intl } = props;

  if (!addOns?.length) return null;

  const addOnLineItems = lineItems.filter(
    item => item.code.startsWith(LINE_ITEM_ADD_ON) && !item.reversal
  );

  if (!addOnLineItems?.length) return null;

  return (
    <>
      <hr className={css.totalDivider} />
      {addOnLineItems.map(lineItem => {
        const splitLineItemCode = lineItem.code.split('-');

        const addOnIdFromLineItem = splitLineItemCode[splitLineItemCode.length - 1];

        const addOn = addOns.find(a => a._id === addOnIdFromLineItem);

        return (
          <div key={lineItem.code} className={css.lineItem}>
            <span className={css.itemLabel}>{addOn?.title}</span>
            <span className={css.itemValue}>{formatMoney(intl, lineItem.lineTotal)}</span>
          </div>
        );
      })}
    </>
  );
};

LineItemAddOnsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAddOnsMaybe;
