import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { getUserRole } from '../../ducks/user.duck';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { USER_ROLE } from '../../constants/user';

const UserNavComponent = props => {
  const { className, rootClassName, currentPage, currentUserRole } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="General.yourBookings" />,
      selected: currentPage === 'ManageBookingsPage',
      linkProps: {
        name: 'ManageBookingsPage',
        ...(currentUserRole === USER_ROLE.ARTIST && { to: { search: `?state=pending` } }),
      },
    },
    ...(currentUserRole === USER_ROLE.USER
      ? [
          {
            text: <FormattedMessage id="UserNav.profileSettings" />,
            selected: currentPage === 'ProfileSettingsPage',
            disabled: false,
            linkProps: {
              name: 'ProfileSettingsPage',
            },
          },
        ]
      : []),
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const currentUserRole = getUserRole(state);

  return { currentUserRole };
};

const UserNav = compose(
  connect(mapStateToProps),
  injectIntl
)(UserNavComponent);

export default UserNav;
