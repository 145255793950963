import { denormalizeAssetData } from '../../util/data';

// ================ Action types ================ //

const GET_BLOGS_REQUEST = 'app/BlogsPage/GET_BLOGS_REQUEST';
const GET_BLOGS_SUCCESS = 'app/BlogsPage/GET_BLOGS_SUCCESS';
const GET_BLOGS_ERROR = 'app/BlogsPage/GET_BLOGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  blogs: [],
  blogsLoaded: false,
  getBlogsInProgress: false,
  getBlogsError: null,
};

const blogsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BLOGS_REQUEST:
      return {
        ...state,
        getBlogsInProgress: true,
        getBlogsError: null,
      };
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: payload.blogs,
        getBlogsInProgress: false,
        blogsLoaded: true,
        loadedBlogsRole: payload.role,
      };
    case GET_BLOGS_ERROR:
      return { ...state, getBlogsInProgress: false, getBlogsError: payload };
    default:
      return state;
  }
};

export default blogsPageReducer;

// ================ Action creators ================ //

export const getBlogsRequest = () => ({
  type: GET_BLOGS_REQUEST,
});

export const getBlogsSuccess = response => ({
  type: GET_BLOGS_SUCCESS,
  payload: response,
});

export const getBlogsError = e => ({
  type: GET_BLOGS_ERROR,
  error: true,
  payload: e,
});

const getContentForAssets = async (sdk, assetPaths) => {
  try {
    const res = await sdk.assetsByAlias({
      paths: assetPaths,
      alias: 'latest',
    });

    return res.data.data.map(asset => {
      const data = denormalizeAssetData({ data: asset.attributes, included: res.data.included });

      return data;
    });
  } catch (error) {
    throw error;
  }
};

export const getBlogs = blogRole => (dispatch, getState, sdk) => {
  dispatch(getBlogsRequest());

  const pathToCheck = [];

  switch (blogRole) {
    case 'artist':
      pathToCheck.push('blogs-artist-');

      break;

    case 'client':
      pathToCheck.push('blogs-client-');

      break;

    default:
      pathToCheck.push('blogs-client-');
      pathToCheck.push('blogs-artist-');

      break;
  }

  return sdk.sitemapData
    .queryAssets({ pathPrefix: '/content/pages/' })
    .then(async response => {
      const assets = response.data.data || [];

      const clientBlogAssets = assets.filter(asset =>
        pathToCheck.some(path => asset.attributes?.assetPath?.startsWith(`/content/pages/${path}`))
      );

      let clientBlogs = [];

      if (clientBlogAssets?.length) {
        clientBlogs = await getContentForAssets(
          sdk,
          clientBlogAssets.map(asset => asset.attributes?.assetPath)
        );
      }

      dispatch(getBlogsSuccess({ blogs: clientBlogs, role: blogRole }));
    })
    .catch(e => {
      dispatch(getBlogsError(e));
      throw e;
    });
};

export const loadData = (params, query, config, pathname) => (dispatch, getState, sdk) => {
  const isArtistBlog = pathname.includes('/artist');
  const isClientBlog = pathname.includes('/client');

  const role = isArtistBlog ? 'artist' : isClientBlog ? 'client' : null;

  const state = getState();

  if (state.BlogsPage.blogsLoaded && role === state.BlogsPage.loadedBlogsRole)
    return Promise.resolve();

  return dispatch(getBlogs(role));
};
