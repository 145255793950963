import React, { useMemo } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { getMomentFromDate, stringifyDateToISO8601 } from '../../util/dates';

import css from './CalendarDateCellWrapper.module.css';

const TODAY = new Date();

const CalendarDateCellWrapper = props => {
  const {
    label,
    date,
    datesWithEvents = {},
    availableDates = {},
    notAvailableDates = {},
    onClickCell,
  } = props;

  const isDateBeforeToday = useMemo(() => getMomentFromDate(TODAY).isAfter(date, 'day'), [date]);

  const dateString = useMemo(() => stringifyDateToISO8601(date), [date]);

  const hasBooking = datesWithEvents[dateString];

  const hasSession = availableDates[dateString];

  const isUnavailable = notAvailableDates[dateString];

  const isException =
    typeof availableDates[dateString] === 'string' ||
    typeof notAvailableDates[dateString] === 'string';

  const exceptionIdMaybe =
    isException && (availableDates[dateString] || notAvailableDates[dateString]);

  return (
    <div
      className={classNames(css.cellWrapper, {
        [css.hasBooking]: hasBooking,
        [css.hasSession]: hasSession && !hasBooking,
        [css.disabled]: isDateBeforeToday,
        [css.unavailable]: isUnavailable,
      })}
      onClick={() => !isDateBeforeToday && !hasBooking && onClickCell?.(date, exceptionIdMaybe)}
    >
      {label}
    </div>
  );
};

CalendarDateCellWrapper.propTypes = {
  label: string.isRequired,
  datesWithEvents: object.isRequired,
};

export default CalendarDateCellWrapper;
