import React, { useMemo } from 'react';
import { func, instanceOf, object, string } from 'prop-types';
import { Navigate } from 'react-big-calendar';
import classNames from 'classnames';
import IconArrowHead from '../IconArrowHead/IconArrowHead';

import css from './CalendarToolbar.module.css';
import { getMomentFromDate } from '../../util/dates';

const TODAY = new Date();
const TODAY_YEAR = TODAY.getFullYear();

const MAX_AVAILABILITY_YEAR_RANGE = 3;

const createYearOptions = () => {
  const years = [TODAY_YEAR];

  for (let index = 1; index <= MAX_AVAILABILITY_YEAR_RANGE; index++) {
    years.push(TODAY_YEAR + index);
  }

  return years;
};

const YEAR_OPTIONS = createYearOptions();

const getHeadersByViews = (currentDate, localizer) => {
  const startOfWeek = localizer.startOf(currentDate, 'week');
  const endOfWeek = localizer.endOf(currentDate, 'week');

  const weekRange = localizer.range(startOfWeek, endOfWeek);

  const headers = weekRange.map(date => ({
    desktop: localizer.format(date, 'dddd'),
    mobile: localizer.format(date, 'ddd'),
  }));

  return headers;
};

const CalendarToolbar = props => {
  const { date, localizer, onNavigate } = props;

  const { messages } = localizer;

  const headers = useMemo(() => getHeadersByViews(date, localizer), [date, localizer]);

  const dateAsMoment = getMomentFromDate(date);

  const isSameYear = useMemo(() => dateAsMoment.isSame(TODAY, 'year'), [dateAsMoment]);

  const currentMonth = useMemo(() => localizer.format(date, `MMMM ${isSameYear ? '' : 'yyyy'}`), [
    date,
    isSameYear,
    localizer,
  ]);

  return (
    <div className={css.toolbar}>
      <div className={css.navigation}>
        <button type="button" onClick={() => onNavigate(Navigate.TODAY)} className={css.navButton}>
          {messages.today}
        </button>
        <div className={css.monthChangeBtns}>
          <button
            type="button"
            onClick={() => onNavigate(Navigate.PREVIOUS)}
            aria-label={messages.previous}
            className={css.navButton}
          >
            <IconArrowHead direction="left" />
          </button>
          <span>{currentMonth}</span>
          <button
            type="button"
            onClick={() => onNavigate(Navigate.NEXT)}
            aria-label={messages.next}
            className={css.navButton}
          >
            <IconArrowHead direction="right" />
          </button>
        </div>
        <select
          className={css.yearSelect}
          name="yearSelect"
          onChange={event => {
            onNavigate(
              Navigate.DATE,
              getMomentFromDate(date)
                .year(+event.target.value)
                .toDate()
            );
          }}
          value={dateAsMoment.year()}
        >
          {YEAR_OPTIONS.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className={classNames(css.headers, css.headersMonth)}>
        {headers.map(header => {
          const { desktop, mobile } = header;

          const [mobileFirstPart, mobileSecondPart] = mobile ? mobile.split(' ') : [];

          return (
            <div key={desktop} className={css.header}>
              <div className={classNames({ [css.desktopHeader]: !!mobile })}>{desktop}</div>
              {mobile ? (
                <div className={css.mobileHeader}>
                  <span> {mobileFirstPart} </span>
                  {mobileSecondPart ? <span>{mobileSecondPart}</span> : null}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CalendarToolbar.propTypes = {
  date: instanceOf(Date),
  label: string,
  localizer: object,
  messages: object,
  onNavigate: func,
};

export default CalendarToolbar;
